import React from 'react';
import ky from 'ky-universal';

import mime from 'mime';
import { v4 as uuidv4 } from 'uuid';

import Dropzone from 'react-dropzone-uploader';

import 'react-dropzone-uploader/dist/styles.css';
import './ImageUploader.scss';

export default function ImageUploader({
  signingUrl,
  onSubmit,
  imgUuid,
  multiple = true
}) {
  // specify upload params and url for your files

  const getUploadParams = async ({ file, meta: { type } }) => {
    const key = `${imgUuid || uuidv4()}.${mime.getExtension(type)}`;
    const res = await ky
      .get(signingUrl, {
        searchParams: { key, type }
      })
      .json();

    const { uploadUrl, fileUrl } = res.data;

    return {
      body: file,
      meta: { fileUrl },
      url: uploadUrl,
      method: 'put'
    };
  };
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    onSubmit(files);
    console.log(files.map(f => f.meta));
    allFiles.forEach(f => f.remove());
  };

  return (
    <Dropzone
      multiple={multiple}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      accept="image/*,audio/*,video/*"
      addClassNames={{
        dropzone: 'img-dropzone',
        inputLabel: 'dropzone-label',
        preview: 'dropzone-preview',
        previewImage: 'dz-preview-img'
      }}
      classNames={{
        submitButton: 'tn-button highlight border'
      }}
      inputContent="Drag an image or click to select files"
      inputComponent={null}
      maxFiles={multiple ? 25 : 1}
    />
  );
}

//TODO add errors for upload problems
