import React from 'react';

import Modal from '../../Site/PageWithModal/Modal/Modal';
import TNButton from '../../Site/TNButton/TNButton';

import './DeleteModal.scss';

function DeleteModal({ toClose, toDelete }) {
  return (
    <Modal title="Are you sure?" toClose={() => toClose(false)} className="delete-modal">
      <div className="content-contain">
        <p>Deleting this item is permanent. Would you like to continue?</p>
        <div className="button-row">
          <TNButton onClick={() => toClose(false)}>Cancel</TNButton>
          <TNButton
            border
            onClick={() => {
              toDelete();
              toClose(false);
            }}
          >
            Delete
          </TNButton>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteModal;
