import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField } from 'formik';

import FieldInner from './shared/FieldInner';

Textarea.propTypes = {
  rows: PropTypes.number,
  cols: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.func
};

Textarea.defaultProps = {
  rows: 4,
  helperText: '',
  disabled: false,
  validate: undefined
};

export default function Textarea({ name, validate, className, ...props }) {
  return (
    <FormikField name={name} {...validate}>
      {fieldProps => (
        <FieldInner
          {...props}
          {...fieldProps}
          type="textarea"
          className={`textarea ${className}`}
        />
      )}
    </FormikField>
  );
}
