import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Parallax,
  ParallaxBanner,
  withController
} from 'react-scroll-parallax';

import classNames from 'classnames';

import Header from '../Header/Header';
import Hamburger from '../Hamburger/Hamburger';
import NavDrawer from '../NavDrawer/NavDrawer';
import TNButton from '../TNButton/TNButton';
import CTA from '../CTA/CTA';
import Nav from '../Nav/Nav';

import './Page.scss';

const year = new Date().getFullYear();

const socialBlock = className => (
  <div className={`social ${className}`}>
    {/*<a href="#">{fb}</a>*/}
    {/*<a href="#">{twitter}</a>*/}
    {/*<a href="#">{google}</a>*/}
  </div>
);

const loadListener = fn => {
  window.removeEventListener('load', loadListener);
  return fn();
};

function Page({
  topImg,
  bottomImg,
  text,
  deskImg1,
  deskImg2,
  deskImg2Style = {},
  topImgStyle = {},
  isContact,
  location,
  ...props
}) {
  const navDrawer2 = useRef(null);
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  typeof window === 'object' &&
    window.addEventListener(
      'load',
      loadListener(props.parallaxController.update)
    );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location?.pathname]);

  function handleScroll() {
    setScrolled(window.scrollY > 10);
  }

  useEffect(() => {
    if (window.innerWidth < 1025) setIsMobile(true);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const registerButton = (
    <a href="mailto:customersupport@topnotchfla.com">
      <TNButton border highlight extraClass="register">
        Register your first job
      </TNButton>
    </a>
  );

  return (
    <div className="tn-page">
      <div className={classNames('header-wrap', { scrolled })}>
        <Header />
        <CTA page />
        <Hamburger onClick={() => navDrawer2?.current?.toggle()} />
      </div>
      <div className="tn-grid">
        <Nav />
        <Parallax
          y={[10, -15]}
          className="top-img"
          disabled={isMobile}
          styleInner={{
            height: '100%',
            backgroundImage: `url(${topImg})`,
            ...topImgStyle
          }}
        >
          <div className="only-desktop">
            {text.title}
            <div className="body-container">
              {text.body}
              {registerButton}
            </div>
          </div>
        </Parallax>
        {socialBlock('top')}
        <Parallax
          y={[-10, 5]}
          className="page-main"
          disabled={isMobile}
          styleInner={{ height: '100%', position: 'relative' }}
        >
          <div className="page-shadow">
            <div className="body-container">
              <div className="only-mobile">
                {text.title}
                {text.body}
                {registerButton}
              </div>
            </div>
          </div>
        </Parallax>
        <div className="mobile" />

        <ParallaxBanner
          layers={[{ image: deskImg1 || topImg, amount: 0.2 }]}
          style={{ height: 'auto' }}
          disabled={isMobile}
          className="desk-img-1 desktop-extra-row"
        />

        <div className="desktop-extra-row middle-paragraphs">
          <h2 className="desk-title">{text.deskTitle}</h2>
          {text.body}
        </div>
        <div
          style={{
            backgroundImage: `url(${deskImg2 || topImg})`,
            ...deskImg2Style
          }}
          className="desk-img-2 desktop-extra-row"
        />
        <div className="desktop-extra-row last-paragraph">{text.body}</div>

        <Parallax y={[50, -75]} className="be-moved" disabled={isMobile}>
          <h2>Be Moved.</h2>
          <p>
            New clients receive a month of free storage on your first job with
            us.{' '}
          </p>
          <div className="register-contain">
            <a className="btn" href="mailto:customersupport@topnotchfla.com">
              <TNButton border highlight extraClass="register">
                Register a job
              </TNButton>
            </a>
            {!isContact && (
              <p>
                Or <Link to="contact">Contact Us</Link> with questions.
              </p>
            )}
          </div>
        </Parallax>
        <div />
        <div
          style={{ backgroundImage: `url(${bottomImg})` }}
          className="bottom-img"
        />

        <NavDrawer ref={navDrawer2} />
        <Nav extra />
        <footer>
          <div>
            <p>1405 Poinsettia Dr</p>
            <p>Bldg G Ste 5-8</p>
            <p>Delray Beach, Florida 33444</p>
            <p>Tel: 561-266-2627</p>
          </div>
          <div>
            <p>Business Hours: M - F 7:30a to 4:00p</p>
            <p>Receiving: M - TH 8:00a to 3:30p</p>
            <p>&nbsp;</p>
            <p>Licensed & Insured</p>
            {socialBlock('footer')}
          </div>
        </footer>
        {socialBlock('bottom')}
        <div className="extra-foot">
          <p>© {year} Top Notch Delivery Service</p>
          <p>All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
}

export default withController(Page);
