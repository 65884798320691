import ky from 'ky-universal';

const REQUEST_EDIT_INVOICE = 'REQUEST_EDIT_INVOICE';
const SUCCESS_EDIT_INVOICE = 'SUCCESS_EDIT_INVOICE';
const FAIL_EDIT_INVOICE = 'FAIL_EDIT_INVOICE';
const RESET_EDIT_INVOICE = 'RESET_EDIT_INVOICE';

export const editInvoiceReset = () => ({ type: RESET_EDIT_INVOICE });

function requestEditInvoice() {
  return { type: REQUEST_EDIT_INVOICE };
}

function successEditInvoice({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_EDIT_INVOICE
  };
}

function failEditInvoice(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_EDIT_INVOICE
  };
}

export function editInvoice(id, data, send) {
  const searchParams = send ? { send } : {};

  return dispatch => {
    dispatch(requestEditInvoice());

    return ky
      .put(`/api/invoices/${id}`, { json: data, searchParams })
      .json()
      .then(
        res => dispatch(successEditInvoice(res)),
        async error => dispatch(failEditInvoice(await error?.response?.json()))
      );
  };
}

const initialInvoicesState = {
  data: {},
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function editedInvoice(state = initialInvoicesState, action) {
  switch (action.type) {
    case REQUEST_EDIT_INVOICE:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_EDIT_INVOICE:
      return {
        ...state,
        data: action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: true
      };
    case FAIL_EDIT_INVOICE:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_EDIT_INVOICE:
      return initialInvoicesState;
    default:
      return state;
  }
}
