import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField } from 'formik';

import FieldInner from './shared/FieldInner';

Field.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  validate: PropTypes.func
};

Field.defaultProps = {
  helperText: '',
  placeholder: '',
  disabled: false,
  className: '',
  validate: undefined
};

export default function Field({ name, validate, ...props }) {
  return (
    <FormikField name={name} validate={validate}>
      {fieldProps => <FieldInner {...props} {...fieldProps} />}
    </FormikField>
  );
}
