import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';

import caret from '../../../images/caret.svg';

import './Dropdown.scss';

let timer;

function Dropdown(
  { extraClass, outsideText, children, arrow, image, noClose, filterBar },
  ref
) {
  const dropdown = useRef(null);

  const [collapsed, setCollapsed] = useState(true);

  const closeDropdown = () => {
    if (!collapsed) {
      setCollapsed(true);
    }
  };

  const dropdownToggle = () => {
    setCollapsed(!collapsed);
  };

  const ticking = React.useRef(false);

  function moveThings() {
    const el = dropdown.current;
    const bounds = el.getBoundingClientRect();
    const ddWindow = el.querySelector('.dropdown-window');
    const ddWindowBounds = ddWindow.getBoundingClientRect();
    const clientWidth = document.documentElement.clientWidth;

    ddWindow.style.top = bounds.bottom + 'px';

    if (bounds.x + bounds.width - ddWindowBounds.width < 0) {
      ddWindow.style.left = bounds.x + 'px';
      ddWindow.style.right = '';
    } else {
      ddWindow.style.left = '';
      ddWindow.style.right = clientWidth - bounds.right + 'px';
    }
  }

  function throttledMoveThings() {
    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        moveThings();
        ticking.current = false;
      });
      ticking.current = true;
    }
  }

  React.useEffect(() => {
    if (!filterBar) return;

    const bar = dropdown.current?.closest('.filter-bar');

    if (bar) {
      setTimeout(moveThings, 1000); // run once first

      bar.addEventListener('scroll', throttledMoveThings);
      return () => bar.removeEventListener('scroll', throttledMoveThings);
    }
  }, []);

  function handleBlur() {
    timer = window.setTimeout(closeDropdown, 100);
  }

  function handleFocus() {
    dropdown?.current?.focus();
    clearTimeout(timer);
  }

  const dropdownClasses = classNames('dropdown', {
    [extraClass]: extraClass
  });

  const windowClasses = classNames('dropdown-window', {
    open: !collapsed
  });

  useImperativeHandle(ref, () => ({
    toggle: dropdownToggle,
    close: closeDropdown
  }));

  return (
    <div
      className={dropdownClasses}
      ref={dropdown}
      onBlur={!noClose ? handleBlur : null}
      onFocus={!noClose ? handleFocus : null}
      tabIndex={!noClose ? '-1' : null}
    >
      <div className="dropdown-outside" onClick={dropdownToggle}>
        {image && (
          <div
            className="dropdown-image"
            style={{
              backgroundImage: `url(${image})`
            }}
          />
        )}
        {outsideText}
        {arrow && <img src={caret} alt="down caret" className="caret" />}
      </div>
      <div className={windowClasses} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}

Dropdown = forwardRef(Dropdown);
export default Dropdown;
