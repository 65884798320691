import ky from 'ky-universal';

const REQUEST_GET_MANUFACTURERS = 'REQUEST_GET_MANUFACTURERS';
const SUCCESS_GET_MANUFACTURERS = 'SUCCESS_GET_MANUFACTURERS';
const FAIL_GET_MANUFACTURERS = 'FAIL_GET_MANUFACTURERS';

function requestGetManufacturers() {
  return { type: REQUEST_GET_MANUFACTURERS };
}

function successGetManufacturers({ data, page }, next) {
  return {
    data,
    page,
    wasPaging: !!next,
    receivedAt: Date.now(),
    type: SUCCESS_GET_MANUFACTURERS
  };
}

function failGetManufacturers(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_MANUFACTURERS
  };
}

function tryGetManufacturers(next, search) {
  return dispatch => {
    dispatch(requestGetManufacturers());

    return ky
      .get(next || `/api/manufacturers${search}`)
      .json()
      .then(
        res => dispatch(successGetManufacturers(res, next)),
        async error =>
          dispatch(failGetManufacturers(await error?.response?.json()))
      );
  };
}

function shouldGetManufacturers({ manufacturers }) {
  if (manufacturers.isFetching) {
    return false;
  } else if (!manufacturers.data.length) {
    return true;
  } else {
    return manufacturers.lastUpdated < Date.now() - 50;
  }
}

export function getManufacturers({ next, search = null } = {}) {
  return (dispatch, getState) => {
    if (shouldGetManufacturers(getState())) {
      return dispatch(tryGetManufacturers(next, search));
    }
  };
}

const initialManufacturersState = {
  data: [],
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function manufacturers(state = initialManufacturersState, action) {
  switch (action.type) {
    case REQUEST_GET_MANUFACTURERS:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_MANUFACTURERS:
      return {
        ...state,
        data: action.wasPaging ? [...state.data, ...action.data] : action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_MANUFACTURERS:
      return {
        ...state,
        data: [],
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
