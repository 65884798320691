import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useGetActions(tableKey) {
  const [actions, setActions] = useState({});

  const user = useSelector(s => s.user?.data);

  useEffect(() => {
    const privileges = user?.tablePrivileges;
    setActions(
      privileges
        ?.find(x => x.table === tableKey)
        ?.actions.reduce((obj, x) => (obj[x] = true) && obj, {}) || {}
    );
  }, [user, tableKey]);

  return actions;
}
