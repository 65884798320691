import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import TNButton from '../../TNButton/TNButton';
import useErrorToast from '../../../hooks/useErrorToast';
import { Field, Form } from '../../../core/Forms';
import { createResetEmailIfNeeded } from '../../../../store/lib/user/resetEmail';

const forgotPassSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required('Please enter your email.')
});

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const resetEmail = useSelector(s => s.resetEmail);

  useErrorToast(resetEmail.error);

  return (
    <div className="switchable">
      {!resetEmail.submitted ? (
        <>
          <p>Please enter your email for a reset link.</p>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={forgotPassSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              dispatch(createResetEmailIfNeeded(values)).finally(() =>
                setSubmitting(false)
              );
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field label="Email" name="email" className="span-all" />
                <TNButton
                  border
                  highlight
                  type="submit"
                  extraClass="span-all"
                  disabled={isSubmitting}
                >
                  Send Reset Link
                </TNButton>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <p>An email has been sent to the requested email.</p>
          <br />
          <Link to={'/login'}>
            <TNButton border>Return to Login</TNButton>
          </Link>
        </>
      )}
    </div>
  );
}
