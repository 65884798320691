import React, { useEffect, useState, useRef } from 'react';
import qs from 'qs';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';

import DropNType from '../DropNType/DropNType';
import TNButton from '../../Site/TNButton/TNButton';
import { Form, RadioGroup } from '../../core/Forms';
import { groups } from '../../../api/db/config/access';

import './FilterBar.scss';

function FilterBar({
  sortFn,
  columns,
  search,
  filterInfo,
  searchActive,
  tableKey,
  location,
  listView,
  setListView,
  printFn
}) {
  const user = useSelector(({ user }) => user);

  const [formValues, setFormValues] = useState({});
  const formik = useRef(null);

  const query = qs.parse(location?.search?.replace?.('?', ''));
  const filteredJob = query?.where?.jobId;
  const isItems = tableKey === 'items';

  const isClient = (user.memberships & groups.client) !== 0;

  function reverseSort() {
    sortFn(
      formValues.order.includes('-')
        ? formValues.order.replace('-', '')
        : `-${formValues.order}`
    );
  }

  useEffect(() => {
    sortFn(formValues.order);
  }, [formValues]);

  useEffect(() => {
    if (!searchActive) {
      formik?.current?.resetForm();
    }
  }, [searchActive]);

  return (
    <div className="filter-bar">
      {filterInfo?.length &&
        filterInfo
          .filter(filter => {
            if (!filter.excludeGroup) return true;
            return (user.data.memberships & filter.excludeGroup) === 0;
          })
          .map(filter => (
            <DropNType
              key={`filter-${filter.label}`}
              filterInfo={filter}
              search={search}
            />
          ))}
      <Formik
        initialValues={{
          order: search?.order || ''
        }}
        innerRef={formik}
      >
        {({ values, errors, touched }) => {
          return (
            <Form noGrid onChange={setFormValues(values)}>
              <RadioGroup
                isDropdown
                filterBar
                id="order"
                label="order"
                className="filter-bar-filter"
                value={values.order}
                error={errors.order}
                touched={touched.order}
                options={columns.map(column => [
                  column.sortKey || column.dataKey || column.extraDataKey,
                  column.display
                ])}
              />
            </Form>
          );
        }}
      </Formik>
      {formValues.order && (
        <TNButton tiny onClick={reverseSort}>
          Reverse Sort
        </TNButton>
      )}

      <TNButton
        tiny
        className="filterBarButton"
        onClick={() => setListView(!listView)}
      >
        {listView ? 'Card View' : 'List View'}
      </TNButton>

      {listView && (
        <TNButton tiny className="filterBarButton" onClick={printFn}>
          Print
        </TNButton>
      )}

      {isItems && !isClient && (
        <TNButton
          tiny
          className="filterBarButton"
          onClick={() =>
            window.location.assign(`/ims/bol/new.pdf?jobId=${filteredJob}`)
          }
          disabled={!filteredJob}
          title={
            !filteredJob ? 'Must filter to a single Job' : 'View draft B/L'
          }
        >
          Generate B/L
        </TNButton>
      )}
    </div>
  );
}

export default FilterBar;
