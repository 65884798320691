import ky from 'ky-universal';

const REQUEST_DELETE_JOB = 'REQUEST_DELETE_JOB';
const SUCCESS_DELETE_JOB = 'SUCCESS_DELETE_JOB';
const FAIL_DELETE_JOB = 'FAIL_DELETE_JOB';
const RESET_DELETE_JOB = 'RESET_DELETE_JOB';

export const deleteJobReset = () => ({ type: RESET_DELETE_JOB });

function requestDeleteJob() {
  return { type: REQUEST_DELETE_JOB };
}

function successDeleteJob(res, id) {
  return {
    id,
    receivedAt: Date.now(),
    type: SUCCESS_DELETE_JOB
  };
}

function failDeleteJob(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_DELETE_JOB
  };
}

export function deleteJob(id) {
  return dispatch => {
    dispatch(requestDeleteJob());
    return ky
      .delete(`/api/jobs/${id}`)
      .json()
      .then(
        res => dispatch(successDeleteJob(res, id)),
        async error => dispatch(failDeleteJob(await error?.response?.json()))
      );
  };
}

const initialJobsState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function deletedJob(state = initialJobsState, action) {
  switch (action.type) {
    case REQUEST_DELETE_JOB:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_DELETE_JOB:
      return {
        ...state,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_DELETE_JOB:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_DELETE_JOB:
      return initialJobsState;
    default:
      return state;
  }
}
