import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Form, Field, Textarea } from '../../core/Forms';

import Page from '../Page/Page';
import TNButton from '../TNButton/TNButton';

import useErrorToast from '../../hooks/useErrorToast';
import { postContactIfNeeded } from '../../../store/lib/contact/postContact';

import './Contact.scss';

import dogBox from './images/dogBox.jpg';
import tinyPackage from './images/tinyPackage.jpg';
import blackYellowWall from './images/blackYellowWall.jpg';
import yellaphone from './images/yellaphone.jpg';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  name: yup.string().required('Please enter your name'),
  email: yup
    .string()
    .email()
    .required('We need an email to respond to you!'),
  phone: yup.string().matches(phoneRegExp, 'Please enter valid phone'),
  message: yup.string().required('How can we help you?')
});

const email = 'topnotchdeliveryservice@gmail.com';
const phone = '+1 (561) 266-2627';

const contactContent = {
  title: (
    <div className="page-title-container">
      {/* the zero-width space (&#8203;) for some reason makes this work on Safari */}
      <h2 className="page-title" data-text="Reach Out &&#8203;">
        Reach Out &&#8203;
      </h2>
      <h2 className="page-title" data-text="Let Us Help You.&#8203;">
        Let Us Help You.&#8203;
      </h2>
    </div>
  ),
  deskTitle: 'We’d love to chat.',
  body: (dispatch, submitted) => (
    <div className="page-main-body">
      <p>
        We are located in the All-American City of Delray Beach, Florida, less
        than 0.5 miles off of Interstate 95 at the Linton Boulevard exit.
      </p>
      <p>
        <address className="address">
          <p>1405 Poinsettia Dr</p>
          <p>Bldg G Ste 5-8</p>
          <p>Delray Beach, Florida 33444</p>
          <br />
          <p>
            Telephone:{' '}
            <a href={`tel:${phone.replace(/[()\- ]/g, '')}`}>{phone}</a>
          </p>
          <p>Fax: 561-266-2629</p>
          <p>
            Email: <a href={`mailto:${email}`}>{email}</a>
          </p>
        </address>
      </p>
      <p>
        <div className="contact-form-container">
          <TransitionGroup className="vertical-switch-container contact-form">
            <CSSTransition
              key={submitted}
              timeout={1e3}
              classNames="vertical-switch"
            >
              {!submitted ? (
                <Formik
                  validationSchema={schema}
                  initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    dispatch(postContactIfNeeded(values)).finally(() =>
                      setSubmitting(false)
                    );
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <h1 style={{ gridColumn: '1 / 3' }}>Contact Us</h1>
                      <Field name="name" label="Name" />
                      <Field name="email" type="email" label="Email" />
                      <Field name="phone" type="phone" label="Phone" />
                      <Textarea
                        name="message"
                        label="Message"
                        rows={5}
                        className="span-all"
                      />
                      <TNButton border type="submit" disabled={isSubmitting}>
                        Submit
                      </TNButton>
                    </Form>
                  )}
                </Formik>
              ) : (
                <div className="contact-form-thanks">
                  <h1>Thanks!</h1>
                  <h2>We’ll get back to you soon.</h2>
                </div>
              )}
            </CSSTransition>
          </TransitionGroup>
        </div>
      </p>
    </div>
  )
};

function Contact() {
  const dispatch = useDispatch();
  const postContact = useSelector(s => s.postContact);

  useErrorToast(postContact.error);

  return (
    <Page
      isContact
      topImg={tinyPackage}
      bottomImg={blackYellowWall}
      deskImg1={dogBox}
      deskImg2={yellaphone}
      text={{
        title: contactContent.title,
        body: contactContent.body(dispatch, postContact.submitted),
        deskTitle: contactContent.deskTitle
      }}
    />
  );
}

export default Contact;
