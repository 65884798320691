import 'react-hot-loader';

import { setConfig } from 'react-hot-loader';
setConfig({ trackTailUpdates: false });

import React from 'react';
import { hydrate } from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ParallaxProvider } from 'react-scroll-parallax';

import configureStore, { getHistory } from './store/configureStore';

import App from './components/App';

if (process.env.NODE_ENV !== 'production') global.__DEVCLIENT__ = true;

const history = getHistory();
const { store } = configureStore(window.__PRELOADED_STATE__, history);

hydrate(
  <Provider store={store}>
    <ParallaxProvider>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ParallaxProvider>
  </Provider>,
  document.getElementById('root')
);
