import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import get from 'lodash.get';

import TNButton from '../../Site/TNButton/TNButton';
import statuses from '../Items/config/statuses.js';
import { editItem } from '../../../store/lib/items/editItem';

import './CardsList.scss';

import defaultImg from './no-item-img.jpg';
import {
  decItemsCount,
  incItemsCount
} from '../../../store/lib/items/itemsCount';

function CardsList({ data, fields, editClick, listView }) {
  const { title, subtitle, details, photoUrl } = fields;

  const dispatch = useDispatch();
  const userPrivs = useSelector(s => s.user.data.tablePrivileges);

  const itemsPrivs = userPrivs?.find(p => p.table === 'items');
  const canLoad = itemsPrivs?.actions.includes('load');

  return (
    <div className={classNames('cards-list', { listView })}>
      {!!data?.length &&
        data.map(item => {
          const image = get(item, photoUrl);

          const toPick = (item.status & statuses.pick) !== 0;
          const inTransit = (item.status & statuses.transit) !== 0;

          return (
            <div
              className={item.hasConcerns ? 'card has-concerns' : 'card'}
              key={`card-${item.id}`}
              onClick={() => editClick(item)}
            >
              {image && listView ? (
                <img className="card-img" src={image} alt="" />
              ) : (
                <div
                  className={image ? 'card-img' : 'card-img no-img'}
                  style={{
                    backgroundImage: `url(${image || defaultImg})`
                  }}
                />
              )}
              <div className="cardBody">
                <h4>{get(item, title) || '-'}</h4>
                <p className="subtitle">{get(item, subtitle) || '-'}</p>
                {listView ? (
                  <div className="cardDetails">
                    {details.map(detail => (
                      <React.Fragment
                        key={`card-detail-${item.id}-${detail.dataKey}`}
                      >
                        <span className="card-key">{detail.display}</span>
                        <span className="card-detail">
                          {get(item, detail.dataKey) ||
                            get(item, detail.extraDataKey)}
                        </span>
                      </React.Fragment>
                    ))}
                  </div>
                ) : (
                  <ul>
                    {details.map(detail => (
                      <li key={`card-detail-${item.id}-${detail.dataKey}`}>
                        <span className="card-key">{detail.display}</span>
                        <span className="card-detail">
                          {get(item, detail.dataKey) ||
                            get(item, detail.extraDataKey)}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="cardActions">
                {canLoad && (
                  <TNButton
                    onClick={e => {
                      e.stopPropagation();

                      dispatch(
                        (toPick ? decItemsCount : incItemsCount)('pick')
                      );

                      return dispatch(
                        editItem(
                          item.id,
                          {
                            status: toPick
                              ? item.status & ~statuses.pick
                              : item.status | statuses.pick
                          },
                          true
                        )
                      );
                    }}
                  >
                    {toPick ? '✓ To Pick' : 'Mark “To Pick”'}
                  </TNButton>
                )}
                {canLoad && (
                  <TNButton
                    onClick={e => {
                      e.stopPropagation();

                      dispatch(
                        (inTransit ? decItemsCount : incItemsCount)('transit')
                      );

                      return dispatch(
                        editItem(
                          item.id,
                          {
                            status: inTransit
                              ? item.status & ~statuses.transit
                              : item.status | statuses.transit
                          },
                          true
                        )
                      );
                    }}
                  >
                    {inTransit ? '✓ In Transit' : 'Mark “In Transit”'}
                  </TNButton>
                )}
                {canLoad && (item.status & statuses.transit) !== 0 && (
                  <TNButton
                    onClick={e => {
                      e.stopPropagation();
                      return dispatch(
                        editItem(item.id, { onTruck: !item.onTruck }, true)
                      );
                    }}
                  >
                    {item.onTruck ? '✓ On Truck' : 'Mark “On Truck”'}
                  </TNButton>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default CardsList;
