import ky from 'ky-universal';

const REQUEST_EDIT_JOB = 'REQUEST_EDIT_JOB';
const SUCCESS_EDIT_JOB = 'SUCCESS_EDIT_JOB';
const FAIL_EDIT_JOB = 'FAIL_EDIT_JOB';
const RESET_EDIT_JOB = 'RESET_EDIT_JOB';

export const editJobReset = () => ({ type: RESET_EDIT_JOB });

function requestEditJob() {
  return { type: REQUEST_EDIT_JOB };
}

function successEditJob({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_EDIT_JOB
  };
}

function failEditJob(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_EDIT_JOB
  };
}

export function editJob(id, data) {
  return dispatch => {
    dispatch(requestEditJob());
    return ky
      .put(`/api/jobs/${id}`, { json: data })
      .json()
      .then(
        res => dispatch(successEditJob(res)),
        async error => dispatch(failEditJob(await error?.response?.json()))
      );
  };
}

const initialJobsState = {
  data: {},
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function editedJob(state = initialJobsState, action) {
  switch (action.type) {
    case REQUEST_EDIT_JOB:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_EDIT_JOB:
      return {
        ...state,
        data: action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: true
      };
    case FAIL_EDIT_JOB:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_EDIT_JOB:
      return initialJobsState;
    default:
      return state;
  }
}
