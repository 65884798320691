const initial = { value: 'initial', inc: 0 };

export const testStore = (state = initial, action) => {
  switch (action.type) {
    case 'update':
      state.value = action.value;
      state.inc += 1;
      return state;
    case 'reset':
      return initial;
    default:
      return state;
  }
};

export const actions = {
  update(value) {
    return { type: 'update', value };
  },
  reset() {
    return { type: 'reset' };
  }
};
