import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import useErrorToast from '../../../hooks/useErrorToast';
import { loginIfNeeded } from '../../../../store/lib/user/user';

import LoginForm from './LoginForm';
import SignUpModal from './SignUpModal';

const loginSchema = yup.object().shape({
  username: yup.string().required('Please enter your username.'),
  password: yup.string().required('Password, please.')
});

export default function Login() {
  const dispatch = useDispatch();
  const userError = useSelector(({ user: { error: userError } }) => userError);

  useErrorToast(userError);

  const [signUpModal, setSignUpModal] = useState(false);

  // Have to return a single element for transition.
  return (
    <>
      <div className="switchable">
        {/*<p>Welcome back! Please log in.</p>*/}
        <p>
          <em>Customer Access Coming Soon!</em>
        </p>

        <Formik
          validationSchema={loginSchema}
          initialValues={{
            username: '',
            password: '',
            rememberMe: true
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            dispatch(loginIfNeeded(values)).finally(() => setSubmitting(false));
          }}
        >
          {({ isSubmitting }) => {
            return (
              <LoginForm
                submitting={isSubmitting}
                showSignup={() => setSignUpModal(true)}
              />
            );
          }}
        </Formik>
      </div>
      {signUpModal && <SignUpModal toClose={() => setSignUpModal(false)} />}
    </>
  );
}
