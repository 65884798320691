import ky from 'ky-universal';

const REQUEST_GET_INVOICE_REPORT = 'REQUEST_GET_INVOICE_REPORT';
const SUCCESS_GET_INVOICE_REPORT = 'SUCCESS_GET_INVOICE_REPORT';
const FAIL_GET_INVOICE_REPORT = 'FAIL_GET_INVOICE_REPORT';

function requestGetInvoiceReport() {
  return { type: REQUEST_GET_INVOICE_REPORT };
}

function successGetInvoiceReport({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_GET_INVOICE_REPORT
  };
}

function failGetInvoiceReport(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_INVOICE_REPORT
  };
}

export function getInvoiceReport() {
  return dispatch => {
    dispatch(requestGetInvoiceReport());
    return ky
      .get('/api/invoices/report')
      .json()
      .then(
        res => dispatch(successGetInvoiceReport(res)),
        async error =>
          dispatch(failGetInvoiceReport(await error?.response?.json()))
      );
  };
}

const initialInvoiceReportState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function invoiceReport(state = initialInvoiceReportState, action) {
  switch (action.type) {
    case REQUEST_GET_INVOICE_REPORT:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_INVOICE_REPORT:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_INVOICE_REPORT:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
