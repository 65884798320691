import ky from 'ky-universal';

const REQUEST_POST_CONTACT = 'REQUEST_POST_CONTACT';
const PASS_POST_CONTACT = 'PASS_POST_CONTACT';
const FAIL_POST_CONTACT = 'FAIL_POST_CONTACT';

function requestContact() {
  return { type: REQUEST_POST_CONTACT };
}

function passContact(res) {
  return {
    type: PASS_POST_CONTACT,
    user: res.data,
    receivedAt: Date.now()
  };
}

function failContact(error) {
  return {
    error,
    type: FAIL_POST_CONTACT,
    receivedAt: Date.now()
  };
}

function tryPostContact(data) {
  return dispatch => {
    dispatch(requestContact());

    return ky
      .post(`/api/contact`, { json: data })
      .json()
      .then(
        res => dispatch(passContact(res)),
        async error => dispatch(failContact(await error?.response?.json?.()))
      );
  };
}

function shouldPostContact({ postContact }) {
  if (postContact.isFetching) {
    return false;
  } else if (!postContact.data) {
    return true;
  } else {
    return postContact.lastUpdated < Date.now() - 500;
  }
}

export function postContactIfNeeded(data) {
  return (dispatch, getState) => {
    if (shouldPostContact(getState())) {
      return dispatch(tryPostContact(data));
    }
  };
}

const initialUserState = {
  data: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function postContact(state = initialUserState, action) {
  switch (action.type) {
    case REQUEST_POST_CONTACT:
      return { ...state, isFetching: true, error: null };
    case PASS_POST_CONTACT:
      return {
        ...state,
        data: action.user,
        isFetching: false,
        error: null,
        submitted: true,
        lastUpdated: action.receivedAt
      };
    case FAIL_POST_CONTACT:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
