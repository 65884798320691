import React from 'react';
const classNames = require('classnames');

import TableHead from './TableHead/TableHead';
import TableRows from './TableRow/TableRow';

import './Table.scss';

function Table({ columns, data, className, editClick, deleteClick, sortFn, search }) {
  const tableClasses = classNames('ims-table', className);

  let headers = [];
  let keys = [];

  columns.forEach(column => {
    headers.push(column.display);
    keys.push(column.dataKey);
  });

  return (
    <table className={tableClasses}>
      <TableHead
        columns={columns}
        headerClass={(className || 'table') + '-header'}
        sortFn={sortFn}
        search={search}
      />
      <TableRows
        data={data}
        dataKeys={keys}
        bodyClass={(className || 'table') + '-body'}
        columns={headers}
        editClick={editClick}
        deleteClick={deleteClick}
      />
    </table>
  );
}

export default Table;

//TODO back to top button should only show when scrolled
