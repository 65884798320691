import ky from 'ky-universal';

const REQUEST_DELETE_INVOICE = 'REQUEST_DELETE_INVOICE';
const SUCCESS_DELETE_INVOICE = 'SUCCESS_DELETE_INVOICE';
const FAIL_DELETE_INVOICE = 'FAIL_DELETE_INVOICE';
const RESET_DELETE_INVOICE = 'RESET_DELETE_INVOICE';

export const deleteInvoiceReset = () => ({ type: RESET_DELETE_INVOICE });

function requestDeleteInvoice() {
  return { type: REQUEST_DELETE_INVOICE };
}

function successDeleteInvoice(res, id) {
  return {
    id,
    receivedAt: Date.now(),
    type: SUCCESS_DELETE_INVOICE
  };
}

function failDeleteInvoice(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_DELETE_INVOICE
  };
}

export function deleteInvoice(id) {
  return dispatch => {
    dispatch(requestDeleteInvoice());
    return ky
      .delete(`/api/invoices/${id}`)
      .json()
      .then(
        res => dispatch(successDeleteInvoice(res, id)),
        async error =>
          dispatch(failDeleteInvoice(await error?.response?.json()))
      );
  };
}

const initialInvoicesState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function deletedInvoice(state = initialInvoicesState, action) {
  switch (action.type) {
    case REQUEST_DELETE_INVOICE:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_DELETE_INVOICE:
      return {
        ...state,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_DELETE_INVOICE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_DELETE_INVOICE:
      return initialInvoicesState;
    default:
      return state;
  }
}
