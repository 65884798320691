import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TNButton from '../../TNButton/TNButton';
import PasswordField from './PasswordField';
import { Checkbox, Field, Form } from '../../../core/Forms';

export default function LoginForm(props) {
  return (
    <Form>
      <Field name="username" label="Username or Email" className="span-all" />
      <PasswordField />
      <Checkbox name="rememberMe" label="Remember Me" />
      <Link className="forgot" to="/login/forgot-password">
        Forgot Password?
      </Link>

      <TNButton border highlight type="submit" disabled={props.submitting}>
        Login
      </TNButton>
      <TNButton border type="button" onClick={props.showSignup}>
        Sign Up
      </TNButton>
    </Form>
  );
}

LoginForm.propTypes = {
  submitting: PropTypes.any,
  showPW: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onClick1: PropTypes.func
};
