export const uuidNs = {
  userImg: '321bab6b-e1ea-4036-a5b5-408cce7998c2',
  itemTemplatesImg: '53af9277-bd74-4d55-afe7-97a79cac68b1'
};

export const statuses = {
  deleted: 2 ** 0,
  inactive: 2 ** 1,
  pending: 2 ** 2,
  active: 2 ** 3,
  late: 2 ** 4
};
