import { statuses } from '../../../config';

export default {
  ...statuses,
  received: 2 ** 5,
  storage: 2 ** 6,
  transit: 2 ** 7,
  delivered: 2 ** 8,
  inspected: 2 ** 9,
  pick: 2 ** 10
};
