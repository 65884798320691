import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { ResponsivePie } from '@nivo/pie';

import { getInvoiceReport } from '../../../store/lib/dashSummaries/invoiceReport';

import './Dash.scss';
import TNButton from '../../Site/TNButton/TNButton';
import IMSTopBar from '../IMSTopBar/IMSTopBar';

const usdOpts = { style: 'currency', currency: 'USD' };

function toUsd(n) {
  const float = parseFloat(n);
  return Number.isFinite(float) ? float.toLocaleString('en-US', usdOpts) : '';
}

const mapStateToProps = data => ({
  user: data.user.data,
  invoiceReport: data.invoiceReport.data
});

export default connect(mapStateToProps)(function Dash({
  dispatch,
  history,
  location,
  user,

  toggleMobileNav,
  invoiceReport
}) {
  const [isMobile, setIsMobile] = useState(null);

  const invPrivileges = user.tablePrivileges.find(p => p.table === 'invoices');

  if (!invPrivileges || !invPrivileges.actions.includes('ls')) {
    history.push('/ims/items');
  }

  useEffect(() => {
    dispatch(getInvoiceReport());
  }, []);

  function handleResize() {
    setIsMobile(window.innerWidth < 768);
  }

  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function goToOverdue() {
    history.push({
      pathname: '/ims/invoices',
      location: `/ims/invoices?where%5Bstatus%5D=16`,
      search: 'where%5Bstatus%5D=16'
    });
  }

  function goToClients() {
    history.push({
      pathname: '/ims/users',
      location: `/ims/users?whereIn[id]=${invoiceReport.overdue.clientIds.join(
        ','
      )}`,
      search: `whereIn[id]=${invoiceReport.overdue.clientIds.join(',')}`
    });
  }

  function handleSliceClick(type) {
    if (type === 'overdue') {
      goToOverdue();
    }
  }

  function renderCostCodeGraph() {
    const data =
      invoiceReport &&
      Object.entries(invoiceReport).map(i => {
        return {
          value: i[1].totalRaw,
          id: i[0],
          label: i[0]
        };
      });

    if (!data) return;

    return (
      <div className="pie-grid">
        <ResponsivePie
          isInteractive
          data={data}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          colors={['#21ad4a', '#ad2aad', '#303236', '#ddbc40']}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          tooltipFormat={toUsd}
          radialLabelsSkipAngle={10}
          radialLabelsTextXOffset={6}
          radialLabelsTextColor="#333333"
          radialLabelsLinkOffset={0}
          radialLabelsLinkDiagonalLength={16}
          radialLabelsLinkHorizontalLength={24}
          radialLabelsLinkStrokeWidth={1}
          radialLabelsLinkColor={{ from: 'color' }}
          slicesLabelsSkipAngle={10}
          sliceLabel={d => toUsd(d.value)}
          slicesLabelsTextColor="#fff"
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          onClick={e => handleSliceClick(e.id)}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.1)',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.1)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          fill={[
            {
              match: {
                id: 'paid'
              },
              id: 'dots'
            },
            {
              match: {
                id: 'sent'
              },
              id: 'dots'
            },
            {
              match: {
                id: 'overdue'
              },
              id: 'lines'
            }
          ]}
          legends={[
            {
              anchor: 'bottom',
              direction: isMobile ? 'column' : 'row',
              translateY: 56,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: '#999',
              symbolSize: 18,
              symbolShape: 'circle',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: '#000'
                  }
                }
              ]
            }
          ]}
        />
      </div>
    );
  }

  return (
    <div className="dash ims-page">
      <IMSTopBar noSearch toggleMobileNav={toggleMobileNav} />
      <div className="page-content">
        <h1 className="title">Invoice Report</h1> {renderCostCodeGraph()}
        <div className="button-row">
          <TNButton border onClick={goToOverdue}>
            View Overdue Invoices
          </TNButton>
          <TNButton border onClick={goToClients}>
            Clients w/Overdue
          </TNButton>
        </div>
      </div>
    </div>
  );
});
