import ky from 'ky-universal';

const REQUEST_GET_EMAIL_AVAILABLE = 'REQUEST_GET_EMAIL_AVAILABLE';
const PASS_GET_EMAIL_AVAILABLE = 'PASS_GET_EMAIL_AVAILABLE';
const FAIL_GET_EMAIL_AVAILABLE = 'FAIL_GET_EMAIL_AVAILABLE';

function requestGetEmailAvailable() {
  return { type: REQUEST_GET_EMAIL_AVAILABLE };
}

function passGetEmailAvailable(res) {
  return {
    type: PASS_GET_EMAIL_AVAILABLE,
    available: res.data.available,
    receivedAt: Date.now()
  };
}

function failGetEmailAvailable(error) {
  return {
    error,
    type: FAIL_GET_EMAIL_AVAILABLE,
    receivedAt: Date.now()
  };
}

export function checkEmail(email) {
  return ky.put(`/api/users/email-available`, { json: { email } }).json();
}

function tryGetEmailAvailable(email) {
  return dispatch => {
    dispatch(requestGetEmailAvailable());

    checkEmail(email).then(
      res => dispatch(passGetEmailAvailable(res)),
      async error =>
        dispatch(failGetEmailAvailable(await error?.response?.json?.()))
    );
  };
}

function shouldGetEmailAvailable({ emailAvailable }) {
  if (emailAvailable.isFetching) {
    return false;
  } else if (!emailAvailable.data) {
    return true;
  } else {
    return emailAvailable.lastUpdated < Date.now() - 50;
  }
}

export function getEmailAvailableIfNeeded(email) {
  return (dispatch, getState) => {
    if (shouldGetEmailAvailable(getState())) {
      return dispatch(tryGetEmailAvailable(email));
    }
  };
}

const initialUserState = {
  available: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function getEmailAvailable(state = initialUserState, action) {
  switch (action.type) {
    case REQUEST_GET_EMAIL_AVAILABLE:
      return { ...state, isFetching: true, error: null };
    case PASS_GET_EMAIL_AVAILABLE:
      return {
        ...state,
        available: action.available,
        isFetching: false,
        error: null,
        submitted: true,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_EMAIL_AVAILABLE:
      return {
        ...state,
        available: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
