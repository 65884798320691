import ky from 'ky-universal';

const REQUEST_CREATE_ITEM = 'REQUEST_CREATE_ITEM';
const SUCCESS_CREATE_ITEM = 'SUCCESS_CREATE_ITEM';
const FAIL_CREATE_ITEM = 'FAIL_CREATE_ITEM';
const RESET_CREATE_ITEM = 'RESET_CREATE_ITEM';

export const createItemReset = () => ({ type: RESET_CREATE_ITEM });

function requestCreateItem() {
  return { type: REQUEST_CREATE_ITEM };
}

function successCreateItem({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_CREATE_ITEM
  };
}

function failCreateItem(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_CREATE_ITEM
  };
}

export function createItem(data) {
  return dispatch => {
    dispatch(requestCreateItem());
    return ky
      .post('/api/items', { json: data })
      .json()
      .then(
        res => dispatch(successCreateItem(res)),
        async error => dispatch(failCreateItem(await error?.response?.json()))
      );
  };
}

const initialItemsState = {
  data: {},
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function createdItem(state = initialItemsState, action) {
  switch (action.type) {
    case REQUEST_CREATE_ITEM:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_CREATE_ITEM:
      return {
        ...state,
        data: action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: true
      };
    case FAIL_CREATE_ITEM:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_CREATE_ITEM:
      return initialItemsState;
    default:
      return state;
  }
}
