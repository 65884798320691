import ky from 'ky-universal';

const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER';
const SUCCESS_DELETE_USER = 'SUCCESS_DELETE_USER';
const FAIL_DELETE_USER = 'FAIL_DELETE_USER';
const RESET_DELETE_USER = 'RESET_DELETE_USER';

export const deleteUserReset = () => ({ type: RESET_DELETE_USER });

function requestDeleteUser() {
  return { type: REQUEST_DELETE_USER };
}

function successDeleteUser(res, id) {
  return {
    id,
    receivedAt: Date.now(),
    type: SUCCESS_DELETE_USER
  };
}

function failDeleteUser(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_DELETE_USER
  };
}

export function deleteUser(id) {
  return dispatch => {
    dispatch(requestDeleteUser());
    return ky
      .delete(`/api/users/${id}`)
      .json()
      .then(
        res => dispatch(successDeleteUser(res, id)),
        async error => dispatch(failDeleteUser(await error?.response?.json()))
      );
  };
}

const initialUsersState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null,
  deleted: false
};

export function deletedUser(state = initialUsersState, action) {
  switch (action.type) {
    case REQUEST_DELETE_USER:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_DELETE_USER:
      return {
        ...state,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        deleted: true
      };
    case FAIL_DELETE_USER:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_DELETE_USER:
      return initialUsersState;
    default:
      return state;
  }
}
