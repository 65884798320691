import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

import Nav from '../Nav/Nav';
import CTA from '../CTA/CTA';
import Header from '../Header/Header';
import NavDrawer from '../NavDrawer/NavDrawer';
import Hamburger from '../Hamburger/Hamburger';
import ProgressRing from './components/ProgressRing';

import van1 from '../../../images/van1.jpeg';
import team_bw from './images/team_bw.jpg';
import yellowCouch from './images/yellowCouch.jpg';

import './Home.scss';

let timer;

const carouselContent = [
  {
    img: van1,
    title: 'Palm Beach and up to Jupiter',
    para1:
      'Top-Notch Delivery Service’s immediate coverage area is the Palm Beach County area and up to Jupiter.'
  },
  {
    img: yellowCouch,
    title: 'Addressing Your Design Needs',
    para1:
      'Top-Notch Delivery Service offers delivery, receiving, inspections, hanging,' +
      ' and over 12,000 square feet of climate controlled storage in Delray Beach, Florida.' +
      ' We offer full inspections & provide daily receiving reports.',
    para2:
      'We understand the work that goes into designing a space. Let us take some' +
      ' weight off your shoulders. Top Notch works hand in hand with designers' +
      ' to help make their jobs easier any way we can.'
  },
  {
    img: team_bw,
    title: 'Family Owned & Operated',
    para1:
      'Top-Notch Delivery Service is a family owned and operated company with over' +
      ' 20 years of quality service & experience in the interior design industry.' +
      ' We specialize in working directly with the interior designer.',
    para2:
      'Top-Notch Delivery Service is a partner of the American Society of Interior Designers,' +
      ' which demands the highest integrity & standards of members. Our Mission' +
      ' is to perform the best quality service to our clients.'
  }
];

const active = (match, count) => {
  if (match === count) {
    return 'active';
  } else if (
    match === count + 1 ||
    (count === carouselContent.length - 1 && match === 0)
  ) {
    return 'next';
  } else if (
    match === count - 1 ||
    (count === 0 && match === carouselContent.length - 1)
  ) {
    return 'before';
  }
};

const listItem = (idx, count, fn, pause, mobile) => {
  const activeClass = active(idx, count);
  const isActive = activeClass === 'active'; // :-/

  return (
    <li key={idx} className={activeClass} onClick={() => fn(idx)}>
      {isActive && (
        <ProgressRing
          radius={18}
          stroke={2}
          color={mobile ? '#363636' : '#f9d248'}
          progress={pause ? 100 : null}
          countdown={!pause ? 7000 : null}
        />
      )}
      <span className="count-number">{`0${idx + 1}`}</span>
    </li>
  );
};

const carouselCount = (count, fn, pause, mobile) => (
  <ul className="carousel-count">
    {[0, 1, 2].map(i => listItem(i, count, fn, pause, mobile))}
  </ul>
);

const caroImg = (idx, count) => {
  const { img, styles = {} } = carouselContent[idx];

  return (
    <div
      style={{ backgroundImage: `url(${img})`, ...styles }}
      className={`home-img ${active(idx, count)}`}
    />
  );
};

const mobTextBlock = (idx, count) => (
  <div className={`home-text ${active(idx, count)}`}>
    <h2>{carouselContent[idx].title}</h2>
    <p>{carouselContent[idx].para1}</p>
  </div>
);

const homeTextMob = count => (
  <div className="home-text-contain">
    {mobTextBlock(0, count)}
    {mobTextBlock(1, count)}
    {mobTextBlock(2, count)}
  </div>
);

const deskTextBlock = (idx, count) => (
  <div className={`row three ${active(idx, count)}`}>
    <div /> {/*empty div for grid*/}
    <h2 className="caro-head">{carouselContent[idx].title}</h2>
    <p className="caro-para">{carouselContent[idx].para1}</p>
    <p className="caro-para">{carouselContent[idx].para2}</p>
    <div /> {/*empty div for grid*/}
  </div>
);

const homeTextDesk = count => (
  <div className="row-three-contain">
    {deskTextBlock(0, count)}
    {deskTextBlock(1, count)}
    {deskTextBlock(2, count)}
  </div>
);

const arrows = (count, fn) => (
  <div className="arrows">
    <p
      onClick={
        count < carouselContent.length - 1 ? () => fn(count + 1) : () => fn(0)
      }
    >
      {' '}
      >{' '}
    </p>
    <p
      onClick={
        count > 0 ? () => fn(count - 1) : () => fn(carouselContent.length - 1)
      }
    >
      {' '}
      >{' '}
    </p>
  </div>
);

function Home() {
  const navDrawer = useRef(null);
  const [innerHeight, setInnerHeight] = useState('100vh');
  const [pause, setPause] = useState(false);
  const [caroCount, setCarouselCounter] = useState(0);

  const incCaroCount = () => {
    setCarouselCounter(
      caroCount < carouselContent.length - 1 ? caroCount + 1 : 0
    );
  };

  useEffect(() => {
    timer = window.setInterval(() => incCaroCount(), 7000);
    setPause(false);

    return () => window.clearInterval(timer);
  }, [caroCount]);

  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, []);

  const carouselImg = pause => (
    <div
      className={classNames('img-contain', { pause })}
      onMouseEnter={() => {
        window.clearInterval(timer);
        setPause(true);
      }}
      onMouseLeave={() => {
        timer = window.setInterval(() => incCaroCount(), 7000);
        setPause(false);
      }}
    >
      {caroImg(0, caroCount)}
      {caroImg(1, caroCount)}
      {caroImg(2, caroCount)}
    </div>
  );

  return (
    <div className="tn-home" style={{ height: innerHeight }}>
      <div className="tn-home-mob" style={{ height: innerHeight }}>
        <Header />
        <Hamburger onClick={() => navDrawer?.current?.toggle()} />
        {homeTextMob(caroCount)}
        {carouselCount(caroCount, setCarouselCounter, pause, true)}
        <CTA />
        {arrows(caroCount, setCarouselCounter)}
        {carouselImg(pause)}
        <div className="filler-div" /> {/*empty div for grid*/}
        <NavDrawer ref={navDrawer} />
      </div>

      <div className="tn-home-desk">
        <div className="row one">
          <Header />
          <CTA />
        </div>
        <div className="row two">
          <Nav />
          {carouselImg(pause)}
          <div className="controls">
            {carouselCount(caroCount, setCarouselCounter, pause)}
            {arrows(caroCount, setCarouselCounter)}
          </div>
        </div>
        {homeTextDesk(caroCount)}
        <div className="row four">
          <div className="gray-color" />
          <div /> {/*Empty div for grid*/}
          <div className="yellow-color" />
        </div>
      </div>
    </div>
  );
}

export default Home;
