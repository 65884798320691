import ky from 'ky-universal';

const REQUEST_GET_USERNAME_AVAILABLE = 'REQUEST_GET_USERNAME_AVAILABLE';
const PASS_GET_USERNAME_AVAILABLE = 'PASS_GET_USERNAME_AVAILABLE';
const FAIL_GET_USERNAME_AVAILABLE = 'FAIL_GET_USERNAME_AVAILABLE';

function requestGetUsernameAvailable() {
  return { type: REQUEST_GET_USERNAME_AVAILABLE };
}

function passGetUsernameAvailable(res) {
  return {
    type: PASS_GET_USERNAME_AVAILABLE,
    available: res.data.available,
    receivedAt: Date.now()
  };
}

function failGetUsernameAvailable(error) {
  return {
    error,
    type: FAIL_GET_USERNAME_AVAILABLE,
    receivedAt: Date.now()
  };
}

export function checkUsername(name) {
  return ky.get(`/api/users/username-available?username=${name}`).json();
}

function tryGetUsernameAvailable(name) {
  return dispatch => {
    dispatch(requestGetUsernameAvailable());

    checkUsername(name).then(
      res => dispatch(passGetUsernameAvailable(res)),
      async error =>
        dispatch(failGetUsernameAvailable(await error?.response?.json?.()))
    );
  };
}

function shouldGetUsernameAvailable({ usernameAvailable }) {
  if (usernameAvailable.isFetching) {
    return false;
  } else if (!usernameAvailable.data) {
    return true;
  } else {
    return usernameAvailable.lastUpdated < Date.now() - 50;
  }
}

export function getUsernameAvailableIfNeeded(name) {
  return (dispatch, getState) => {
    if (shouldGetUsernameAvailable(getState())) {
      return dispatch(tryGetUsernameAvailable(name));
    }
  };
}

const initialUserState = {
  available: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function getUsernameAvailable(state = initialUserState, action) {
  switch (action.type) {
    case REQUEST_GET_USERNAME_AVAILABLE:
      return { ...state, isFetching: true, error: null };
    case PASS_GET_USERNAME_AVAILABLE:
      return {
        ...state,
        available: action.available,
        isFetching: false,
        error: null,
        submitted: true,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_USERNAME_AVAILABLE:
      return {
        ...state,
        available: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
