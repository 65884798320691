import React from 'react';
import Loader from 'react-loader-spinner';
import { CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';

export default function GlobalLoader() {
  const loading = useSelector(s => s.loading.now);

  return (
    <CSSTransition in={loading} timeout={200} classNames="loader" unmountOnExit>
      <Loader type="ThreeDots" className="loader" color="#303236" />
    </CSSTransition>
  );
}
