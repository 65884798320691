import ky from 'ky-universal';
import { formatDate, getStatus } from '../etc/formatFns';

const REQUEST_GET_JOB = 'REQUEST_GET_JOB';
const SUCCESS_GET_JOB = 'SUCCESS_GET_JOB';
const FAIL_GET_JOB = 'FAIL_GET_JOB';
const RESET_GET_JOB = 'RESET_GET_JOB';

export const getJobReset = () => ({ type: RESET_GET_JOB });

function requestGetJob() {
  return { type: REQUEST_GET_JOB };
}

function successGetJob({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_GET_JOB
  };
}

function failGetJob(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_JOB
  };
}

export function getJob(id) {
  return dispatch => {
    dispatch(requestGetJob());
    return ky
      .get(`/api/jobs/${id}`)
      .json()
      .then(
        res => {
          return dispatch(successGetJob(res));
        },
        async error => {
          return dispatch(failGetJob(await error?.response?.json()));
        }
      );
  };
}

const initialJobState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function job(state = initialJobState, action) {
  switch (action.type) {
    case REQUEST_GET_JOB:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_JOB:
      const d = action.data;
      d.updatedAt = d.updatedAt && formatDate(d.updatedAt);
      d.createdAt = d.createdAt && formatDate(d.createdAt);
      d.statusText = getStatus(d.status);
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_JOB:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_GET_JOB:
      return initialJobState;
    default:
      return state;
  }
}
