import React, { useRef, useEffect } from 'react';
import queryString from 'qs';

import { Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';

import Dropdown from '../Dropdown/Dropdown';
import FilterBar from '../FilterBar/FilterBar';
import { Form, Field } from '../../core/Forms';

import { logoutIfNeeded } from '../../../store/lib/user/user';

import './IMSTopBar.scss';

import add from './add.svg';
import searchIcon from './search.svg';

const mapStateToProps = ({ user: { data: userData } }) => ({ userData });

import hatLogo from '../../../images/topNotchJustHat@2x.png';
import useGetActions from '../../hooks/useGetActions';

export default connect(mapStateToProps)(function IMSTopBar({
  tableKey,
  toggleMobileNav,
  sortFn,
  searchFn,
  columns,
  search,
  noSearch,
  filterInfo,
  setAddNew,
  userData,
  location,
  listView,
  setListView,
  printFn
}) {
  const formik = useRef(null);
  const actions = useGetActions(tableKey);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutIfNeeded());
  };

  const parsedSearch =
    queryString.parse(search?.replace('%3F', '').replace('?', '')) || null;

  useEffect(() => {
    if (!!search) {
      formik?.current?.resetForm();
    }
  }, [search]);

  return (
    <div className="ims-bar-contain">
      <div className="ims-bar">
        <div className="hamburger" onClick={toggleMobileNav} />
        {!noSearch && (
          <img src={searchIcon} alt="mag glass" className="search-icon" />
        )}
        {!noSearch && (
          <Formik
            initialValues={{
              textSearch: parsedSearch?.text || ''
            }}
            onSubmit={values => {
              searchFn(values.textSearch);
            }}
            innerRef={formik}
          >
            <Form>
              <Field
                name="textSearch"
                className="search-box span-all"
                placeholder="search"
                label="search"
              />
            </Form>
          </Formik>
        )}
        <Dropdown
          arrow
          extraClass="account-dropdown"
          image={userData.photoUrl || hatLogo}
          outsideText={`Hi, ${userData.name}!`}
        >
          <div onClick={logout}>Log Out</div>
        </Dropdown>
        {actions?.create && (
          <img src={add} alt="plus" className="add" onClick={setAddNew} />
        )}
      </div>
      {columns && (
        <FilterBar
          listView={listView}
          setListView={setListView}
          printFn={printFn}
          location={location}
          tableKey={tableKey}
          sortFn={sortFn}
          columns={columns}
          search={parsedSearch}
          filterInfo={filterInfo}
          searchActive={!!search}
        />
      )}
    </div>
  );
});

//TODO hide and show filters on search click
