/**
 * @typedef {number} BitFlag
 * Number in the form of  2^n, for use in using & denoting bit flags.
 *
 * e.g. `1`, `2`, `4`, `8`, `0b1000`, `2 ** 3`
 */

module.exports = {
  /**
   * @typedef {object} UserGroups
   *
   * Group values as bit flags.
   *
   * @property {BitFlag} root - `1`
   * @property {BitFlag} admin - `2`, i.e. managers
   * @property {BitFlag} billing - `4`, i.e. accountants
   * @property {BitFlag} employee - `8`, e.g. field worker
   * @property {BitFlag} client - `16`, e.g. designer
   *
   */
  groups: {
    root: 2 ** 0,
    admin: 2 ** 1,
    billing: 2 ** 2,
    employee: 2 ** 3,
    client: 2 ** 4
  },

  /**
   *
   * @typedef {object} ObjectPermissions
   *
   * Unix style permission set.
   * reference: https://en.wikipedia.org/wiki/File_system_permissions#Numeric_notation.
   *
   * @property {object} other - applies to all.
   * @property {BitFlag} other.del - `1`
   * @property {BitFlag} other.write - `2`
   * @property {BitFlag} other.read - `4`
   *
   * @property {object} group - applies to users in owning group
   * @property {BitFlag} group.del - `8`
   * @property {BitFlag} group.write - `16`
   * @property {BitFlag} group.read - `32`
   *
   * @property {object} owner - applies only to owner
   * @property {BitFlag} owner.del - `64`
   * @property {BitFlag} owner.write - `128`
   * @property {BitFlag} owner.read - `256`
   *
   */
  permissions: {
    other: {
      del: 2 ** 0,
      write: 2 ** 1,
      read: 2 ** 2
    },

    group: {
      del: 2 ** 3,
      write: 2 ** 4,
      read: 2 ** 5
    },

    owner: {
      del: 2 ** 6,
      write: 2 ** 7,
      read: 2 ** 8
    }
  },

  /**
   *
   * @typedef {object} ObjectStatuses
   *
   * Common statuses as bit flags.
   *
   * @property {BitFlag} deleted - `1`, 'archived' may be more accurate.
   * @property {BitFlag} inactive - `2`
   * @property {BitFlag} pending - `4`, could also be 'draft'
   * @property {BitFlag} active - `8`
   * @property {BitFlag} late - `16`, i.e. overdue
   *
   */
  statuses: {
    deleted: 2 ** 0,
    inactive: 2 ** 1,
    pending: 2 ** 2,
    active: 2 ** 3,
    late: 2 ** 4
  }
};
