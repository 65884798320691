import ky from 'ky-universal';
import { formatDate, formatDataDate, getStatus } from '../etc/formatFns';

const REQUEST_GET_ITEM = 'REQUEST_GET_ITEM';
const SUCCESS_GET_ITEM = 'SUCCESS_GET_ITEM';
const FAIL_GET_ITEM = 'FAIL_GET_ITEM';
const RESET_GET_ITEM = 'RESET_GET_ITEM';

export const getItemReset = () => ({ type: RESET_GET_ITEM });

function requestGetItem() {
  return { type: REQUEST_GET_ITEM };
}

function successGetItem({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_GET_ITEM
  };
}

function failGetItem(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_ITEM
  };
}

export function getItem(id) {
  return dispatch => {
    dispatch(requestGetItem());
    return ky
      .get(`/api/items/${id}`)
      .json()
      .then(
        res => {
          return dispatch(successGetItem(res));
        },
        async error => {
          return dispatch(failGetItem(await error?.response?.json()));
        }
      );
  };
}

const initialItemState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function item(state = initialItemState, action) {
  switch (action.type) {
    case REQUEST_GET_ITEM:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_ITEM:
      const d = action.data;
      d.createdAt = d.createdAt && formatDate(d.createdAt);
      d.dateReceived = d.dateReceived && formatDataDate(d.dateReceived);
      d.dateDelivered = d.dateDelivered && formatDataDate(d.dateDelivered);
      d.statusText = getStatus(d.status, 'item');
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_ITEM:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_GET_ITEM:
      return initialItemState;
    default:
      return state;
  }
}
