import { isToday, isThisYear, format, isValid } from 'date-fns';

export function formatDate(date) {
  date = new Date(date);

  if (!isValid(date)) return;

  if (isToday(date)) {
    return format(date, 'h:mm aa');
  } else if (isThisYear(date)) {
    return format(date, 'MMMM d');
  } else {
    return format(date, 'MM/dd/yyyy');
  }
}

export function formatDataDate(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function getStatus(num, type) {
  switch (num) {
    case 1:
      return 'Archived';
    case 2:
      return 'Inactive';
    case 4:
      return 'Draft';
    case 8:
      return type === 'invoice' ? 'Unsent' : 'Active';
    case 16:
      return 'Late';
    case 32:
      return 'Sent';
    case 64:
      return type === 'invoice' ? 'Paid' : 'In Storage';
    case 128:
      return 'In Transit';
    case 256:
      return 'Delivered';
  }
}

export function formatDollars(float) {
  if (typeof float === 'string') float = parseFloat(float);
  if (typeof float !== 'number') return null;

  return float.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}
