import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import './TNButton.scss';

function TNButton({
  extraClass,
  className,
  children,
  border,
  highlight,
  tiny,
  ...props
}) {
  const btnClass = classNames('tn-button', extraClass, className, {
    tiny,
    border,
    highlight
  });

  return (
    <button className={btnClass} {...props}>
      {children}
    </button>
  );
}

export default TNButton;

TNButton.propTypes = {
  children: PropTypes.any,
  border: PropTypes.bool,
  highlight: PropTypes.bool,
  extraClass: PropTypes.string
};

TNButton.defaultProps = {
  children: null,
  border: false,
  highlight: false,
  extraClass: ''
};
