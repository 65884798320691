import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

Label.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

Label.defaultProps = {
  className: '',
  disabled: false
};

export default function Label({ field, form, disabled, className, children }) {
  const error = form?.touched?.[field?.name] && form.errors[field.name];

  const labelClasses = classNames('field-label', className, {
    'field-label-error': error,
    'field-label-clear': field.value === '',
    'field-label-disabled': disabled
  });

  return <label className={labelClasses}>{children}</label>;
}
