import React, { useRef, forwardRef, useImperativeHandle } from 'react';

import Downshift from 'downshift';

import get from 'lodash.get';
import debounce from 'lodash.debounce';

import Field from './Field';

function Autocomplete(
  {
    onSelect,
    onChange,
    searchFn,
    data,
    fieldInfo,
    keyKey,
    dataKey,
    isTypeahead,
    formikInfo,
    disabled
  },
  ref
) {
  const { setFieldValue, field } = formikInfo || {};

  const input = useRef(null);
  const downshift = useRef(null);

  const debouncedSearchFn = useRef(
    debounce(searchFn, 500, { leading: true, trailing: false })
  );

  function handleTypeaheadChange(changes) {
    if (changes.hasOwnProperty('selectedItem')) {
      setFieldValue?.(field, changes.selectedItem[dataKey]);
    } else if (changes.hasOwnProperty('inputValue')) {
      changes.inputValue && debouncedSearchFn.current(changes.inputValue);
      setFieldValue?.(field, changes.inputValue);

      if (onSelect) {
        onSelect(changes.inputValue);
      } else {
        setFieldValue(field, '');
      }
    }
  }

  function handleStateChange(changes) {
    onChange?.(changes);
    setFieldValue?.(field, changes.inputValue);

    if (changes.inputValue) {
      debouncedSearchFn.current(changes.inputValue);
    }
  }

  useImperativeHandle(ref, () => ({
    reset: downshift?.current?.reset,
    clear: downshift?.current?.clearSelection
  }));

  const extraDownshiftProps = {};

  if (formikInfo?.values) {
    const val = formikInfo?.values
      ? get(formikInfo.values, fieldInfo.name)
      : '';

    extraDownshiftProps.initialInputValue = val;
    extraDownshiftProps.inputValue = val;
  }

  return (
    <Downshift
      onSelect={onSelect}
      itemToString={item => item?.[dataKey] || item || ''}
      defaultHighlightedIndex={0}
      onStateChange={
        formikInfo && isTypeahead ? handleTypeaheadChange : handleStateChange
      }
      selectedItem={isTypeahead && get(formikInfo?.values, formikInfo?.field)}
      initialInputValue=""
      ref={downshift}
      {...extraDownshiftProps}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem
      }) => (
        <div>
          {fieldInfo ? (
            <div ref={input}>
              <Field
                {...getInputProps()}
                name={fieldInfo.name}
                label={fieldInfo.label}
                helperText={fieldInfo.helperText}
                autoComplete="off"
                disabled={disabled}
              />
            </div>
          ) : (
            <input
              {...getInputProps()}
              placeholder="Start typing..."
              className="field"
              ref={input}
              disabled={disabled}
            />
          )}
          <ul
            {...getMenuProps()}
            className={`typeahead-list ${data?.length && 'open'} `}
          >
            {isOpen && data?.length
              ? data.map((item, index) => (
                  <li
                    {...getItemProps({
                      key: item[keyKey || dataKey],
                      index,
                      item,
                      style: {
                        backgroundColor:
                          highlightedIndex === index ? 'lightgray' : null,
                        fontWeight: selectedItem === item ? 'bold' : 'normal'
                      }
                    })}
                  >
                    {item[dataKey]}
                  </li>
                ))
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
}

Autocomplete = forwardRef(Autocomplete);
export default Autocomplete;
