import ky from 'ky-universal';

const REQUEST_GET_ADDRESSES = 'REQUEST_GET_ADDRESSES';
const SUCCESS_GET_ADDRESSES = 'SUCCESS_GET_ADDRESSES';
const FAIL_GET_ADDRESSES = 'FAIL_GET_ADDRESSES';

function requestGetAddresses() {
  return { type: REQUEST_GET_ADDRESSES };
}

function successGetAddresses({ data, page }, next) {
  return {
    data,
    page,
    wasPaging: !!next,
    receivedAt: Date.now(),
    type: SUCCESS_GET_ADDRESSES
  };
}

function failGetAddresses(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_ADDRESSES
  };
}

function tryGetAddresses(next, search) {
  return dispatch => {
    dispatch(requestGetAddresses());

    return ky
      .get(next || `/api/addresses${search}`)
      .json()
      .then(
        res => dispatch(successGetAddresses(res, next)),
        async error => dispatch(failGetAddresses(await error?.response?.json()))
      );
  };
}

function shouldGetAddresses({ addresses }) {
  if (addresses.isFetching) {
    return false;
  } else if (!addresses.data.length) {
    return true;
  } else {
    return addresses.lastUpdated < Date.now() - 50;
  }
}

export function getAddresses({ next, search = null } = {}) {
  return (dispatch, getState) => {
    if (shouldGetAddresses(getState())) {
      return dispatch(tryGetAddresses(next, search));
    }
  };
}

const initialAddressesState = {
  data: [],
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function addresses(state = initialAddressesState, action) {
  switch (action.type) {
    case REQUEST_GET_ADDRESSES:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_ADDRESSES:
      return {
        ...state,
        data: action.wasPaging ? [...state.data, ...action.data] : action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_ADDRESSES:
      return {
        ...state,
        data: [],
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
