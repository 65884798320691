import React from 'react';
import { Link } from 'react-router-dom';

import Page from '../Page/Page';

import truck2 from '../../../images/truck2.jpeg';
import warehouse1 from './images/warehouse1.jpeg';
import room from './images/room.jpg';
import grate from './images/grate.jpg';

const servicesContent = {
  title: (
    <div className="page-title-container">
      {/* the zero-width space (&#8203;) for some reason makes this work on Safari */}
      <h2 data-text="Services We Provide&#8203;" className="page-title">
        Services We Provide&#8203;
      </h2>
      <h2 data-text="for Designers&#8203;" className="page-title">
        for Designers&#8203;
      </h2>
    </div>
  ),
  deskTitle: 'We Help You Work',
  body: (
    <div className="page-main-body">
      <p>
        Here at Top-Notch, we focus on your needs as the interior designer. We
        understand the work that goes into designing a space, so we aim to take
        some of that weight off of your shoulders. We work hand in hand with
        designers to help make their jobs easier in any way we can.
      </p>

      <p>
        We offer full inspections on items received in our warehouse and provide
        daily receiving reports. Our delivery service includes blanket wrapped
        service, inside delivery, installation and debris removal.
      </p>

      <p>
        Our full range of services includes:
        <ul>
          <li>White Glove Service</li>
          <li>Receiving</li>
          <li>Pick-ups, and Delivery</li>
          <li>Installation</li>
          <li>Consolidation</li>
          <li>Assembly</li>
          <li>Climate Controlled Warehouse</li>
          <li>24 hour Security Surveillance</li>
          <li>Furniture Inspections</li>
          <li>Art & Mirror Hanging</li>
          <li>Import/Export</li>
        </ul>
      </p>

      <p>
        We also hang art and mirrors. Hanging art doesn’t just consist of
        putting a nail in the wall. There are many factors that come into play
        before the art even touches the wall. Some key factors of hanging art is
        placement, location, and room flow. Top-Notch prides itself in making
        sure your art stays safe during delivery and installation. Need to hear
        more or need help hanging? <Link to="/contact"> Call us today!</Link>
      </p>
    </div>
  )
};

function Services() {
  return (
    <Page
      topImg={room}
      bottomImg={grate}
      deskImg1={warehouse1}
      deskImg2={truck2}
      text={{
        title: servicesContent.title,
        body: servicesContent.body,
        deskTitle: servicesContent.deskTitle
      }}
    />
  );
}

export default Services;
