import React from 'react';
import get from 'lodash.get';
import classNames from 'classnames';

import './TableRow.scss';

import edit from './edit.svg';
import close from '../../../../images/close.svg';

const makeClass = c =>
  c[0]
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace('/', '-')
    .replace('#', 'number');

function TableRows({
  data,
  bodyClass,
  columns,
  dataKeys,
  editClick,
  deleteClick
}) {
  const classedColumnArray = columns.map(makeClass);

  if (!data || !data.length) return null;

  const rows = data.map((row, ridx) => {
    const cells = dataKeys.map((key, idx) => {
      const rawData = get(row, key);

      const colClasses = classNames({
        [classedColumnArray[idx] + '-cell']: true
      });

      return (
        <td className={colClasses} key={idx}>
          {rawData}
        </td>
      );
    });
    return (
      <tr key={row?.id}>
        {cells}
        <td className="edit-cell" onClick={() => editClick(row)}>
          <img src={edit} alt="pencil" />
        </td>
        <td className="delete-cell" onClick={() => deleteClick(row)}>
          <img src={close} alt="x" />
        </td>
      </tr>
    );
  });

  return <tbody className={bodyClass}>{rows}</tbody>;
}

export default TableRows;
