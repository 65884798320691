import React from 'react';
import TNButton from '../Site/TNButton/TNButton';

import './FourOhFour.scss';

export default function FourOhFour({ history }) {
  const back = e => {
    e?.stopPropagation();
    history?.goBack();
  };

  return (
    <div className="errorPage">
      <h1>404</h1>
      <h3>The page you were looking for does not exist.</h3>
      <p>Alas, even delivery experts cannot deliver what does not exist.</p>

      <TNButton onClick={back}>Go Back</TNButton>
    </div>
  );
}
