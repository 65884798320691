import ky from 'ky-universal';

const REQUEST_GET_JOBS_COUNT = 'REQUEST_GET_JOBS_COUNT';
const SUCCESS_GET_JOBS_COUNT = 'SUCCESS_GET_JOBS_COUNT';
const FAIL_GET_JOBS_COUNT = 'FAIL_GET_JOBS_COUNT';

function requestGetJobsCount() {
  return { type: REQUEST_GET_JOBS_COUNT };
}

function successGetJobsCount({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_GET_JOBS_COUNT
  };
}

function failGetJobsCount(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_JOBS_COUNT
  };
}

export function getJobsCount() {
  return dispatch => {
    dispatch(requestGetJobsCount());
    return ky
      .get('/api/jobs/count')
      .json()
      .then(
        res => dispatch(successGetJobsCount(res)),
        async error => dispatch(failGetJobsCount(await error?.response?.json()))
      );
  };
}

const initialJobsCountState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function jobsCount(state = initialJobsCountState, action) {
  switch (action.type) {
    case REQUEST_GET_JOBS_COUNT:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_JOBS_COUNT:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_JOBS_COUNT:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
