import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Label from './Label';

FieldInner.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,

  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,

  disabled: PropTypes.bool,

  className: PropTypes.string,
  classInput: PropTypes.string,
  classLabelText: PropTypes.string,
  classHelperText: PropTypes.string,

  noHelperText: PropTypes.bool,
  reverseContent: PropTypes.bool,

  type: PropTypes.string
};

FieldInner.defaultProps = {
  helperText: '',
  disabled: false,
  className: '',
  classInput: '',
  classLabelText: '',
  classHelperText: '',
  noHelperText: false,
  type: 'text'
};

export default function FieldInner({
  form,
  field,
  label,
  type,
  disabled,
  helperText,
  className,
  classInput,
  classLabelText,
  classHelperText,
  noHelperText,
  children,
  ...props
}) {
  let error = form?.touched?.[field?.name] && form?.errors?.[field?.name];

  if (Array.isArray(error)) error = error.filter(Boolean).join(', ');

  const inputProps = {
    type,
    disabled,
    ...field,
    ...props,
    className: classNames('field', classInput)
  };

  const input =
    type === 'textarea' ? (
      <textarea {...inputProps} />
    ) : (
      <input {...inputProps} />
    );

  return (
    <Label {...{ form, field, disabled, className }}>
      <span className={classNames('field-label-text', classLabelText)}>
        {label}
      </span>
      {children ? children : <>{input}</>}
      {!noHelperText && (
        <span className={classNames('field-helper-text', classHelperText)}>
          {error || helperText}
        </span>
      )}
    </Label>
  );
}
