import ky from 'ky-universal';

const REQUEST_GET_ADDRESS = 'REQUEST_GET_ADDRESS';
const SUCCESS_GET_ADDRESS = 'SUCCESS_GET_ADDRESS';
const FAIL_GET_ADDRESS = 'FAIL_GET_ADDRESS';
const RESET_GET_ADDRESS = 'RESET_GET_ADDRESS';

export const getAddressReset = () => ({ type: RESET_GET_ADDRESS });

function requestGetAddress() {
  return { type: REQUEST_GET_ADDRESS };
}

function successGetAddress({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_GET_ADDRESS
  };
}

function failGetAddress(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_ADDRESS
  };
}

export function getAddress(id) {
  return dispatch => {
    dispatch(requestGetAddress());
    return ky
      .get(`/api/addresses/${id}`)
      .json()
      .then(
        res => {
          return dispatch(successGetAddress(res));
        },
        async error => {
          return dispatch(failGetAddress(await error?.response?.json()));
        }
      );
  };
}

const initialAddressState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function address(state = initialAddressState, action) {
  switch (action.type) {
    case REQUEST_GET_ADDRESS:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_ADDRESS:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_ADDRESS:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_GET_ADDRESS:
      return initialAddressState;
    default:
      return state;
  }
}
