import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Home from './Site/Home/Home';
import { userIsAuthenticated, userIsNotAuthenticated } from './core/authGates';

import About from './Site/About/About';
import Services from './Site/Services/Services';
import CoverageArea from './Site/CoverageArea/CoverageArea';
import Contact from './Site/Contact/Contact';
import FormExample from './Site/FormExample/FormExample';

import IMS from './IMS/IMSWrap/IMSWrap';
import LoginShell from './Site/Login/LoginShell';
import GlobalLoader from './IMS/Loader/GlobalLoader';

import FourOhFour from './errors/FourOhFour';
import FiveHundy from './errors/FiveHundy';

import '../styles/App.css';
import '../styles/global.scss';

const App = function App() {
  return (
    <>
      <GlobalLoader />
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/services" component={Services} />
        <Route path="/coverage" component={CoverageArea} />
        <Route path="/contact" component={Contact} />
        <Route path="/login" component={userIsNotAuthenticated(LoginShell)} />
        <Route path="/ims" component={userIsAuthenticated(IMS)} />
        <Route path="/form" component={FormExample} />
        <Route path="/500" component={FiveHundy} />
        <Route component={FourOhFour} />
      </Switch>
    </>
  );
};

export default hot(App);
