import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import classNames from 'classnames';

import Nav from '../Nav/Nav';

import './NavDrawer.scss';

function NavDrawer(props, ref) {
  const [closed, setClosed] = useState(true);
  const navDrawerRef = useRef();

  const navDrawerClasses = classNames('nav-drawer', {
    closed: closed
  });

  const toggle = () => {
    setClosed(!closed);
  };

  useImperativeHandle(ref, () => ({
    toggle: toggle
  }));

  return (
    <div className={navDrawerClasses} ref={navDrawerRef}>
      <div className="close" onClick={() => toggle()} />
      <Nav navOnClick={() => toggle()} />
    </div>
  );
}

NavDrawer = forwardRef(NavDrawer);
export default NavDrawer;

//TODO hamburger is nearly unclickable
