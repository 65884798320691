import React from 'react';
import { Formik } from 'formik';

import {
  Form,
  Field,
  FieldArray,
  Textarea,
  Checkbox,
  RadioGroup,
  SelectGroup
} from '../../core/Forms';

import * as yup from 'yup';
import TNButton from '../TNButton/TNButton';

const schema = yup.object().shape({
  name: yup.string().required('Please enter a name.'),
  age: yup
    .number()
    .required('Age is required.')
    .positive('Age must be a positive number.')
    .integer()
    .min(18, 'Must be at least 18.'),
  email: yup.string().email('Please enter a valid email.')
});

const defaultPlant = { sciName: '', commonName: '' };

const FormExample = () => (
  <div style={{ padding: '5rem' }}>
    <h1>Hi! This is the form example page.</h1>

    <p>
      Check out the <a href={'https://jaredpalmer.com/formik/docs/overview'}>Formik docs</a> for
      more info.
    </p>

    <Formik
      validationSchema={schema}
      initialValues={{
        name: '',
        age: '',
        email: 'your@mail.com',
        cat: '',
        cheese: '',
        doge: false,
        description: '',
        plantsWeHave: [defaultPlant]
      }}
      onSubmit={(values, { setSubmitting }) =>
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400)
      }
    >
      {({ isSubmitting, values, errors, touched }) => {
        return (
          <Form legend="Test Form">
            <Field
              name="name"
              label="Test Label"
              helperText="This is Helper Text"
              placeholder="Jiminy Cricket"
            />
            <Field type="number" name="age" label="Age" placeholder="28" />
            <Field
              disabled
              type="email"
              name="email"
              label="Email"
              helperText="Email is disabled"
              placeholder="me@mail.com"
            />

            <Checkbox name="doge" label="Has Doge?" />

            <RadioGroup
              id="cheese"
              label="Pick a cheese, please"
              value={values.cheese}
              error={errors.cheese}
              touched={touched.cheese}
              options={[
                ['gruyere', 'Gruyere'],
                ['mozzarella', 'Mozzarella'],
                ['gorgonzola', 'Gorgonzola']
              ]}
            />

            <SelectGroup
              name="cat"
              label="Pick a cat, please"
              helperText="Which is hungriest?"
              options={[['miyamu', 'Miyamu'], ['orange', 'Orange'], ['henry', 'Henry']]}
            />

            <Textarea
              name="description"
              label="Item Description"
              helperText="Please describe in 500 characters or less."
            />

            <FieldArray
              name="plantsWeHave"
              values={values}
              legend="A Plant We Have"
              defaultValue={defaultPlant}
              addButtonText="Add a Plant"
            >
              {(plant, idx) => (
                <>
                  <Field
                    name={`plantsWeHave.${idx}.sciName`}
                    label="Scientific Name"
                    helperText="Gimmie the Latin"
                    placeholder="Davallia Fejeensis"
                  />
                  <Field
                    name={`plantsWeHave.${idx}.commonName`}
                    label="Common Name"
                    helperText="English please"
                    placeholder="Rabbit's Foot Fern"
                  />
                </>
              )}
            </FieldArray>

            <TNButton
              border
              type="submit"
              extraClass="span-all form-submit"
              disabled={isSubmitting}
            >
              Submit
            </TNButton>
          </Form>
        );
      }}
    </Formik>
  </div>
);

export default FormExample;
