import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { groups } from '../../../api/db/config/access';

import classNames from 'classnames';

import './IMSNav.scss';

const dashIcon = (
  <svg
    className="dash-icon icon"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g className="b" transform="translate(0)">
      <path
        className="c"
        d="M11.686,3.89,6.436.14a.75.75,0,0,0-.872,0L.314,3.89a.75.75,0,0,0,.872,1.221L1.5,4.886V11.25a.75.75,0,0,0,.75.75h7.5a.75.75,0,0,0,.75-.75V4.886a1.278,1.278,0,0,0,.749.364.75.75,0,0,0,.437-1.36ZM9,10.5H7.5V8.25a1.5,1.5,0,0,0-3,0V10.5H3V3.815L6,1.672,9,3.815Z"
        transform="translate(0)"
      />
    </g>
  </svg>
);

const invoicesIcon = (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="12"
    viewBox="0 0 10 12"
  >
    <path
      className="a"
      d="M10.286,0H1.714A.692.692,0,0,0,1,.75V12l2.143-1.5L4.571,12,6,10.5,7.429,12l1.429-1.5L11,12V.75A.692.692,0,0,0,10.286,0ZM8.857,7.5H3.143V6H8.857Zm0-3H3.143V3H8.857Z"
      transform="translate(-1)"
    />
  </svg>
);

const jobsIcon = (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="11.077"
    viewBox="0 0 12 11.077"
  >
    <path
      className="a"
      d="M48.923,75.077H59.077A.922.922,0,0,0,60,74.154V68.616H48v5.539A.922.922,0,0,0,48.923,75.077Zm10.154-9.231h-.231v-.231a.231.231,0,0,0-.231-.231h-.923a.231.231,0,0,0-.231.231v.231h-.808v-.923A.926.926,0,0,0,55.731,64H52.269a.926.926,0,0,0-.923.923v.923h-.808v-.231a.231.231,0,0,0-.231-.231h-.923a.231.231,0,0,0-.231.231v.231h-.231a.922.922,0,0,0-.923.923v1.154H60V66.769A.922.922,0,0,0,59.077,65.846Zm-3.231,0H52.154v-.808a.231.231,0,0,1,.231-.231h3.231a.231.231,0,0,1,.231.231Z"
      transform="translate(-48 -64)"
    />
  </svg>
);

const itemsIcon = (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      className="a"
      d="M7.5-207v-12H9v12ZM-3-207v-12h1.5v12Zm8.25-3.75V-219h1.5v8.25Zm-3.75,0V-219h3v8.25Zm-2.25,0V-219H.75v8.25Z"
      transform="translate(3 219)"
    />
  </svg>
);

const usersIcon = (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="10.5"
    height="12"
    viewBox="0 0 10.5 12"
  >
    <g className="b" transform="translate(0)">
      <path
        className="c"
        d="M-4-314a4.513,4.513,0,0,1,4.5-4.5H2A4.513,4.513,0,0,1,6.5-314Zm1.949-1.5H4.625A3.025,3.025,0,0,0,2-317H.575A3.026,3.026,0,0,0-2.05-315.5Zm.3-6.75V-323a3.009,3.009,0,0,1,3-3,3.009,3.009,0,0,1,3,3v.75a3.009,3.009,0,0,1-3,3A3.009,3.009,0,0,1-1.75-322.25Zm1.5-.75v.75a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5V-323a1.5,1.5,0,0,0-1.5-1.5A1.5,1.5,0,0,0-.25-323Z"
        transform="translate(4 326)"
      />
    </g>
  </svg>
);

const settingsIcon = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="cog"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className="icon"
  >
    <path d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z" />
  </svg>
);

function IMSNav({ showMobileNav, isMobile, toggleMobileNav }) {
  const user = useSelector(({ user }) => user);

  const canList = _table =>
    user.data?.tablePrivileges
      ?.find(({ table }) => table === _table)
      ?.actions?.includes('ls');

  const navListClasses = classNames('ims-nav', {
    show: showMobileNav
  });

  return (
    <nav
      className={navListClasses}
      onClick={isMobile ? toggleMobileNav : () => {}}
    >
      <div className="app-name">
        Top-Notch <span>IMS</span>
      </div>
      <ul>
        {canList('invoices') && (
          <NavLink to="/ims/dash">
            <li>{dashIcon}Dashboard</li>
          </NavLink>
        )}
        {canList('invoices') && (
          <NavLink to="/ims/invoices">
            <li>{invoicesIcon}Invoices</li>
          </NavLink>
        )}
        {canList('jobs') && (user.data.memberships & groups.client) === 0 && (
          <NavLink to="/ims/jobs">
            <li>{jobsIcon}Jobs</li>
          </NavLink>
        )}
        {canList('items') && (
          <NavLink to="/ims/items">
            <li>{itemsIcon}Items</li>
          </NavLink>
        )}
        {canList('users') && (
          <NavLink to="/ims/users">
            <li>{usersIcon}Users</li>
          </NavLink>
        )}
        <NavLink to="/ims/settings" className="settings">
          <li>{settingsIcon}Settings</li>
        </NavLink>
      </ul>
    </nav>
  );
}

export default IMSNav;
