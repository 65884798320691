import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field as FormikField } from 'formik';

import Label from './shared/Label';

Checkbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func
};

Checkbox.defaultProps = {
  className: '',
  disabled: false,
  validate: undefined
};

export default function Checkbox({
  name,
  validate,
  label,
  disabled,
  className,
  ...props
}) {
  return (
    <FormikField name={name} validate={validate}>
      {fieldProps => (
        <Label
          className={classNames('checkbox', className)}
          {...{ ...fieldProps, disabled }}
        >
          <input
            disabled={disabled}
            type="checkbox"
            className="field checkbox-field"
            checked={fieldProps.field?.value === true}
            {...fieldProps.field}
            {...props}
          />
          <span className="field-label-text checkbox-label-text">{label}</span>
        </Label>
      )}
    </FormikField>
  );
}
