import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import './Nav.scss';

function Nav({ navOnClick, extra }) {
  const navClasses = classNames('nav', {
    extra
  });
  return (
    <nav className={navClasses}>
      <ul>
        <li>
          <NavLink to="/about" onClick={navOnClick}>
            About
          </NavLink>
        </li>
        <li>
          <NavLink to="/services" onClick={navOnClick}>
            Services
          </NavLink>
        </li>
        <li>
          <NavLink to="/coverage" onClick={navOnClick}>
            Coverage
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" onClick={navOnClick}>
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
