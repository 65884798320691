import ky from 'ky-universal';

const REQUEST_EDIT_ITEM = 'REQUEST_EDIT_ITEM';
const SUCCESS_EDIT_ITEM = 'SUCCESS_EDIT_ITEM';
const FAIL_EDIT_ITEM = 'FAIL_EDIT_ITEM';
const RESET_EDIT_ITEM = 'RESET_EDIT_ITEM';

export const editItemReset = () => ({ type: RESET_EDIT_ITEM });

function requestEditItem() {
  return { type: REQUEST_EDIT_ITEM };
}

function successEditItem({ data }, skipSubmit) {
  return {
    data,
    skipSubmit,
    receivedAt: Date.now(),
    type: SUCCESS_EDIT_ITEM
  };
}

function failEditItem(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_EDIT_ITEM
  };
}

export function editItem(id, data, skipSubmit = false) {
  return dispatch => {
    dispatch(requestEditItem());
    return ky
      .put(`/api/items/${id}`, { json: data })
      .json()
      .then(
        res => dispatch(successEditItem(res, skipSubmit)),
        async error => dispatch(failEditItem(await error?.response?.json()))
      );
  };
}

const initialItemsState = {
  data: {},
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function editedItem(state = initialItemsState, action) {
  switch (action.type) {
    case REQUEST_EDIT_ITEM:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_EDIT_ITEM:
      return {
        ...state,
        data: action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: !action.skipSubmit
      };
    case FAIL_EDIT_ITEM:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_EDIT_ITEM:
      return initialItemsState;
    default:
      return state;
  }
}
