const initialState = {};

const SET_PREV_LOC = 'SET_PREV_LOC';
const RESET_PREV_LOC = 'RESET_PREV_LOC';

export const prevLoc = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREV_LOC:
      return action.location;
    case RESET_PREV_LOC:
      return initialState;
    default:
      return state;
  }
};

export const actions = {
  setPrevLoc(location) {
    return { type: SET_PREV_LOC, location };
  },
  resetPrevLoc() {
    return { type: RESET_PREV_LOC };
  }
};

export const setPrevLoc = actions.setPrevLoc;
