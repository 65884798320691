import React from 'react';
import { Link } from 'react-router-dom';

import Page from '../Page/Page';

import van1 from '../../../images/van1.jpeg';
import truck2 from '../../../images/truck2.jpeg';
import darkTruck from './images/darkTruck.jpg';
import coverageMap from './images/coverageMap.jpg';
import trucksAbove from './images/trucksAbove.jpg';

const coverageAreaContent = {
  title: (
    <div className="page-title-container">
      {/* the zero-width space (&#8203;) for some reason makes this work on Safari */}
      <h2 className="page-title" data-text="We’ll Go Where&#8203;">
        We’ll Go Where&#8203;
      </h2>
      <h2 className="page-title" data-text="You Need to Go&#8203;">
        You Need to Go&#8203;
      </h2>
    </div>
  ),
  deskTitle: 'Palm Beach County Experts',
  body: (
    <div className="page-main-body">
      <p>
        Top-Notch Delivery Service's immediate coverage area is the Palm Beach
        County area and up to Jupiter.
      </p>

      <p>
        <Link to="/contact"> Contact us</Link> today to see if we are in your
        area!
      </p>
    </div>
  )
};

function CoverageArea() {
  return (
    <Page
      topImg={darkTruck}
      bottomImg={trucksAbove}
      deskImg1={truck2}
      deskImg2={van1}
      text={{
        title: coverageAreaContent.title,
        body: coverageAreaContent.body,
        deskTitle: coverageAreaContent.deskTitle
      }}
    />
  );
}

export default CoverageArea;
