import queryString from 'querystring';
import pickBy from 'lodash.pickby';
import get from 'lodash/get';
import set from 'lodash.set';

export function updateSearch(
  newTerms,
  _oldQuery,
  history,
  location,
  isTextSearch,
  noDupes
) {
  const oldQuery = queryString.parse(_oldQuery);

  let search;
  let _search;
  if (isTextSearch) {
    _search = queryString.stringify(newTerms);
  } else {
    newTerms.textSearch = false;
    if (
      noDupes &&
      get(oldQuery, noDupes) &&
      get(queryString.parse(newTerms), noDupes)
    ) {
      set(oldQuery, noDupes, undefined);
    }
    _search = queryString.stringify(
      pickBy(Object.assign(oldQuery, newTerms), Boolean)
    );
  }

  search = _search.replace('%3F', '').replace('?', '');
  history.push({ ...location, search });
}

export function replaceSearch(newTerms, history, location) {
  const search = queryString.stringify(newTerms);
  history.push({ ...location, search });
}

export function changeSort(_field, _oldQuery, history, location) {
  let field = _field;
  const oldQuery = queryString.parse(_oldQuery);

  if (!field) return;
  if (oldQuery.order === field) {
    field = field.startsWith('-') ? field.slice(1) : `-${field}`;
  }

  updateSearch({ order: field }, _oldQuery, history, location);
}

export function textSearchSubmit(text, _oldQuery, history, location) {
  updateSearch({ text }, _oldQuery, history, location, true);
}

export function clearSearch(history, location) {
  const search = null;
  history.push({ ...location, search });
}

export function clearOneFilter(history, key) {
  const location = window.location;
  const search = new URLSearchParams(location.search);
  search.delete(key);
  history.push({ ...location, search: search.toString() });
}
