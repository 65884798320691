import ky from 'ky-universal';

const REQUEST_CREATE_STATEMENT = 'REQUEST_CREATE_STATEMENT';
const SUCCESS_CREATE_STATEMENT = 'SUCCESS_CREATE_STATEMENT';
const FAIL_CREATE_STATEMENT = 'FAIL_CREATE_STATEMENT';
const RESET_CREATE_STATEMENT = 'RESET_CREATE_STATEMENT';

export const createStatementReset = () => ({ type: RESET_CREATE_STATEMENT });

function requestCreateStatement() {
  return { type: REQUEST_CREATE_STATEMENT };
}

function successCreateStatement({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_CREATE_STATEMENT
  };
}

function failCreateStatement(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_CREATE_STATEMENT
  };
}

export function createStatement(data) {
  return dispatch => {
    dispatch(requestCreateStatement());
    return ky
      .post('/api/statements', { json: data })
      .json()
      .then(
        res => dispatch(successCreateStatement(res)),
        async error =>
          dispatch(failCreateStatement(await error?.response?.json()))
      );
  };
}

const initialStatementsState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function createdStatement(state = initialStatementsState, action) {
  switch (action.type) {
    case REQUEST_CREATE_STATEMENT:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_CREATE_STATEMENT:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: true
      };
    case FAIL_CREATE_STATEMENT:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_CREATE_STATEMENT:
      return initialStatementsState;
    default:
      return state;
  }
}
