import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router';

import Dropdown from '../Dropdown/Dropdown';
import { Autocomplete } from '../../core/Forms';

import close from '../../../images/close.svg';

import './DropNType.scss';

function DropNType({ filterInfo, search }) {
  const {
    plain,
    type,
    items,
    label,
    displayKey,
    dataKey,
    filterFn,
    getDataFn,
    fullPath,
    clearOneFilter
  } = filterInfo;

  const dropdown = useRef(null);
  const autocomplete = useRef(null);

  const [selectedValue, setSelectedValue] = useState('');

  const location = useLocation();

  useEffect(() => {
    if (selectedValue) return;

    const query = new URLSearchParams(location.search);
    const searchVal = query.get(`where[${fullPath}]`);

    if (plain && searchVal) {
      setSelectedValue(searchVal);
    } else if (items?.length && searchVal) {
      // noinspection EqualityComparisonWithCoercionJS
      setSelectedValue(items.find(x => x[dataKey] == searchVal));
    }
  }, [location.search, items]);

  function handleSelect(selected) {
    setSelectedValue(selected);

    if (selected?.[dataKey]) {
      filterFn({ [`where[${fullPath}]`]: selected[dataKey] }, 'where.jobId');
      dropdown?.current?.toggle();
    } else if (selected && !dataKey) {
      filterFn({ [`where[${fullPath}]`]: selected }, 'where.jobId');
    }

    if (!selected) {
      clearOneFilter(fullPath);
    }
  }

  function clearValueOnClear(changes, force) {
    if (changes?.inputValue === '' || force) {
      setSelectedValue('');
      autocomplete?.current?.reset();
      autocomplete?.current?.clear();
      clearOneFilter(fullPath);
    }
  }

  useEffect(() => {
    if (search && !search.where && !search?.where?.hasOwnProperty(fullPath)) {
      autocomplete?.current?.reset();
      autocomplete?.current?.clear();
      dropdown?.current?.close();
    }
  }, [search?.where]);

  return (
    <Dropdown
      arrow
      noClose
      filterBar
      outsideText={
        <>
          <span className="label">{label}</span>
          {selectedValue && (
            <>
              <span className="selected">
                {selectedValue?.[displayKey] || selectedValue}
              </span>
              <img
                alt="x"
                src={close}
                onClick={() => clearValueOnClear(null, true)}
                className="selected-clear"
              />
            </>
          )}
        </>
      }
      extraClass="drop-n-type"
      ref={dropdown}
    >
      {plain ? (
        <input
          type={type || 'text'}
          className="field"
          onChange={e => handleSelect(e.target.value)}
        />
      ) : (
        <Autocomplete
          onSelect={handleSelect}
          onChange={clearValueOnClear}
          searchFn={getDataFn}
          data={items}
          dataKey={displayKey}
          ref={autocomplete}
        />
      )}
    </Dropdown>
  );
}

export default DropNType;
