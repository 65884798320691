import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Field, Form, SelectGroup } from '../../core/Forms';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { v3 as uuidv3 } from 'uuid';

import classNames from 'classnames';
import * as yup from 'yup';

import TNButton from '../../Site/TNButton/TNButton';
import Modal from '../../Site/PageWithModal/Modal/Modal';
import ImageUploader from '../../core/ImageUploader/ImageUploader';

import { uuidNs } from '../../config';
import { editUser, editUserReset } from '../../../store/lib/users/editUser';

import './AppSettings.scss';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  name: yup.string().required('Name required'),
  email: yup
    .string()
    .email('Email must be valid')
    .required('A valid email is required.'),
  phone: yup.string().matches(phoneRegExp, 'Please enter valid phone'),
  memberships: yup.number().required,
  photoUrl: yup.string()
});

const mapStateToProps = ({
  user: { data: userData },
  editedUser: { data: editedUser }
}) => ({
  userData,
  editedUser
});

function getUuidV3(user) {
  return uuidv3(user.id.toString(), uuidNs.userImg);
}

export default connect(mapStateToProps)(function AppSettings({
  dispatch,
  userData,
  editedUser
}) {
  const {
    name,
    email,
    phone,
    memberships,
    photoUrl,
    id,
    website,
    companyName
  } = userData;

  const [uploadImgModal, setUploadImgModal] = useState(false);

  //stuff for add and edit forms
  function handleFormSubmit(values) {
    const formatValues = { ...values };
    delete formatValues.memberships;

    if (userImg) {
      formatValues.photoUrl = userImg;
    }

    return dispatch(editUser(id, formatValues));
  }

  const [userImg, setUserImg] = useState(photoUrl || '');

  function handleSaveImg(imgFiles) {
    const photoUrl = imgFiles?.[0]?.meta?.fileUrl;
    setUserImg(`${photoUrl}?ts=${Date.now()}`);
    dispatch(editUser(userData.id, { photoUrl }, true));
  }

  useEffect(() => {
    if (editedUser.submitted) {
      //success message
      editedUser.submitted && toast.success('User edited successfully');
      dispatch(editUserReset());
    }
  }, [editedUser.submitted]);

  return (
    <div className="settings-page">
      <h1> User Settings</h1>
      <h4>You may edit your user settings here.</h4>
      <div
        onClick={() => setUploadImgModal(true)}
        style={userImg ? { backgroundImage: `url('${userImg}')` } : {}}
        className={classNames('main-img', { placeholder: !userImg })}
      />
      {uploadImgModal && (
        <Modal
          title="Upload Image"
          toClose={() => setUploadImgModal(false)}
          className="img-upload-modal"
        >
          <ImageUploader
            imgUuid={getUuidV3(userData)}
            signingUrl="/api/s3-uploads/users/me"
            onSubmit={files => {
              handleSaveImg(files);
              setUploadImgModal(false);
            }}
          />
        </Modal>
      )}

      <Formik
        validationSchema={schema}
        initialValues={{
          name: name,
          email: email,
          phone: phone || '',
          memberships: memberships || 8,
          photoUrl: photoUrl || '',
          companyName: companyName || '',
          website: website || ''
        }}
        onSubmit={values => {
          handleFormSubmit(values);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="name" label="Name*" />
            <Field name="email" type="email" label="Email*" />
            <SelectGroup
              disabled
              name="memberships"
              label="Choose an Access Level*"
              options={[
                [8, 'Employee'],
                [16, 'Client'],
                [2, 'Admin'],
                [4, 'Billing'],
                [6, 'Billing Admin']
              ]}
            />
            <Field name="phone" type="phone" label="Phone" />
            <Field name="companyName" label="Company Name" />
            <Field name="website" label="Website" />

            <TNButton border type="submit" disabled={isSubmitting}>
              Submit
            </TNButton>
          </Form>
        )}
      </Formik>
    </div>
  );
});
