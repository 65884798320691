import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import TNButton from '../../TNButton/TNButton';
import PasswordField from './PasswordField';
import useErrorToast from '../../../hooks/useErrorToast';
import { Form } from '../../../core/Forms';
import { passResetIfNeeded } from '../../../../store/lib/user/passwordReset';

const forgotPassSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});

export default function ResetPassword({ location }) {
  const dispatch = useDispatch();
  const passReset = useSelector((s) => s.passReset);

  useErrorToast(passReset.error);

  const isActivate = /^\/login\/activate/.test(location.pathname);

  return (
    <div className="switchable">
      {!passReset.submitted ? (
        <>
          {isActivate && <p>Welcome!</p>}
          <p>
            {isActivate
              ? 'Please create a password to begin using your account.'
              : 'Please enter a new password.'}
          </p>
          <Formik
            initialValues={{ password: '', passwordConfirmation: '' }}
            validationSchema={forgotPassSchema}
            onSubmit={async (values, { setSubmitting }) =>
              dispatch(passResetIfNeeded(values, location.search)).finally(() =>
                setSubmitting(false)
              )
            }
          >
            {({ isSubmitting }) => (
              <Form>
                <PasswordField />
                <PasswordField
                  name="passwordConfirmation"
                  label="Confirm Password"
                />
                <TNButton
                  border
                  highlight
                  type="submit"
                  extraClass="span-all"
                  disabled={isSubmitting}
                >
                  Submit
                </TNButton>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <p>
            {isActivate
              ? 'Your password has been set successfully.'
              : 'Your password has been reset successfully.'}
          </p>
          <br />
          <Link replace to="/login">
            <TNButton border>Please Return to Login</TNButton>
          </Link>
        </>
      )}
    </div>
  );
}
