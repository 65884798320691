import ky from 'ky-universal';
import { formatDataDate, formatDate, getStatus } from '../etc/formatFns';

const REQUEST_GET_ITEMS = 'REQUEST_GET_ITEMS';
const SUCCESS_GET_ITEMS = 'SUCCESS_GET_ITEMS';
const FAIL_GET_ITEMS = 'FAIL_GET_ITEMS';
const SUCCESS_DELETE_ITEM = 'SUCCESS_DELETE_ITEM';
const SUCCESS_CREATE_ITEM = 'SUCCESS_CREATE_ITEM';
const SUCCESS_EDIT_ITEM = 'SUCCESS_EDIT_ITEM';

function requestGetItems() {
  return { type: REQUEST_GET_ITEMS };
}

function successGetItems({ data, page }, next) {
  return {
    data,
    page,
    wasPaging: !!next,
    receivedAt: Date.now(),
    type: SUCCESS_GET_ITEMS
  };
}

function failGetItems(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_ITEMS
  };
}

export function tryGetItems(next, search) {
  return dispatch => {
    dispatch(requestGetItems());

    return ky
      .get(next || `/api/items${search}`)
      .json()
      .then(
        res => dispatch(successGetItems(res, next)),
        async error => dispatch(failGetItems(await error?.response?.json()))
      );
  };
}

function shouldGetItems({ items }) {
  if (items.isFetching) {
    return false;
  } else if (!items.data?.length) {
    return true;
  } else {
    return items.lastUpdated < Date.now() - 50;
  }
}

export function getItems({ next, search = null } = {}) {
  return (dispatch, getState) => {
    if (shouldGetItems(getState())) {
      return dispatch(tryGetItems(next, search));
    }
  };
}

const initialItemsState = {
  data: [],
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

function formatItem(item) {
  item.createdAtFormat = formatDate(
    item.createdAt ? item.createdAt : Date.now()
  );
  item.dateReceivedFormat =
    item.dateReceived && formatDataDate(item.dateReceived);
  item.dateDeliveredFormat =
    item.dateDelivered && formatDataDate(item.dateDelivered);
  item.statusText = getStatus(item.status);
  return item;
}

export function items(state = initialItemsState, action) {
  switch (action.type) {
    case REQUEST_GET_ITEMS:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_ITEMS:
      action.data.forEach(i => {
        formatItem(i);
      });
      return {
        ...state,
        data: action.wasPaging ? [...state.data, ...action.data] : action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_ITEMS:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case SUCCESS_DELETE_ITEM:
      return {
        ...state,
        data: state.data?.filter(item => item.id !== action.id),
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        deleted: true
      };

    case SUCCESS_CREATE_ITEM:
      const nextData = [formatItem({ ...action.data }), ...state.data];

      return {
        ...state,
        data: nextData,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case SUCCESS_EDIT_ITEM:
      const data = formatItem({ ...action.data });
      const editedData = [...state.data];
      const editedIdx = editedData.findIndex(item => item.id === data.id);
      editedData[editedIdx] = data;

      return {
        ...state,
        data: editedData,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
}
