import ky from 'ky-universal';

const REQUEST_POST_CREATE_RESET_EMAIL = 'REQUEST_POST_CREATE_RESET_EMAIL';
const PASS_POST_CREATE_RESET_EMAIL = 'PASS_POST_CREATE_RESET_EMAIL';
const FAIL_POST_CREATE_RESET_EMAIL = 'FAIL_POST_CREATE_RESET_EMAIL';

function requestCreateResetEmail() {
  return { type: REQUEST_POST_CREATE_RESET_EMAIL };
}

function passCreateResetEmail(res) {
  return {
    type: PASS_POST_CREATE_RESET_EMAIL,
    user: res.data,
    receivedAt: Date.now()
  };
}

function failCreateResetEmail(error) {
  return {
    error,
    type: FAIL_POST_CREATE_RESET_EMAIL,
    receivedAt: Date.now()
  };
}

function tryCreateResetEmail(data) {
  return dispatch => {
    dispatch(requestCreateResetEmail());

    return ky
      .post(`/api/users/send-reset-email`, { json: data })
      .json()
      .then(
        res => dispatch(passCreateResetEmail(res)),
        async error =>
          dispatch(failCreateResetEmail(await error?.response?.json?.()))
      );
  };
}

function shouldCreateResetEmail({ resetEmail }) {
  if (resetEmail.isFetching) {
    return false;
  } else if (!resetEmail.data) {
    return true;
  } else {
    return resetEmail.lastUpdated < Date.now() - 50;
  }
}

export function createResetEmailIfNeeded(data) {
  return (dispatch, getState) => {
    if (shouldCreateResetEmail(getState())) {
      return dispatch(tryCreateResetEmail(data));
    }
  };
}

const initialUserState = {
  data: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function resetEmail(state = initialUserState, action) {
  switch (action.type) {
    case REQUEST_POST_CREATE_RESET_EMAIL:
      return { ...state, isFetching: true, error: null };
    case PASS_POST_CREATE_RESET_EMAIL:
      return {
        ...state,
        data: action.user,
        isFetching: false,
        error: null,
        submitted: true,
        lastUpdated: action.receivedAt
      };
    case FAIL_POST_CREATE_RESET_EMAIL:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
