import ky from 'ky-universal';

const REQUEST_CREATE_PAYMENT = 'REQUEST_CREATE_PAYMENT';
const SUCCESS_CREATE_PAYMENT = 'SUCCESS_CREATE_PAYMENT';
const FAIL_CREATE_PAYMENT = 'FAIL_CREATE_PAYMENT';
const RESET_CREATE_PAYMENT = 'RESET_CREATE_PAYMENT';

export const createPaymentReset = () => ({ type: RESET_CREATE_PAYMENT });

function requestCreatePayment() {
  return { type: REQUEST_CREATE_PAYMENT };
}

function successCreatePayment({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_CREATE_PAYMENT
  };
}

function failCreatePayment(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_CREATE_PAYMENT
  };
}

export function createPayment(data) {
  return dispatch => {
    dispatch(requestCreatePayment());
    return ky
      .post('/api/payments', { json: data })
      .json()
      .then(
        res => dispatch(successCreatePayment(res)),
        async error =>
          dispatch(failCreatePayment(await error?.response?.json()))
      );
  };
}

const initialPaymentsState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function createdPayment(state = initialPaymentsState, action) {
  switch (action.type) {
    case REQUEST_CREATE_PAYMENT:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_CREATE_PAYMENT:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: true
      };
    case FAIL_CREATE_PAYMENT:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_CREATE_PAYMENT:
      return initialPaymentsState;
    default:
      return state;
  }
}
