import ky from 'ky-universal';
import { format, addDays, differenceInCalendarDays } from 'date-fns';

import { formatDate, getStatus } from '../etc/formatFns';

const REQUEST_GET_INVOICE = 'REQUEST_GET_INVOICE';
const SUCCESS_GET_INVOICE = 'SUCCESS_GET_INVOICE';
const FAIL_GET_INVOICE = 'FAIL_GET_INVOICE';
const RESET_GET_INVOICE = 'RESET_GET_INVOICE';

const today = Date.now();

export const getInvoiceReset = () => ({ type: RESET_GET_INVOICE });

function requestGetInvoice() {
  return { type: REQUEST_GET_INVOICE };
}

function successGetInvoice({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_GET_INVOICE
  };
}

function failGetInvoice(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_INVOICE
  };
}

export function getInvoice(id) {
  return dispatch => {
    dispatch(requestGetInvoice());
    return ky
      .get(`/api/invoices/${id}`)
      .json()
      .then(
        res => {
          return dispatch(successGetInvoice(res));
        },
        async error => {
          return dispatch(failGetInvoice(await error?.response?.json()));
        }
      );
  };
}

const initialInvoiceState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function invoice(state = initialInvoiceState, action) {
  switch (action.type) {
    case REQUEST_GET_INVOICE:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_INVOICE:
      const d = action.data;

      const parsedDate = new Date(d.date);

      d.date = format(parsedDate, 'yyyy-MM-dd');
      d.sentDate = d.sentDate && format(new Date(d.sentDate), 'MM/dd/yyyy');
      d.updatedAt = d.updatedAt && formatDate(d.updatedAt);
      d.createdAt = d.createdAt && formatDate(d.createdAt);
      d.statusText = getStatus(d.status, 'invoice');
      d.dateDue = format(addDays(parsedDate, d.dueIn), 'MM/dd/yyyy');
      d.daysLate = Math.max(
        differenceInCalendarDays(today, new Date(d.dateDue)),
        0
      );
      d.billingItems = d.billingItems?.length
        ? d.billingItems.map(bi => {
            if (!bi.rate) {
              bi.rate = bi.template.default_rate;
            }
            if (!bi.quantity) {
              bi.quantity = bi.template.default_quantity;
            }
            if (!bi.description) {
              bi.description = bi.template.default_description;
            }
            return bi;
          })
        : d.billingItems;

      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_INVOICE:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_GET_INVOICE:
      return initialInvoiceState;
    default:
      return state;
  }
}
