import React from 'react';
import { Link } from 'react-router-dom';

import hat from '../../../images/topNotchJustHat@2x.png';
import logo from '../../../images/topnotchdeliveryservice.png';

import './Header.scss';

function Header() {
  return (
    <Link to="/" className="header">
      <img src={hat} alt="tipped top hat" className="hat" />
      <img src={logo} className="logo" alt="logo" />
      <h1 className="company-name">Top-Notch Delivery Service</h1>
    </Link>
  );
}

export default Header;
