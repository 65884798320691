import React, { useState } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

import ImageUploader from '../../core/ImageUploader/ImageUploader';
import Modal from '../../Site/PageWithModal/Modal/Modal';
import Dropdown from '../Dropdown/Dropdown';
import TNButton from '../../Site/TNButton/TNButton';

import './EditPane.scss';

import close from '../../../images/close.svg';

function EditPane({
  disabled,
  noImgUploads,
  selectedRow,
  closePane,
  addItem,
  headerKeys,
  column1Keys,
  column2Keys,
  formValues,
  children,
  extraContent,
  actionButtons
}) {
  const {
    title,
    subtitle,
    subtitleLink,
    img,
    signingUrl,
    imgUuid,
    imgMultiple,
    imgModalSubmit
  } = headerKeys;
  const [uploadImgModal, setUploadImgModal] = useState(false);

  return (
    <div className="edit-pane">
      {extraContent && (
        <Dropdown
          extraClass="edit-pane-dots"
          outsideText={
            <div className="dots">
              <div />
              <div />
              <div />
            </div>
          }
        >
          {extraContent}
        </Dropdown>
      )}
      <img
        src={close}
        alt="x for closing"
        className="close"
        onClick={closePane}
      />
      <div className="summary">
        <div
          onClick={
            !noImgUploads && !disabled ? () => setUploadImgModal(true) : null
          }
          style={img ? { backgroundImage: `url('${img}')` } : {}}
          className={classNames('main-img', {
            placeholder: !img,
            noCursor: noImgUploads
          })}
        />
        {uploadImgModal && (
          <Modal
            title="Upload Image"
            toClose={() => setUploadImgModal(false)}
            className="img-upload-modal"
          >
            <ImageUploader
              imgUuid={imgUuid}
              multiple={imgMultiple}
              signingUrl={signingUrl}
              onSubmit={files => {
                imgModalSubmit(files);
                setUploadImgModal(false);
              }}
            />
          </Modal>
        )}
        <h1 className="title">
          {formValues?.[title] ||
            (selectedRow && get(selectedRow, title)) ||
            `Add New ${addItem}`}
        </h1>
        <a href={subtitleLink}>
          <h5>
            {formValues?.[subtitle] ||
              (selectedRow && get(selectedRow, subtitle)) ||
              '- - -'}
          </h5>
        </a>
        <div className="display-info">
          <div className="column left">
            <ul>
              {column1Keys?.map(field => (
                <li key={field.key}>
                  <span className="display-field">{field.display}</span>
                  <span className="data">
                    {formValues?.[field.key] ||
                      get(selectedRow, field.key) ||
                      '-'}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <div className="column right">
            <ul>
              {column2Keys?.map(field => (
                <li key={field.key}>
                  <span className="display-field">{field.display}</span>
                  <span className="data">
                    {formValues?.[field.key] ||
                      get(selectedRow, field.key) ||
                      '-'}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {actionButtons && (
          <div className="action-buttons">
            {actionButtons.map((button, idx) => (
              <TNButton
                border
                highlight={idx === 0}
                key={`actionbutton-${idx}`}
                onClick={button.action}
                disabled={button.disabled}
              >
                {button.display}
              </TNButton>
            ))}
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

export default EditPane;

//TODO Add photo display
//TODO add warning if leave and changes have been made
