import React from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField } from 'formik';

import FieldInner from './shared/FieldInner';

SelectGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  validate: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired
};

SelectGroup.defaultProps = {
  helperText: '',
  placeholder: '',
  disabled: false,
  className: '',
  validate: undefined
};

export default function SelectGroup({
  name,
  validate,
  placeholder,
  options,
  ...props
}) {
  return (
    <FormikField name={name} validate={validate}>
      {fieldProps => (
        <FieldInner {...props} {...fieldProps} className="select-field">
          <select
            className="field"
            {...fieldProps.field}
            disabled={props.disabled}
          >
            <option value="" disabled hidden>
              {placeholder || 'Pick one…'}
            </option>
            {options.map(([value, label], idx) => (
              <option key={`${value}-${idx}`} value={value}>
                {label}
              </option>
            ))}
          </select>
        </FieldInner>
      )}
    </FormikField>
  );
}
