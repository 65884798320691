import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'qs';

import { connect } from 'react-redux';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Route, Switch } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as yup from 'yup';

import PageTabs from '../PageTabs/PageTabs';
import IMSTopBar from '../IMSTopBar/IMSTopBar';
import EditPane from '../EditPane/EditPane';
import CardsList from '../CardsList/CardsList';
import DeleteModal from '../DeleteModal/DeleteModal';
import PhotosModal from './PhotosModal';
import TNButton from '../../Site/TNButton/TNButton';
import ItemForm from '../Items/AddEditItem';

import { getItems, tryGetItems } from '../../../store/lib/items/items';
import { getUsers } from '../../../store/lib/users/users';
import { getItem, getItemReset } from '../../../store/lib/items/item';
import {
  createItem,
  createItemReset
} from '../../../store/lib/items/createItem';
import { editItem, editItemReset } from '../../../store/lib/items/editItem';
import { getItemsCount } from '../../../store/lib/items/itemsCount';
import {
  deleteItem,
  deleteItemReset
} from '../../../store/lib/items/deleteItem';

import { getJobs } from '../../../store/lib/jobs/jobs';
import { getCarriers } from '../../../store/lib/carriers/carriers';
import { getRooms } from '../../../store/lib/rooms/rooms';
import { getManufacturers } from '../../../store/lib/manufacturers/manufacturers';

import {
  changeSort,
  textSearchSubmit,
  updateSearch,
  clearOneFilter
} from '../../../store/lib/etc/searchFns';

import useErrorToast from '../../hooks/useErrorToast';

import { v3 as uuidv3 } from 'uuid';
import { uuidNs } from '../../config';

import statuses from './config/statuses';
import { setPrevLoc } from '../../../store/lib/prevLoc';

import { groups } from '../../../api/db/config/access';

function getUuidV3(selectedRow) {
  return uuidv3(selectedRow.id.toString(), uuidNs.itemTemplatesImg);
}

function getSigningUrl(id) {
  return `/api/s3-uploads/items${id ? `/${id}` : ''}`;
}

import './styles/Items.scss';

const mapStateToProps = data => ({
  item: data.item.data,
  items: data.items.data,
  createdItem: data.createdItem,
  editedItem: data.editedItem,
  deletedItem: data.deletedItem,
  itemsCount: data.itemsCount.data,

  pagination: data.items.page,
  fetching: data.items.isFetching,

  jobs: data.jobs.data,
  users: data.users.data,
  carriers: data.carriers.data,

  itemError: data.job.error,
  itemsError: data.jobs.error,
  createdItemError: data.createdItem.error,
  editedItemError: data.editedItem.error,
  deletedItemError: data.deletedItem.error,
  itemsCountError: data.itemsCount.error,

  jobsError: data.jobs.error,

  lastPath: data.prevLoc?.pathname
});

function cardFields(isColumn) {
  return {
    title: 'name',
    subtitle: 'job.name',
    photoUrl: 'photoUrl',
    details: [
      {
        display: 'Customer',
        dataKey: isColumn ? 'user.id' : 'user.detail',
        sortKey: 'ownerName',
        extraDataKey: 'user.name'
      },
      { display: 'Job', dataKey: isColumn ? 'jobName' : 'job.name' },
      { display: 'Manufacturer', dataKey: 'manufacturer.name' },
      { display: 'Quantity', dataKey: 'quantity' },
      {
        display: 'Carrier',
        dataKey: isColumn ? 'carrierId' : 'carrier.name',
        sortKey: 'carrier'
      },
      {
        display: 'Date Rec',
        dataKey: 'dateReceivedFormat',
        sortKey: 'dateReceived'
      },
      {
        display: 'Date Del',
        dataKey: 'dateDeliveredFormat',
        sortKey: 'dateDelivered'
      }
    ]
  };
}

const cardFieldsMain = cardFields();
const cardFieldsNav = cardFields(true);

const schema = yup.object().shape({
  quantity: yup
    .number()
    .min(0, 'Must be 0 or greater')
    .required('Quantity required'),
  billedForReceiving: yup.boolean(),
  dateReceived: yup.date(),
  dateDelivered: yup.date(),
  declaredValue: yup.number().min(0, 'Must be 0 or greater'),
  customerPo: '',
  comments: yup.string(),
  hasConcerns: yup.boolean(),
  carrier: yup.string(),
  carrierId: yup.number(),
  room: yup.string(),
  roomId: yup.number(),

  user: yup.string(),
  owner: yup.number(),

  job: yup.string(),
  jobId: yup.number(),

  name: yup.string().required('Item must be assigned a name'),
  type: yup.string(),
  manufacturer: yup.string(),
  manufacturerId: yup.number()
});

export default connect(mapStateToProps)(function Items({
  toggleMobileNav,
  dispatch,
  pagination,
  lastPath,
  location,
  history,

  item,
  items,
  createdItem,
  editedItem,
  deletedItem,
  itemsCount,

  jobs,
  users,
  carriers,

  itemError,
  itemsError,
  createdItemError,
  editedItemError,
  deletedItemError,
  itemsCountError,

  jobsError
}) {
  useErrorToast(itemError);
  useErrorToast(itemsError);
  useErrorToast(createdItemError);
  useErrorToast(editedItemError);
  useErrorToast(deletedItemError);
  useErrorToast(itemsCountError);

  useErrorToast(jobsError);

  //stuff for handling the main getting of data and errors
  useEffect(() => {
    const regex = /items\/?(.*)?/;
    const [, id] = location.pathname.match(regex) || [];

    id && id !== 'new' ? dispatch(getItem(id)) : resetStateItems();

    if (!regex.test(lastPath)) {
      dispatch(getItems({ search: location.search }));
    }
  }, [location]);

  useEffect(() => {
    dispatch(getItemsCount({ searchParams: location.search }));
  }, [location?.search]);

  const scrollSection = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  function handleScroll() {
    const scrollTop = scrollSection?.current?.scrollTop;
    if (!isScrolled && scrollTop > 100) {
      setIsScrolled(true);
    } else if (isScrolled && scrollTop < 50) {
      setIsScrolled(false);
    }
  }

  function pushPage(id, replace = false) {
    dispatch(setPrevLoc(location));
    const search = location ? location?.search : '';
    history[replace ? 'replace' : 'push']({
      pathname: `/ims/items${id ? `/${id}` : ``}`,
      location: `/ims/items${id ? `/${id}${search}` : `${search}`}`,
      search: search
    });
  }

  useEffect(() => {
    scrollSection?.current?.addEventListener('scroll', handleScroll);
    return () =>
      scrollSection?.current?.removeEventListener('scroll', handleScroll);
  });

  function onScroll() {
    dispatch(getItems({ next: pagination?.next?.url }));
  }

  //stuff for controlling add and edit panel
  const [selectedRow, setSelectedRow] = useState(null);
  const [addNew, setAddNew] = useState(null);
  const [itemTempImg, setItemTempImg] = useState(item?.photoUrl || undefined);

  const editPaneOpen = addNew || selectedRow;
  const {
    quantity,
    billedForReceiving,
    dateReceived,
    dateDelivered,
    declaredValue,
    customerPo,
    comments,
    hasConcerns,
    onTruck,
    carrier,
    carrierId,
    room,
    roomId,
    manufacturer,
    manufacturerId,
    type,
    user,
    owner,
    job,
    jobId,
    name
  } = item ? item : {};

  const isInspected = (item.status & statuses.inspected) !== 0;

  const [listView, setListView] = useState(true);
  const [photos, setPhotos] = useState(selectedRow?.photos || []);
  const [photosModalOpen, setPhotosModalOpen] = useState(false);

  function resetStateItems() {
    setSelectedRow(null);
    setItemTempImg(undefined);
    setPhotos([]);
  }

  function resetAndCloseEditPane() {
    setAddNew(null);
    resetStateItems();
    dispatch(getItemReset());
    pushPage();
  }

  useEffect(() => {
    if (item?.id && item.id !== selectedRow?.id) {
      setItemTempImg(item.photoUrl);
      setPhotos(item.photos);
      setSelectedRow(item);
    }
  }, [item]);

  function onRowClick(selected) {
    if (selectedRow?.id === selected?.id) {
      resetStateItems();
      pushPage();
    } else {
      if (addNew) {
        setAddNew(null);
        pushPage();
        setTimeout(() => {
          pushPage(selected.id, true);
        }, 500);
      } else if (selectedRow) {
        setSelectedRow(null);
        pushPage();
        setTimeout(() => {
          pushPage(selected.id, true);
        }, 500);
      } else {
        pushPage(selected.id);
      }
    }
  }

  function handleAddNewClick() {
    if (selectedRow) {
      resetAndCloseEditPane();
      pushPage();
      setTimeout(() => {
        setAddNew(true);
        pushPage('new', true);
      }, 500);
    } else {
      setAddNew(true);
      pushPage('new');
    }
  }

  function handleSaveImg(imgFiles) {
    const photoUrl = imgFiles?.[0].meta.fileUrl;
    const newPhotos = [...photos, { url: photoUrl }];

    setItemTempImg(`${photoUrl}?ts=${Date.now()}`);
    setPhotos(newPhotos);

    if (selectedRow?.id) {
      dispatch(editItem(selectedRow.id, { photoUrl, photos: newPhotos }, true));
    }
  }

  //stuff for add and edit forms

  function handleFormSubmit(values) {
    const formatValues = { ...values };

    if (values.carrierId) {
      delete formatValues.carrier;
    } else if (values.carrier) {
      formatValues.carrier = { name: values.carrier };
    }

    if (values.roomId) {
      delete formatValues.room;
    } else if (values.room) {
      formatValues.room = { name: values.room };
    }

    if (values.manufacturerId) {
      delete formatValues.manufacturer;
    } else if (values.manufacturer) {
      formatValues.manufacturer = { name: values.manufacturer };
    }

    if (values.jobId) {
      delete formatValues.job;
    } else if (values.job) {
      formatValues.job = { name: values.job };
    }

    delete formatValues.user;

    formatValues.photoUrl = itemTempImg;

    if (selectedRow) {
      return dispatch(editItem(selectedRow.id, formatValues));
    } else {
      return dispatch(createItem(formatValues));
    }
  }

  function handleStatusChange(newStatus) {
    dispatch(editItem(selectedRow.id, { status: newStatus }));
  }

  useEffect(() => {
    //success message
    createdItem?.submitted && toast.success('Item created successfully');
    editedItem?.submitted && toast.success('Item edited successfully');

    if (createdItem?.submitted || editedItem?.submitted) {
      resetAndCloseEditPane();
    }
  }, [createdItem, editedItem]);

  useEffect(() => {
    //reset createItem object after pane closes on submit
    if (!addNew) {
      dispatch(createItemReset());
    }
    if (editedItem.submitted && !selectedRow) {
      dispatch(editItemReset());
    }
  }, [addNew, selectedRow]);

  //stuff for delete
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  function requestDelete(selected) {
    setDeleteConfirm(selected.id);
  }

  useEffect(() => {
    if (deletedItem.deleted || deletedItemError) {
      setDeleteConfirm(null);
      dispatch(deleteItemReset());
      resetAndCloseEditPane();
      deletedItem?.deleted && toast.success('Item deleted successfully');
    }
  }, [deletedItem?.deleted, deletedItemError]);

  //stuff for search, sort, filters, queries

  const _oldQuery = location.search.replace('%3F', '').replace('?', '');

  useEffect(() => {
    dispatch(getItems({ search: location.search }));
  }, [location.search]);

  function sortFn(newQuery) {
    changeSort(newQuery, _oldQuery, history, location);
  }

  function searchFn(text) {
    textSearchSubmit(text, _oldQuery, history, location);
  }

  function filterFn(newQuery, noDupes) {
    updateSearch(newQuery, _oldQuery, history, location, false, noDupes);
  }

  function autocompleteJobs(text) {
    dispatch(
      getJobs({
        search: '?' + queryString.stringify({ ac_key: 'name', ac_val: text })
      })
    );
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search);

    const jobId = search.get('where[jobId]');
    if (jobId) {
      dispatch(
        getJobs({ search: '?' + queryString.stringify({ 'where[id]': jobId }) })
      );
    }

    const userId = search.get('where[owner]');
    if (userId) {
      dispatch(
        getUsers({
          search: '?' + queryString.stringify({ 'where[id]': userId })
        })
      );
    }

    const carrierId = search.get('where[carrierId]');
    if (carrierId) {
      dispatch(
        getCarriers({
          search: '?' + queryString.stringify({ 'where[id]': carrierId })
        })
      );
    }
  }, []);

  function autocompleteSearch(modelName, ac_key, ac_val) {
    const params = { search: '?' + queryString.stringify({ ac_key, ac_val }) };

    switch (modelName) {
      case 'users':
        dispatch(getUsers(params));
        break;
      case 'carriers':
        dispatch(getCarriers(params));
        break;
      case 'rooms':
        dispatch(getRooms(params));
        break;
      case 'manufacturers':
        dispatch(getManufacturers(params));
        break;
      case 'jobs':
        dispatch(getJobs(params));
        break;
      default:
        return;
    }
  }

  function clearFilter(key) {
    clearOneFilter(history, `where[${key}]`);
  }

  async function printFn(next) {
    if (next) {
      const result = await dispatch(tryGetItems(next));
      return printFn(result?.page?.next?.url).catch(console.error);
    }

    window.print();
  }

  return (
    <div className="items ims-page">
      <span>
        <PageTabs
          title="Items"
          addItem="Item"
          tableKey="items"
          className="items-page"
          search={location?.search}
          history={history}
          condense={editPaneOpen}
          setAddNew={handleAddNewClick}
          pathKey="status"
          tabs={[
            {
              name: 'All Items',
              extra: itemsCount?.all || 0
            },
            {
              name: 'To Pick',
              extra: itemsCount?.pick || 0,
              filter: statuses.pick
            },
            {
              name: 'In Storage',
              extra: itemsCount?.storage || 0,
              filter: statuses.storage
            },
            {
              name: 'In Transit',
              extra: itemsCount?.transit || 0,
              filter: statuses.transit
            },
            {
              name: 'Delivered',
              extra: itemsCount?.delivered || 0,
              filter: statuses.delivered
            }
          ]}
        />
      </span>
      <div className="ims-right-contain">
        <IMSTopBar
          printFn={() => printFn(pagination?.next?.url)}
          setListView={setListView}
          listView={listView}
          tableKey="items"
          location={location}
          toggleMobileNav={toggleMobileNav}
          columns={cardFieldsNav.details}
          sortFn={sortFn}
          searchFn={searchFn}
          search={location?.search}
          setAddNew={handleAddNewClick}
          filterInfo={[
            {
              filterFn,
              displayKey: 'name',
              dataKey: 'id',
              fullPath: 'jobId',
              items: jobs,
              label: 'Job',
              getDataFn: autocompleteJobs,
              clearOneFilter: clearFilter
            },
            {
              filterFn,
              displayKey: 'name',
              dataKey: 'id',
              fullPath: 'owner',
              items: users,
              label: 'Customer',
              getDataFn: v => autocompleteSearch('users', 'name', v),
              clearOneFilter: clearFilter,
              excludeGroup: groups.client
            },
            {
              filterFn,
              displayKey: 'name',
              dataKey: 'id',
              fullPath: 'carrierId',
              items: carriers,
              label: 'Carrier',
              getDataFn: v => autocompleteSearch('carriers', 'name', v),
              clearOneFilter: clearFilter
            },
            {
              plain: true,
              type: 'date',
              filterFn,
              fullPath: 'dateReceived',
              label: 'Received',
              clearOneFilter: clearFilter
            },
            {
              plain: true,
              type: 'date',
              filterFn,
              fullPath: 'dateDelivered',
              label: 'Delivered',
              clearOneFilter: clearFilter
            },
            {
              plain: true,
              filterFn,
              fullPath: 'bolNumber',
              label: 'BoL Number',
              clearOneFilter: clearFilter
            }
          ]}
        />

        <div className="ims-content" ref={scrollSection} id="scrollSection">
          <InfiniteScroll
            hasChildren={true}
            dataLength={items?.length}
            next={onScroll}
            hasMore={pagination?.next?.url}
            scrollableTarget="scrollSection"
            endMessage={<p className="end-table">End of results</p>}
          >
            <CardsList
              listView={listView}
              data={items}
              fields={cardFieldsMain}
              editClick={onRowClick}
            />
          </InfiniteScroll>
          {isScrolled && (
            <TNButton
              border
              highlight
              onClick={() => {
                scrollSection?.current && scrollSection.current.scrollTo(0, 0);
              }}
              extraClass="back-to-top"
            >
              Back To Top
            </TNButton>
          )}
        </div>
      </div>
      <TransitionGroup className="edit-pane-wrap">
        <CSSTransition
          key={location.key}
          timeout={{ enter: 500, exit: 300 }}
          classNames="edit-pane"
        >
          <Switch location={location}>
            <Route
              exact
              path="/ims/items/:id"
              render={() => (
                <Formik
                  enableReinitialize
                  validationSchema={schema}
                  initialValues={{
                    name: name || '',
                    quantity: quantity || '',
                    billedForReceiving: billedForReceiving || false,
                    dateReceived:
                      dateReceived || format(new Date(), 'yyyy-MM-dd'),
                    dateDelivered: dateDelivered || '',
                    declaredValue: declaredValue || '',
                    customerPo: customerPo || '',
                    comments: comments || '',
                    hasConcerns: hasConcerns || false,
                    onTruck: onTruck || false,
                    carrier: carrier?.name || '',
                    carrierId: carrierId || '',
                    room: room?.name || '',
                    roomId: room?.id || roomId || '',
                    type: type || '',
                    manufacturer: manufacturer?.name || '',
                    manufacturerId: manufacturer?.id || manufacturerId || '',
                    user: user?.name || user || '',
                    owner: user?.id || owner || '',
                    job: job?.name || '',
                    jobId: jobId || ''
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    handleFormSubmit(values).finally(() => {
                      setSubmitting(false);
                    });
                  }}
                >
                  {({ isSubmitting, values, setFieldValue }) => (
                    <EditPane
                      isAdd={addNew}
                      disabled={selectedRow && !selectedRow?.rights?.write}
                      addItem="Item"
                      closePane={resetAndCloseEditPane}
                      headerKeys={{
                        title: 'name',
                        subtitle: 'job.name',
                        img: itemTempImg,
                        signingUrl: getSigningUrl(selectedRow?.id),
                        imgMultiple: false,
                        imgUuid: selectedRow?.id && getUuidV3(selectedRow),
                        imgModalSubmit: handleSaveImg
                      }}
                      column1Keys={[
                        { display: 'Created', key: 'createdAt' },
                        { display: 'Status', key: 'statusText' },
                        { display: 'ID', key: 'id' }
                      ]}
                      column2Keys={[
                        { display: 'Quantity', key: 'quantity' },
                        {
                          display: 'Carrier',
                          key: values?.carrier ? 'carrier' : 'carrier.name'
                        }
                      ]}
                      actionButtons={
                        addNew
                          ? []
                          : [
                              {
                                display: 'Photos…',
                                action: () => setPhotosModalOpen(true)
                              },
                              {
                                disabled: isInspected,
                                display: isInspected
                                  ? 'Inspection Notice Sent'
                                  : 'Send Inspection Notice',
                                action: () =>
                                  handleFormSubmit({
                                    ...values,
                                    status: item.status | statuses.inspected
                                  })
                              }
                            ]
                      }
                      selectedRow={item}
                      formValues={values}
                      extraContent={
                        selectedRow?.rights?.write ||
                        selectedRow?.rights?.del ? (
                          <ul className="extra-actions">
                            {selectedRow.rights?.write && (
                              <>
                                <li
                                  onClick={() => {
                                    handleStatusChange(statuses.storage);
                                  }}
                                >
                                  Mark as in Storage
                                </li>
                                <li
                                  onClick={() => {
                                    handleStatusChange(statuses.transit);
                                  }}
                                >
                                  Mark as in Transit
                                </li>
                                <li
                                  onClick={() => {
                                    handleStatusChange(statuses.delivered);
                                  }}
                                >
                                  Mark as Delivered
                                </li>
                              </>
                            )}
                            {selectedRow.rights?.del && (
                              <li
                                onClick={() => {
                                  requestDelete(selectedRow);
                                }}
                              >
                                Delete
                              </li>
                            )}
                          </ul>
                        ) : null
                      }
                    >
                      <ItemForm
                        disabled={selectedRow && !selectedRow?.rights?.write}
                        isSubmitting={isSubmitting}
                        jobs={jobs}
                        handleFormSubmit={handleFormSubmit}
                        setFieldValue={setFieldValue}
                        values={values}
                        autocompleteSearch={autocompleteSearch}
                      />
                    </EditPane>
                  )}
                </Formik>
              )}
            />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      {photosModalOpen && (
        <PhotosModal
          disabled={selectedRow && !selectedRow?.rights?.write}
          signingUrl={getSigningUrl(selectedRow?.id)}
          photos={photos}
          setPhotos={setPhotos}
          toClose={setPhotosModalOpen}
          save={photos => {
            selectedRow?.id &&
              dispatch(editItem(selectedRow.id, { photos }, true));
          }}
        />
      )}
      {deleteConfirm && (
        <DeleteModal
          toDelete={() => {
            dispatch(deleteItem(deleteConfirm));
          }}
          toClose={() => setDeleteConfirm(null)}
        />
      )}
    </div>
  );
});
