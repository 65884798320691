import React from 'react';
import { Route, Switch } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './Login.scss';

import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';

export default function LoginShell({ location }) {
  return (
    <div className="login">
      <div className="desktop" />
      <div className="content">
        <h2 className="app-name">
          Top-Notch <span>IMS</span>
        </h2>
        <TransitionGroup className="vertical-switch-container">
          <CSSTransition
            key={location.key}
            timeout={1e3}
            classNames="vertical-switch"
          >
            <Switch location={location}>
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/login/forgot-password"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/login/reset-password"
                component={ResetPassword}
              />
              <Route exact path="/login/activate" component={ResetPassword} />
              <Route render={() => <div>Not Found</div>} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}
