import React, { useState, useEffect } from 'react';
import queryString from 'qs';
import classNames from 'classnames';
import usePrevious from '@rooks/use-previous';

import './PageTabs.scss';

import add from './add.svg';
import useGetActions from '../../hooks/useGetActions';

function PageTabs({
  tableKey,
  title,
  className,
  condense,
  addItem,
  tabs,
  search,
  setAddNew,
  history,
  pathKey
}) {
  const path = `where[${pathKey}]`;
  const actions = useGetActions(tableKey);

  const searchActive =
    parsedSearch && parsedSearch.hasOwnProperty('textSearch');
  const parsedSearch = queryString.parse(
    search?.replace('%3F', '').replace('?', '')
  );

  const initTabIdx = (() => {
    if (parsedSearch?.where) {
      for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];
        // noinspection EqualityComparisonWithCoercionJS
        if (
          parsedSearch.where.hasOwnProperty(tab.fullPath) &&
          parsedSearch.where[tab.fullPath] == tab.filter
        ) {
          return i;
        }
      }
    }
  })();

  const [tabIdx, setTabIdx] = useState(initTabIdx || 0);
  const prevTabIdx = usePrevious(tabIdx);

  function clearSearch() {
    const { location } = window;
    const search = new URLSearchParams(location.search);
    search.delete(path);
    history.push({ ...location, search: search.toString() });
  }

  function addQuery(filter) {
    const { location } = window;
    const search = new URLSearchParams(location.search);
    search.set(path, filter);
    history.push({ ...location, search: search.toString() });
  }

  function handleTabChange(idx) {
    setTabIdx(idx);

    if (idx === 0) {
      clearSearch();
    }

    document
      .querySelector('#scrollSection')
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    if (prevTabIdx === null || tabIdx === prevTabIdx) return;
    if (!tabs[tabIdx].filter) {
      clearSearch();
    } else {
      addQuery(tabs[tabIdx].filter);
    }
  }, [tabIdx]);

  const tabContain = classNames('tab-contain', {
    searchActive
  });

  return (
    <div className={classNames('page-tabs', className, { condense })}>
      <div className="ims-bar">
        {actions?.create ? (
          <>
            <p>New {addItem}</p>
            <img src={add} alt="plus" onClick={setAddNew} />
          </>
        ) : (
          <p>Filter your {addItem}</p>
        )}
      </div>
      <h1 className="title">{title}</h1>
      <div className={tabContain}>
        {tabs.map((tab, idx) => {
          const tabClasses = classNames('tab', {
            active: tabIdx === idx
          });
          return (
            <div
              className={tabClasses}
              key={`tab-${idx}`}
              onClick={() => handleTabChange(idx)}
            >
              <h1>{tab.extra}</h1>
              <p>{tab.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PageTabs;

//TODO the z-indexes are wrong at tab overlap
