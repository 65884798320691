import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory, createMemoryHistory } from 'history';

// import { persistStore, persistReducer } from 'redux-persist';
// import localforage from 'localforage';

import createRootReducer from './rootReducer';

// const persistConfig = {
//   storage: localforage,
//   key: 'root',
//   whitelist: []
// };

const isServer = typeof window !== 'object';

export const getHistory = (url = '/') =>
  isServer
    ? createMemoryHistory({ initialEntries: [url] })
    : createBrowserHistory();

export default function configureStore(preloadedState, history) {
  const middleware = [thunk, routerMiddleware(history)];

  if (global.__DEVCLIENT__) {
    middleware.push(logger);
  }

  const allReducers = createRootReducer(history);
  const rootReducer = (state, action) => {
    if (action.type === 'PASS_LOGOUT') {
      state = { router: state.router };
    }
    return allReducers(state, action);
  };

  const store = createStore(
    // isServer ? rootReducer : persistReducer(persistConfig, rootReducer),
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middleware))
  );

  // const persistor = isServer ? { purge: () => {} } : persistStore(store);

  // if (process.env.DISABLE_PERSISTOR) persistor.purge();

  // return { store, persistor };
  return { store };
}
