import React, { useEffect } from 'react';

import {
  Form,
  Field,
  Textarea,
  Autocomplete,
  FieldArray
} from '../../core/Forms';
import TNButton from '../../Site/TNButton/TNButton';

export default function InvoiceForm({
  isSubmitting,
  users = [],
  jobs = [],
  billingItem,
  resetBillingItem,
  getBillingItem,
  billingItems = [],
  defaultBillingItem,
  setFieldValue,
  values,
  autocompleteSearch
}) {
  function handleBISelect(selected, idx) {
    if (selected?.id) {
      getBillingItem(selected.id, idx);
    } else {
      setFieldValue(`billingItems.${idx}.id`, '');
      resetBillingItem();
    }
  }

  useEffect(() => {
    if (billingItem?.id) {
      const idx = billingItem.__selectedIdx;

      setFieldValue(`billingItems.${idx}.template.id`, billingItem.id);

      setFieldValue(`billingItems.${idx}.template.name`, billingItem.name);
      setFieldValue(`billingItems.${idx}.rate`, billingItem.default_rate);
      setFieldValue(
        `billingItems.${idx}.quantity`,
        billingItem.default_quantity
      );
      setFieldValue(
        `billingItems.${idx}.description`,
        billingItem.default_description
      );
    }
  }, [billingItem]);

  function getMatchingBillingItem(text) {
    autocompleteSearch('billingItems', 'name', text);
  }

  function handleUserSelect(selected) {
    if (selected?.['id']) {
      setFieldValue('user', selected.name);
      setFieldValue('userId', selected.id);
    } else {
      setFieldValue('userId', '');
    }
  }

  function handleJobSelect(selected) {
    if (selected?.['id']) {
      setFieldValue('job', selected.name);
      setFieldValue('jobId', selected.id);
    } else {
      setFieldValue('jobId', '');
    }
  }

  function getMatchingJobs(text) {
    autocompleteSearch('jobs', 'name', text);
  }

  function getMatchingUsers(text) {
    autocompleteSearch('users', 'name', text);
  }

  return (
    <Form legend="Add/Edit Invoice" autoComplete="off">
      <Field
        type="number"
        name="number"
        label="Number*"
        helperText="Assign Invoice Number"
      />
      <Field type="date" name="date" label="Invoice Date*" />
      <Field
        type="number"
        name="dueIn"
        label="Due In (Days)*"
        helperText="Put zero for Due on Receipt"
      />
      <Field type="date" name="paidDate" label="Paid Date" />

      <Autocomplete
        searchFn={getMatchingUsers}
        formikInfo={{ values, setFieldValue, field: 'user' }}
        onSelect={handleUserSelect}
        data={users}
        keyKey="id"
        dataKey="name"
        fieldInfo={{ name: 'user', label: 'Client' }}
      />

      <Autocomplete
        searchFn={getMatchingJobs}
        formikInfo={{ values, setFieldValue, field: 'job' }}
        onSelect={handleJobSelect}
        data={jobs}
        keyKey="id"
        dataKey="name"
        fieldInfo={{ name: 'job', label: 'Job' }}
      />

      <FieldArray
        name="billingItems"
        values={values}
        legend="Billing Item"
        defaultValue={defaultBillingItem}
        addButtonText="Add another Billing Item"
      >
        {(item, idx) => (
          <>
            <Autocomplete
              isTypeahead
              searchFn={getMatchingBillingItem}
              formikInfo={{
                values,
                setFieldValue,
                field: `billingItems.${idx}.template.name`
              }}
              onSelect={selected => handleBISelect(selected, idx)}
              data={billingItems}
              keyKey="id"
              dataKey="name"
              fieldInfo={{
                name: `billingItems.${idx}.template.name`,
                label: 'Item Name*'
              }}
            />
            <Field
              type="number"
              name={`billingItems.${idx}.rate`}
              label="Rate*"
              helperText="In US Dollars"
            />
            <Field
              type="number"
              name={`billingItems.${idx}.quantity`}
              label="Quantity"
            />
            <Textarea
              label="Description"
              name={`billingItems.${idx}.description`}
            />
          </>
        )}
      </FieldArray>
      <Textarea label="Notes" name="notes" className="span-all" />
      <TNButton
        border
        type="submit"
        extraClass="span-all form-submit"
        disabled={isSubmitting}
      >
        Submit
      </TNButton>
    </Form>
  );
}

//TODO in edit it should show all existing items in array
