import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form as FormikForm } from 'formik';

import '../styles/forms.scss';

const FieldSet = ({ legend, disabled, grid }) => (
  <fieldset className="fieldset" disabled={disabled}>
    <legend className="legend">{legend}</legend>
    {grid}
  </fieldset>
);

Form.propTypes = {
  legend: PropTypes.string
};

Form.defaultProps = {
  legend: ''
};

export default function Form({ noGrid, legend, disabled, children, ...props }) {
  const grid = (
    <div className={classNames({ 'form-grid': !noGrid })}>{children}</div>
  );

  return (
    <FormikForm className="form" {...props}>
      {legend ? <FieldSet {...{ legend, disabled, grid }} /> : grid}
    </FormikForm>
  );
}
