import ky from 'ky-universal';

const REQUEST_GET_USERS_COUNT = 'REQUEST_GET_USERS_COUNT';
const SUCCESS_GET_USERS_COUNT = 'SUCCESS_GET_USERS_COUNT';
const FAIL_GET_USERS_COUNT = 'FAIL_GET_USERS_COUNT';

function requestGetUsersCount() {
  return { type: REQUEST_GET_USERS_COUNT };
}

function successGetUsersCount({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_GET_USERS_COUNT
  };
}

function failGetUsersCount(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_USERS_COUNT
  };
}

export function getUsersCount() {
  return dispatch => {
    dispatch(requestGetUsersCount());
    return ky
      .get('/api/users/count')
      .json()
      .then(
        res => dispatch(successGetUsersCount(res)),
        async error =>
          dispatch(failGetUsersCount(await error?.response?.json()))
      );
  };
}

const initialUsersCountState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function usersCount(state = initialUsersCountState, action) {
  switch (action.type) {
    case REQUEST_GET_USERS_COUNT:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_USERS_COUNT:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_USERS_COUNT:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
