import React from 'react';
import * as yup from 'yup';
import pick from 'lodash.pick';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../Site/PageWithModal/Modal/Modal';
import TNButton from '../../Site/TNButton/TNButton';
import FieldArray from '../../core/Forms/components/FieldArray';
import Form from '../../core/Forms/components/Form';
import Field from '../../core/Forms/components/Field';
import Textarea from '../../core/Forms/components/Textarea';
import Autocomplete from '../../core/Forms/components/Autocomplete';

import { getInvoices } from '../../../store/lib/invoices/invoices';

import './PaymentModal.scss';
import { formatDollars } from '../../../store/lib/etc/formatFns';
import { getInvoice } from '../../../store/lib/invoices/invoice';
import { createPayment } from '../../../store/lib/payments/createPayment';

const emptyInv = {
  id: '',
  number: '',
  total: '',
  balance: '',
  balanceDisplay: '',
  amount: ''
};

const schema = yup.object().shape({
  checkNumber: yup.string().required('Please enter a number for the check.'),
  amount: yup
    .number()
    .required('Please enter the total amount of the payment')
    .test('amounts-match', 'Amounts do not match', function(value) {
      return (
        value ===
        this.parent.invoices.reduce((t, i) => (i.amount ? t + i.amount : t), 0)
      );
    }),
  notes: yup.string(),
  invoices: yup
    .array()
    .min(1, 'Payment must be applied to at least one Invoice')
    .of(
      yup.object().shape({
        number: yup
          .string()
          .required('Number is required to match the Invoice'),
        amount: yup.number().required('Need an amount to apply payment')
      })
    )
});

function handleInvSelect(dispatch, setFieldValue, idx, values) {
  return async selected => {
    const inv = (await dispatch(getInvoice(selected.id))).data;

    setFieldValue(`invoices.${idx}.id`, inv.id);
    setFieldValue(`invoices.${idx}.number`, inv.number);
    setFieldValue(`invoices.${idx}.total`, inv.total);
    setFieldValue(`invoices.${idx}.balance`, inv.balanceRaw);
    setFieldValue(
      `invoices.${idx}.balanceDisplay`,
      formatDollars(inv.balanceRaw - values.invoices[idx].amount)
    );
  };
}

function getInvSearchFn(dispatch, userId) {
  return val =>
    dispatch(
      getInvoices({
        searchParams: { ac_key: 'number', ac_val: val, 'where[userId]': userId }
      })
    );
}

function getAmountOnChange(handleChange, setFieldValue, invoice, idx) {
  return e => {
    const balance = invoice.balance;

    if (balance) {
      setFieldValue(
        `invoices.${idx}.balanceDisplay`,
        formatDollars(balance - e.target.value)
      );
    }

    handleChange(e);
  };
}

function PaymentModal({ toClose, user }) {
  const dispatch = useDispatch();
  const invoices = useSelector(s => s.invoices.data);

  return (
    <Modal title="Record Payment" toClose={toClose} className="payment-modal">
      <div className="content-contain">
        <Formik
          validationSchema={schema}
          initialValues={{
            amount: '',
            checkNumber: '',
            invoices: [emptyInv]
          }}
          onSubmit={(values, { setSubmitting }) => {
            const data = {
              ...values,
              invoices: undefined,
              invoicesPaid: values.invoices.map(i => pick(i, ['id', 'amount']))
            };

            dispatch(createPayment({ userId: user.id, data }))
              .then(toClose)
              .finally(() => setSubmitting(false));
          }}
        >
          {({ values, setFieldValue, handleChange }) => {
            return (
              <Form legend={`Payment for ${user?.name}`}>
                <Field
                  label="Check Number"
                  name="checkNumber"
                  placeholder="12345"
                  helperText="For your future reference"
                />
                <Field
                  label="Total Check Amount ($)"
                  name="amount"
                  type="number"
                  placeholder="123.45"
                  helperText="Can be split among Invoices below"
                />
                <FieldArray
                  name="invoices"
                  values={values}
                  legend="Item"
                  defaultValue={emptyInv}
                  addButtonText="Apply Payment to another Invoice →"
                >
                  {(item, idx) => (
                    <>
                      <Autocomplete
                        data={invoices}
                        keyKey="id"
                        dataKey="number"
                        searchFn={getInvSearchFn(dispatch, user?.id)}
                        onSelect={handleInvSelect(
                          dispatch,
                          setFieldValue,
                          idx,
                          values
                        )}
                        formikInfo={{
                          values,
                          setFieldValue,
                          field: `invoices.${idx}.number`
                        }}
                        fieldInfo={{
                          name: `invoices.${idx}.number`,
                          label: 'Invoice Number'
                        }}
                      />
                      <Field
                        label="Amount ($)"
                        name={`invoices.${idx}.amount`}
                        helperText="Amount of payment to apply to this invoice"
                        placeholder="123.45"
                        type="number"
                        onChange={getAmountOnChange(
                          handleChange,
                          setFieldValue,
                          values.invoices[idx],
                          idx
                        )}
                      />
                      <Field
                        disabled
                        name={`invoices.${idx}.total`}
                        label="Total"
                        helperText="Sum of Invoice charges"
                      />
                      <Field
                        disabled
                        name={`invoices.${idx}.balanceDisplay`}
                        label="Balance"
                        helperText="Total after previous payments & new amount"
                      />
                    </>
                  )}
                </FieldArray>
                <Textarea label="Notes" name="notes" className="span-all" />
                <TNButton border onClick={toClose}>
                  Cancel
                </TNButton>
                <TNButton border highlight type="submit">
                  Save
                </TNButton>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}

export default PaymentModal;
