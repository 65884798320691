import ky from 'ky-universal';

const REQUEST_EDIT_USER = 'REQUEST_EDIT_USER';
const SUCCESS_EDIT_USER = 'SUCCESS_EDIT_USER';
const FAIL_EDIT_USER = 'FAIL_EDIT_USER';
const RESET_EDIT_USER = 'RESET_EDIT_USER';

export const editUserReset = () => ({ type: RESET_EDIT_USER });

function requestEditUser() {
  return { type: REQUEST_EDIT_USER };
}

function successEditUser({ data }, skipSubmit = false) {
  return {
    data,
    skipSubmit,
    receivedAt: Date.now(),
    type: SUCCESS_EDIT_USER
  };
}

function failEditUser(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_EDIT_USER
  };
}

export function editUser(id, data, skipSubmit = false) {
  return dispatch => {
    dispatch(requestEditUser());
    return ky
      .put(`/api/users/${id}`, { json: data })
      .json()
      .then(
        res => dispatch(successEditUser(res, skipSubmit)),
        async error => dispatch(failEditUser(await error?.response?.json()))
      );
  };
}

const initialUsersState = {
  data: {},
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function editedUser(state = initialUsersState, action) {
  switch (action.type) {
    case REQUEST_EDIT_USER:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_EDIT_USER:
      return {
        ...state,
        data: action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: !action.skipSubmit
      };
    case FAIL_EDIT_USER:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_EDIT_USER:
      return initialUsersState;
    default:
      return state;
  }
}
