import ky from 'ky-universal';

const REQUEST_DELETE_ITEM = 'REQUEST_DELETE_ITEM';
const SUCCESS_DELETE_ITEM = 'SUCCESS_DELETE_ITEM';
const FAIL_DELETE_ITEM = 'FAIL_DELETE_ITEM';
const RESET_DELETE_ITEM = 'RESET_DELETE_ITEM';

export const deleteItemReset = () => ({ type: RESET_DELETE_ITEM });

function requestDeleteItem() {
  return { type: REQUEST_DELETE_ITEM };
}

function successDeleteItem(res, id) {
  return {
    id,
    receivedAt: Date.now(),
    type: SUCCESS_DELETE_ITEM
  };
}

function failDeleteItem(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_DELETE_ITEM
  };
}

export function deleteItem(id) {
  return dispatch => {
    dispatch(requestDeleteItem());
    return ky
      .delete(`/api/items/${id}`)
      .json()
      .then(
        res => dispatch(successDeleteItem(res, id)),
        async error => dispatch(failDeleteItem(await error?.response?.json()))
      );
  };
}

const initialItemsState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null,
  deleted: false
};

export function deletedItem(state = initialItemsState, action) {
  switch (action.type) {
    case REQUEST_DELETE_ITEM:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_DELETE_ITEM:
      return {
        ...state,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        deleted: true
      };
    case FAIL_DELETE_ITEM:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_DELETE_ITEM:
      return initialItemsState;
    default:
      return state;
  }
}
