import React from 'react';
import classNames from 'classnames';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import close from './close.svg';

import './Modal.scss';

export default class Modal extends React.Component {
  //TODO: convert this to a fn component. Was built before we started using Hooks.
  back = e => {
    e?.stopPropagation();
    this.props.history?.goBack();
  };

  constructor(props) {
    super(props);

    this.state = { mounted: false };
    this.content = React.createRef();
    this.contentEl = null;
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.setState({ mounted: true });
      this.contentEl = this.content?.current;
      this.contentEl?.focus();
      disableBodyScroll(this.contentEl);
    }, 150);
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    const { props, back } = this;
    const { toClose, children, title } = props;

    const modalClasses = classNames('modal-back', {
      mounted: this.state.mounted,
      [props.className]: props.className
    });

    return (
      <div className={modalClasses} onClick={toClose || back}>
        <div className="modal-content" ref={this.content} onClick={e => e.stopPropagation()}>
          {title && <h2 className="title">{title}</h2>}
          {children}
          {(toClose || back) && (
            <button type="button" onClick={toClose || back} className="modal-close">
              <img src={close} alt="x to indicate close modal" />
            </button>
          )}
        </div>
      </div>
    );
  }
}
