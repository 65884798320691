import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Field as FormikField } from 'formik';

import Dropdown from '../../../IMS/Dropdown/Dropdown';

RadioButton.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }).isRequired,

  className: PropTypes.any,
  id: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired
};

RadioButton.defaultProps = {
  className: ''
};

export function RadioButton({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  ...props
}) {
  const labelClasses = classNames('radio-button', className);

  return (
    <label className={labelClasses}>
      <input
        id={id}
        name={name}
        type="radio"
        value={id}
        onBlur={onBlur}
        onChange={onChange}
        checked={id === value}
        className="field radio-field"
        {...props}
      />
      <span className="field-label-text radio-label-text">{label}</span>
    </label>
  );
}

RadioGroup.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  touched: PropTypes.bool
};

RadioGroup.defaultProps = {
  className: '',
  error: '',
  disabled: false,
  touched: false
};

export default function RadioGroup({
  id,
  options,
  error,
  touched,
  label,
  className,
  disabled,
  isDropdown,
  filterBar
}) {
  error = touched && error;

  const classes = classNames('radio-group', {
    'radio-group-error': error,
    'radio-group-disabled': disabled,
    [className]: className,
    'is-dropdown': isDropdown
  });

  if (!isDropdown) {
    return (
      <div className={classes}>
        <fieldset className="fieldset fieldset-inner">
          <legend className="legend">{error || label}</legend>
          <div className="radio-fields">
            {options.map(([value, label]) => (
              <FormikField
                key={value}
                name={id}
                id={value}
                label={label}
                component={RadioButton}
              />
            ))}
          </div>
        </fieldset>
      </div>
    );
  }

  return (
    <div className={classes}>
      <fieldset className="fieldset fieldset-inner">
        <Dropdown
          arrow
          filterBar={filterBar}
          extraClass="filter-dropdown"
          outsideText={label}
        >
          <div className="radio-fields">
            {options.map(([value, label]) => (
              <FormikField
                key={value}
                name={id}
                id={value}
                label={label}
                component={RadioButton}
              />
            ))}
          </div>
        </Dropdown>
      </fieldset>
    </div>
  );
}
