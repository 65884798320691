import React, { useState } from 'react';

import Modal from '../../Site/PageWithModal/Modal/Modal';
import TNButton from '../../Site/TNButton/TNButton';
import ImageUploader from '../../core/ImageUploader/ImageUploader';

import './styles/PhotosModal.scss';

function viewButtons(save, toClose, setEditMode, disabled) {
  return (
    <>
      <TNButton onClick={() => toClose(false)}>Close</TNButton>
      {!disabled && (
        <TNButton highlight border onClick={() => setEditMode(true)}>
          Add More
        </TNButton>
      )}
    </>
  );
}

function PhotosModal({
  signingUrl,
  toClose,
  photos,
  setPhotos,
  save,
  disabled
}) {
  const [editMode, setEditMode] = useState(false);

  const handleDelete = idx => () => {
    if (
      window.confirm('Are you sure you want to permanently delete this photo?')
    ) {
      const newPhotos = [...photos];
      newPhotos.splice(idx, 1);
      setPhotos(newPhotos);
      save(newPhotos);
    }
  };

  return (
    <Modal
      title="Item Photos"
      toClose={() => toClose(false)}
      className="photos-modal"
    >
      <div className="content-contain">
        {editMode ? (
          <ImageUploader
            multiple
            signingUrl={signingUrl}
            onSubmit={files => {
              const newPhotos = [
                ...photos,
                ...files.map(x => ({ url: x.meta.fileUrl }))
              ];

              setPhotos(newPhotos);
              save(newPhotos);

              setEditMode(false);
            }}
          />
        ) : (
          <div className="photo-grid">
            {photos?.map?.((photo, idx) => (
              <div
                key={photo.id || idx}
                className="photo-block"
                style={{ backgroundImage: `url(${photo.url})` }}
              >
                <a className="link" target="_blank" href={photo.url} />
                {!disabled && (
                  <button
                    className="photo-delete-button"
                    onClick={handleDelete(idx)}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        <div className="button-row">
          {editMode ? (
            <TNButton onClick={() => setEditMode(false)}>Cancel</TNButton>
          ) : (
            viewButtons(save, toClose, setEditMode, disabled)
          )}
        </div>
      </div>
    </Modal>
  );
}

export default PhotosModal;
