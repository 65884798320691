import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useErrorToast from '../../hooks/useErrorToast';
import TNButton from '../../Site/TNButton/TNButton';
import {
  Form,
  Field,
  Checkbox,
  Textarea,
  SelectGroup,
  Autocomplete
} from '../../core/Forms';

export default function ItemForm({
  disabled,
  isSubmitting,
  handleFormSubmit,
  jobs = [],
  setFieldValue,
  values,
  autocompleteSearch
}) {
  const item = useSelector(s => s.item?.data);
  const userPrivs = useSelector(s => s.user.data.tablePrivileges);
  const itemsPrivs = userPrivs?.find(p => p.table === 'items');
  const canLoad = itemsPrivs?.actions.includes('load');
  const rooms = useSelector(s => s.rooms.data);
  const users = useSelector(s => s.users.data);
  const carriers = useSelector(s => s.carriers.data);
  const manufacturers = useSelector(s => s.manufacturers.data);

  const roomsError = useSelector(s => s.rooms.error);
  const carriersError = useSelector(s => s.carriers.error);
  const manufacturersError = useSelector(s => s.manufacturers.error);

  useErrorToast(roomsError);
  useErrorToast(carriersError);
  useErrorToast(manufacturersError);

  function handleSelect(field, initial, hasChanged) {
    return selected => {
      if (initial === selected && !hasChanged) return;
      if (!initial) hasChanged = true;

      if (selected?.['id']) {
        setFieldValue(field, selected.id);
      } else {
        setFieldValue(field, '');
      }
    };
  }

  function getMatchingCarriers(text) {
    !disabled && autocompleteSearch('carriers', 'name', text);
  }

  function getMatchingRooms(text) {
    !disabled && autocompleteSearch('rooms', 'name', text);
  }

  function getMatchingManufacturers(text) {
    !disabled && autocompleteSearch('manufacturers', 'name', text);
  }

  function handleUserSelect(selected) {
    if (selected?.['id']) {
      setFieldValue('user', selected.name);
      setFieldValue('owner', selected.id);
    } else {
      setFieldValue('owner', null);
    }
  }

  function handleJSelect(selected) {
    if (selected?.['id']) {
      setFieldValue('job', selected.name);
      setFieldValue('jobId', selected.id);
    } else {
      setFieldValue('jobId', null);
    }
  }

  function getMatchingUsers(text) {
    !disabled && autocompleteSearch('users', 'name', text);
  }

  function getMatchingJobs(text) {
    !disabled && autocompleteSearch('jobs', 'name', text);
  }

  return (
    <Form
      legend={disabled ? 'Item Details' : 'Add/Edit Item'}
      autoComplete="off"
      disabled={disabled}
    >
      <Field name="name" label="Item Name" />
      <Autocomplete
        isTypeahead
        searchFn={getMatchingManufacturers}
        formikInfo={{
          values,
          setFieldValue,
          field: 'manufacturer'
        }}
        onSelect={handleSelect(
          'manufacturerId',
          values.manufacturer?.name || values.manufacturer,
          false
        )}
        data={manufacturers}
        keyKey="id"
        dataKey="name"
        fieldInfo={{ name: 'manufacturer', label: 'Manufacturer' }}
      />
      <Field
        type="number"
        name="quantity"
        label="Quantity"
        helperText="How many of this item"
      />
      <SelectGroup
        name="type"
        label="Type"
        options={[
          ['bail', 'Bail'],
          ['carton', 'Carton'],
          ['pallet', 'Pallet'],
          ['piece', 'Piece'],
          ['roll', 'Roll']
        ]}
      />

      <Field type="date" name="dateReceived" label="Date Received" />
      <Checkbox name="billedForReceiving" label="Billed for Receiving?" />
      <Field type="date" name="dateDelivered" label="Date Delivered" />

      {!values.dateDelivered && <Checkbox name="onTruck" label="On Truck?" />}

      <Autocomplete
        isTypeahead
        onSelect={handleSelect(
          'roomId',
          values.room?.name || values.room,
          false
        )}
        searchFn={getMatchingRooms}
        data={rooms}
        keyKey="id"
        dataKey="name"
        fieldInfo={{
          name: 'room',
          label: 'Room',
          helperText: 'Specific location'
        }}
        formikInfo={{
          values,
          setFieldValue,
          field: 'room'
        }}
      />
      <Autocomplete
        isTypeahead
        onSelect={handleSelect(
          'carrierId',
          values.carrier?.name || values.carrier,
          false
        )}
        searchFn={getMatchingCarriers}
        data={carriers}
        keyKey="id"
        dataKey="name"
        fieldInfo={{
          name: 'carrier',
          label: 'Carrier',
          helperText: 'Assign carrier'
        }}
        formikInfo={{
          values,
          setFieldValue,
          field: 'carrier'
        }}
      />
      <Field type="number" name="declaredValue" label="Declared Value" />
      <Field name="customerPo" label="Customer PO" helperText="If provided" />

      <fieldset className="fieldset fieldset-inner span-all">
        <legend className="legend">User Assignment</legend>
        <Autocomplete
          searchFn={getMatchingUsers}
          formikInfo={{
            values,
            setFieldValue,
            field: 'user'
          }}
          onSelect={handleUserSelect}
          data={users}
          keyKey="id"
          dataKey="name"
          fieldInfo={{ name: 'user', label: 'User Name' }}
        />
      </fieldset>

      <fieldset className="fieldset fieldset-inner span-all">
        <legend className="legend">Job Assignment</legend>
        <Autocomplete
          searchFn={getMatchingJobs}
          formikInfo={{
            values,
            setFieldValue,
            field: 'job'
          }}
          onSelect={handleJSelect}
          data={jobs}
          keyKey="id"
          dataKey="name"
          fieldInfo={{ name: 'job', label: 'Job Name' }}
        />
      </fieldset>

      <Textarea name="comments" label="Comments" className="span-all" />

      <Checkbox
        className="collapseCheck"
        name="hasConcerns"
        label="Concerns?"
      />
      {item.id && values.hasConcerns && canLoad && (
        <TNButton
          disabled={item.sentConcerns}
          border
          type="button"
          onClick={e => {
            e.stopPropagation();
            return handleFormSubmit({ ...values, sentConcerns: true });
          }}
        >
          {item.sentConcerns ? 'Concerns Sent' : 'Send Concerns'}
        </TNButton>
      )}

      {!disabled && (
        <TNButton
          border
          type="submit"
          extraClass="span-all form-submit"
          disabled={isSubmitting}
        >
          Submit
        </TNButton>
      )}
    </Form>
  );
}
