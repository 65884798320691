import ky from 'ky-universal';

const REQUEST_CREATE_JOB = 'REQUEST_CREATE_JOB';
const SUCCESS_CREATE_JOB = 'SUCCESS_CREATE_JOB';
const FAIL_CREATE_JOB = 'FAIL_CREATE_JOB';
const RESET_CREATE_JOB = 'RESET_CREATE_JOB';

export const createJobReset = () => ({ type: RESET_CREATE_JOB });

function requestCreateJob() {
  return { type: REQUEST_CREATE_JOB };
}

function successCreateJob({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_CREATE_JOB
  };
}

function failCreateJob(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_CREATE_JOB
  };
}

export function createJob(data) {
  return dispatch => {
    dispatch(requestCreateJob());
    return ky
      .post('/api/jobs', { json: data })
      .json()
      .then(
        res => dispatch(successCreateJob(res)),
        async error => dispatch(failCreateJob(await error?.response?.json()))
      );
  };
}

const initialJobsState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function createdJob(state = initialJobsState, action) {
  switch (action.type) {
    case REQUEST_CREATE_JOB:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_CREATE_JOB:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: true
      };
    case FAIL_CREATE_JOB:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_CREATE_JOB:
      return initialJobsState;
    default:
      return state;
  }
}
