import ky from 'ky-universal';

const REQUEST_GET_BILLING_ITEMS = 'REQUEST_GET_BILLING_ITEMS';
const SUCCESS_GET_BILLING_ITEMS = 'SUCCESS_GET_BILLING_ITEMS';
const FAIL_GET_BILLING_ITEMS = 'FAIL_GET_BILLING_ITEMS';

function requestGetBillingItems() {
  return { type: REQUEST_GET_BILLING_ITEMS };
}

function successGetBillingItems({ data, page }, next) {
  return {
    data,
    page,
    wasPaging: !!next,
    receivedAt: Date.now(),
    type: SUCCESS_GET_BILLING_ITEMS
  };
}

function failGetBillingItems(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_BILLING_ITEMS
  };
}

function tryGetBillingItems(next, search) {
  return dispatch => {
    dispatch(requestGetBillingItems());

    return ky
      .get(next || `/api/billing-items${search}`)
      .json()
      .then(
        res => dispatch(successGetBillingItems(res, next)),
        async error =>
          dispatch(failGetBillingItems(await error?.response?.json()))
      );
  };
}

function shouldGetBillingItems({ billingItems }) {
  if (billingItems.isFetching) {
    return false;
  } else if (!billingItems.data.length) {
    return true;
  } else {
    return billingItems.lastUpdated < Date.now() - 50;
  }
}

export function getBillingItems({ next, search = null } = {}) {
  return (dispatch, getState) => {
    if (shouldGetBillingItems(getState())) {
      return dispatch(tryGetBillingItems(next, search));
    }
  };
}

const initialBillingItemsState = {
  data: [],
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function billingItems(state = initialBillingItemsState, action) {
  switch (action.type) {
    case REQUEST_GET_BILLING_ITEMS:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_BILLING_ITEMS:
      return {
        ...state,
        data: action.wasPaging ? [...state.data, ...action.data] : action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_BILLING_ITEMS:
      return {
        ...state,
        data: [],
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
