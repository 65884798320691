import React from 'react';
import { useSelector } from 'react-redux';

import { groups } from '../../../api/db/config/access';
import { Form, Field, SelectGroup, Textarea } from '../../core/Forms';
import TNButton from '../../Site/TNButton/TNButton';

export default function UserForm({ isSubmitting }) {
  const user = useSelector(s => s.user?.data);
  let accessOptions = [
    [8, 'Employee'],
    [16, 'Client'],
    [2, 'Admin'],
    [4, 'Billing'],
    [6, 'Billing Admin']
  ];

  if ((user.memberships & groups.root) !== 0) {
    accessOptions.push([1, 'Root']);
  }

  return (
    <Form legend="Add/Edit User">
      <Field
        name="name"
        label="Name*"
        helperText="The user's name or company name"
        placeholder="Jiminy Cricket"
      />

      <Field
        name="username"
        label="Username*"
        helperText="User will use this to log in"
        placeholder="JimCricket666"
      />

      <Field
        type="email"
        name="email"
        label="Email"
        helperText="A valid email"
        placeholder="jim-me@mail.com"
        className="span-all"
      />

      <Textarea
        name="billingEmail"
        label="Billing Email Addresses"
        className="span-all"
      />

      <Field
        type="phone"
        name="phone"
        label="Phone"
        helperText="Optional"
        placeholder="666-666-6666"
      />

      <Field
        type="website"
        name="website"
        label="Website"
        helperText="Optional"
        placeholder="https://cricketRevenge.com"
      />

      <Field
        name="companyName"
        label="Company"
        helperText="Company, if different from name"
        placeholder="The Cricket Cafe & Bar"
      />

      <SelectGroup
        name="memberships"
        label="Choose an Access Level*"
        options={accessOptions}
      />

      <TNButton
        border
        type="submit"
        extraClass="span-all form-submit"
        disabled={isSubmitting}
      >
        Submit
      </TNButton>
    </Form>
  );
}
