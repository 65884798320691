import React, { useEffect } from 'react';

import { Form, Field, Autocomplete } from '../../core/Forms';
import TNButton from '../../Site/TNButton/TNButton';

export default function JobForm({
  isSubmitting,
  users,
  address,
  getAddress,
  resetAddress,
  setFieldValue,
  values,
  addresses,
  autocompleteSearch
}) {
  function handleSelect(selected) {
    setFieldValue('user', selected.name);
    if (selected?.id) {
      setFieldValue('userId', selected.id);
    }
  }

  function getMatchingUsers(text) {
    autocompleteSearch('users', 'name', text);
  }

  function getMatchingAddresses(text) {
    autocompleteSearch('addresses', 'street', text);
  }

  function handleSelectA(selected) {
    if (selected?.id) {
      getAddress(selected.id);
    } else {
      setFieldValue('addressId', '');
      resetAddress();
    }
  }

  useEffect(() => {
    if (address?.id) {
      setFieldValue('addressId', address.id);

      setFieldValue('locale', address.locale?.name);
      setFieldValue('state', address.locale?.state);
      setFieldValue('postalCode', address.locale?.postalCode);
    }
  }, [address]);

  return (
    <Form legend="Add/Edit Job">
      <Field
        name="name"
        label="Name*"
        helperText="The job's name"
        placeholder="VIP Delivery"
      />

      <Autocomplete
        onSelect={handleSelect}
        searchFn={getMatchingUsers}
        data={users}
        keyKey="id"
        dataKey="name"
        fieldInfo={{
          name: 'user',
          label: 'User*',
          helperText: 'Assign job to client'
        }}
        formikInfo={{
          values,
          setFieldValue,
          field: 'user'
        }}
      />
      <fieldset className="fieldset fieldset-inner span-all">
        <legend className="legend">Delivery Address</legend>

        <Autocomplete
          isTypeahead
          searchFn={getMatchingAddresses}
          formikInfo={{
            values,
            setFieldValue,
            field: 'street'
          }}
          onSelect={handleSelectA}
          data={addresses}
          keyKey="id"
          dataKey="street"
          fieldInfo={{ name: 'street', label: 'Street' }}
        />
        <div className="form-grid">
          <Field name="locale" label="City" />
          <Field name="state" label="State" />
          <Field name="postalCode" label="Zip" />
        </div>
      </fieldset>

      <TNButton
        border
        type="submit"
        extraClass="span-all form-submit"
        disabled={isSubmitting}
      >
        Submit
      </TNButton>
    </Form>
  );
}

//TODO allow upload for images to each item?
