import ky from 'ky-universal';
import { formatDate, getStatus } from '../etc/formatFns';

const REQUEST_GET_JOBS = 'REQUEST_GET_JOBS';
const SUCCESS_GET_JOBS = 'SUCCESS_GET_JOBS';
const FAIL_GET_JOBS = 'FAIL_GET_JOBS';
const SUCCESS_DELETE_JOB = 'SUCCESS_DELETE_JOB';
const SUCCESS_CREATE_JOB = 'SUCCESS_CREATE_JOB';
const SUCCESS_EDIT_JOB = 'SUCCESS_EDIT_JOB';

function requestGetJobs() {
  return { type: REQUEST_GET_JOBS };
}

function successGetJobs({ data, page }, next) {
  return {
    data,
    page,
    wasPaging: !!next,
    receivedAt: Date.now(),
    type: SUCCESS_GET_JOBS
  };
}

function failGetJobs(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_JOBS
  };
}

export function tryGetJobs(next, search) {
  return dispatch => {
    dispatch(requestGetJobs());
    return ky
      .get(next || `/api/jobs${search}`)
      .json()
      .then(
        res => dispatch(successGetJobs(res, next)),
        async error => dispatch(failGetJobs(await error?.response?.json()))
      );
  };
}

function shouldGetJobs({ jobs }) {
  if (jobs.isFetching) {
    return false;
  } else if (!jobs.data?.length) {
    return true;
  } else {
    return jobs.lastUpdated < Date.now() - 50;
  }
}

export function getJobs({ next, search = null } = {}) {
  return (dispatch, getState) => {
    if (shouldGetJobs(getState())) {
      return dispatch(tryGetJobs(next, search));
    }
  };
}

const initialJobsState = {
  data: [],
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

function formatJob(job) {
  job.updatedAt = job.updatedAt && formatDate(job.updatedAt);
  job.createdAt = job.createdAt && formatDate(job.createdAt);
  job.statusText = getStatus(job.status);
  return job;
}
export function jobs(state = initialJobsState, action) {
  switch (action.type) {
    case REQUEST_GET_JOBS:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_JOBS:
      action.data.forEach(u => {
        formatJob(u);
      });

      return {
        ...state,
        data: action.wasPaging ? [...state.data, ...action.data] : action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_JOBS:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case SUCCESS_DELETE_JOB:
      return {
        ...state,
        data: state.data?.filter(job => job.id !== action.id),
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };

    case SUCCESS_CREATE_JOB:
      const nextData = [formatJob({ ...action.data }), ...state.data];

      return {
        ...state,
        data: nextData,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case SUCCESS_EDIT_JOB:
      const data = formatJob({ ...action.data });

      const editedData = [...state.data];
      const editedIdx = editedData.findIndex(item => item.id === data.id);
      editedData[editedIdx] = data;

      return {
        ...state,
        data: editedData,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
}
