import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { testStore } from './lib/testStore';
import { prevLoc } from './lib/prevLoc';

import { invoiceReport } from './lib/dashSummaries/invoiceReport';

import { user } from './lib/user/user';
import { users } from './lib/users/users';
import { resetEmail } from './lib/user/resetEmail';
import { passReset } from './lib/user/passwordReset';
import { createdUser } from './lib/users/createUser';
import { editedUser } from './lib/users/editUser';
import { deletedUser } from './lib/users/deleteUser';
import { usersCount } from './lib/users/usersCount';

import { job } from './lib/jobs/job';
import { jobs } from './lib/jobs/jobs';
import { createdJob } from './lib/jobs/createJob';
import { editedJob } from './lib/jobs/editJob';
import { deletedJob } from './lib/jobs/deleteJob';
import { jobsCount } from './lib/jobs/jobsCount';

import { invoice } from './lib/invoices/invoice';
import { invoices } from './lib/invoices/invoices';
import { createdInvoice } from './lib/invoices/createInvoice';
import { editedInvoice } from './lib/invoices/editInvoice';
import { deletedInvoice } from './lib/invoices/deleteInvoice';
import { invoicesCount } from './lib/invoices/invoicesCount';

import { items } from './lib/items/items';
import { item } from './lib/items/item';
import { createdItem } from './lib/items/createItem';
import { editedItem } from './lib/items/editItem';
import { deletedItem } from './lib/items/deleteItem';
import { itemsCount } from './lib/items/itemsCount';

import { address } from './lib/addresses/address';
import { addresses } from './lib/addresses/addresses';
import { carriers } from './lib/carriers/carriers';
import { rooms } from './lib/rooms/rooms';
import { manufacturers } from './lib/manufacturers/manufacturers';
import { billingItem } from './lib/billingItems/billingItem';
import { billingItems } from './lib/billingItems/billingItems';
import { loading } from './lib/loading';
import { postContact } from './lib/contact/postContact';
import { createdPayment } from './lib/payments/createPayment';
import { createdStatement } from './lib/statements/createStatement';

export default history =>
  combineReducers({
    router: connectRouter(history),

    loading,
    postContact,

    testStore,
    prevLoc,

    invoiceReport,

    items,
    item,
    createdItem,
    editedItem,
    deletedItem,
    itemsCount,

    createdUser,
    editedUser,
    deletedUser,
    users,
    user,
    resetEmail,
    passReset,
    usersCount,

    job,
    jobs,
    createdJob,
    editedJob,
    deletedJob,
    jobsCount,

    invoice,
    invoices,
    createdInvoice,
    editedInvoice,
    deletedInvoice,
    invoicesCount,

    createdPayment,
    createdStatement,

    address,
    addresses,
    carriers,
    rooms,
    manufacturers,
    billingItem,
    billingItems
  });
