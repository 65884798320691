import React, { useState } from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import { Field } from '../../../core/Forms';

import eye from '../eye.svg';

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
};

PasswordField.defaultProps = {
  name: 'password',
  label: 'Password'
};

export default function PasswordField(props) {
  const [showPW, setShowPW] = useState(false);

  const pwLabelClass = classNames('pw-eye', { show: showPW });

  return (
    <div className="span-all show-pw">
      <Field
        name={props.name}
        label={props.label}
        type={showPW ? 'text' : 'password'}
        className="span-all"
        autoComplete="new-password"
      />
      <img
        src={eye}
        alt="eye to hide/show pw"
        className={pwLabelClass}
        onClick={() => setShowPW(!showPW)}
      />
    </div>
  );
}
