import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import TNButton from '../TNButton/TNButton';

import './CTA.scss';

function CTA({ page }) {
  const ctaClasses = classNames('cta', {
    page
  });

  return (
    <div className={ctaClasses}>
      <a href="mailto:customersupport@topnotchfla.com">
        <TNButton border highlight>
          Hire Us
        </TNButton>
      </a>
      {/*<Link to="/login">*/}
      {/*  <TNButton>Log in</TNButton>*/}
      {/*</Link>*/}
    </div>
  );
}

export default CTA;
