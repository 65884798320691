import ky from 'ky-universal';
import { formatDate, getStatus } from '../etc/formatFns';

const REQUEST_GET_INVOICES = 'REQUEST_GET_INVOICES';
const SUCCESS_GET_INVOICES = 'SUCCESS_GET_INVOICES';
const FAIL_GET_INVOICES = 'FAIL_GET_INVOICES';
const SUCCESS_DELETE_INVOICE = 'SUCCESS_DELETE_INVOICE';
const SUCCESS_CREATE_INVOICE = 'SUCCESS_CREATE_INVOICE';
const SUCCESS_EDIT_INVOICE = 'SUCCESS_EDIT_INVOICE';

function requestGetInvoices() {
  return { type: REQUEST_GET_INVOICES };
}

function successGetInvoices({ data, page }, next) {
  return {
    data,
    page,
    wasPaging: !!next,
    receivedAt: Date.now(),
    type: SUCCESS_GET_INVOICES
  };
}

function failGetInvoices(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_INVOICES
  };
}

export function tryGetInvoices(next, search, searchParams) {
  return dispatch => {
    dispatch(requestGetInvoices());
    return ky
      .get(next || `/api/invoices${search}`, { searchParams })
      .json()
      .then(
        res => dispatch(successGetInvoices(res, next)),
        async error => dispatch(failGetInvoices(await error?.response?.json()))
      );
  };
}

function shouldGetInvoices({ invoices }) {
  if (invoices.isFetching) {
    return false;
  } else if (!invoices.data?.length) {
    return true;
  } else {
    return invoices.lastUpdated < Date.now() - 50;
  }
}

export function getInvoices({ next, search = '', searchParams } = {}) {
  return (dispatch, getState) => {
    if (shouldGetInvoices(getState())) {
      return dispatch(tryGetInvoices(next, search, searchParams));
    }
  };
}

const initialInvoicesState = {
  data: [],
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

function formatInvoice(invoice) {
  invoice.updatedAt = formatDate(invoice.updatedAt);
  invoice.createdAt = formatDate(
    invoice.createdAt ? invoice.createdAt : Date.now()
  );
  invoice.statusText = getStatus(invoice.status, 'invoice');
  return invoice;
}

export function invoices(state = initialInvoicesState, action) {
  switch (action.type) {
    case REQUEST_GET_INVOICES:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_INVOICES:
      action.data.forEach(u => {
        formatInvoice(u);
      });

      return {
        ...state,
        data: action.wasPaging ? [...state.data, ...action.data] : action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_INVOICES:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case SUCCESS_DELETE_INVOICE:
      return {
        ...state,
        data: state.data?.filter(invoice => invoice.id !== action.id),
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case SUCCESS_CREATE_INVOICE:
      const nextData = [formatInvoice({ ...action.data }), ...state.data];

      return {
        ...state,
        data: nextData,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };

    case SUCCESS_EDIT_INVOICE:
      const data = formatInvoice({ ...action.data });
      const editedData = [...state.data];
      const editedIdx = editedData.findIndex(item => item.id === data.id);
      editedData[editedIdx] = data;

      return {
        ...state,
        data: editedData,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
}
