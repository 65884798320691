import ky from 'ky-universal';

const REQUEST_GET_INVOICES_COUNT = 'REQUEST_GET_INVOICES_COUNT';
const SUCCESS_GET_INVOICES_COUNT = 'SUCCESS_GET_INVOICES_COUNT';
const FAIL_GET_INVOICES_COUNT = 'FAIL_GET_INVOICES_COUNT';

function requestGetInvoicesCount() {
  return { type: REQUEST_GET_INVOICES_COUNT };
}

function successGetInvoicesCount({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_GET_INVOICES_COUNT
  };
}

function failGetInvoicesCount(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_INVOICES_COUNT
  };
}

export function getInvoicesCount() {
  return dispatch => {
    dispatch(requestGetInvoicesCount());
    return ky
      .get('/api/invoices/count')
      .json()
      .then(
        res => dispatch(successGetInvoicesCount(res)),
        async error =>
          dispatch(failGetInvoicesCount(await error?.response?.json()))
      );
  };
}

const initialInvoicesCountState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function invoicesCount(state = initialInvoicesCountState, action) {
  switch (action.type) {
    case REQUEST_GET_INVOICES_COUNT:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_INVOICES_COUNT:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_INVOICES_COUNT:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
