import React from 'react';

import Page from '../Page/Page';

import warehouse2 from './images/warehouse2.jpeg';
import sepiaTrucks from './images/sepiaTrucks.jpg';
import asidPartner from './images/ASIDPartner.png';
import cloudyTruck from './images/cloudyTruck.jpg';
import teamInside from './images/team_inside.jpeg';

const aboutContent = {
  title: (
    <div className="page-title-container">
      {/* the zero-width space (&#8203;) for some reason makes this work on Safari */}
      <h2 className="page-title" data-text="About Top-Notch&#8203;">
        About Top-Notch&#8203;
      </h2>
      <h2 className="page-title" data-text="Delivery Services&#8203;">
        Delivery Services&#8203;
      </h2>
    </div>
  ),
  deskTitle: 'Our Mission',
  body: (
    <div className="page-main-body">
      <p>
        Top-Notch Delivery Service is a family owned and operated company with
        over 20 years of experience in the interior design industry. We
        specialize in working directly with the interior designer throughout the
        entire process from consolidation to installation.
      </p>

      <p>
        Our Mission is to perform the highest level of quality service to our
        customers at fair and competitive prices. We want to ensure the
        longevity of our company through repeat and referral business achieved
        by customer satisfaction in all areas including timeliness, attention to
        detail and service minded attitudes.
      </p>
      <p>
        We strive to maintain the highest levels of professionalism, integrity,
        honesty, and fairness in our relationships with our designers,
        manufacturers, professional associates, and customers.
      </p>
      <p>
        Top-Notch Delivery Service is an industry partner of the American
        Society of Interior Designers (ASID), which demands the highest
        integrity and standards from its members.
        <img
          src={asidPartner}
          alt="logo/badge demonstrating partnership of ASID"
          className="extra-image"
        />
      </p>
    </div>
  )
};

function About() {
  return (
    <Page
      topImg={cloudyTruck}
      topImgStyle={{ backgroundPosition: 'left' }}
      bottomImg={sepiaTrucks}
      deskImg1={warehouse2}
      deskImg2={teamInside}
      deskImg2Style={{ backgroundPosition: 'center' }}
      text={{
        title: aboutContent.title,
        body: aboutContent.body,
        deskTitle: aboutContent.deskTitle
      }}
    />
  );
}

export default About;
