import ky from 'ky-universal';

const REQUEST_GET_ITEMS_COUNT = 'REQUEST_GET_ITEMS_COUNT';
const SUCCESS_GET_ITEMS_COUNT = 'SUCCESS_GET_ITEMS_COUNT';
const FAIL_GET_ITEMS_COUNT = 'FAIL_GET_ITEMS_COUNT';

const INC_COUNT = 'INC_COUNT';
const DEC_COUNT = 'DEC_COUNT';

function requestGetItemsCount() {
  return { type: REQUEST_GET_ITEMS_COUNT };
}

function successGetItemsCount({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_GET_ITEMS_COUNT
  };
}

function failGetItemsCount(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_ITEMS_COUNT
  };
}

export function getItemsCount(opts) {
  return dispatch => {
    dispatch(requestGetItemsCount());
    return ky
      .get('/api/items/count', opts)
      .json()
      .then(
        res => dispatch(successGetItemsCount(res)),
        async error =>
          dispatch(failGetItemsCount(await error?.response?.json()))
      );
  };
}

export function incItemsCount(key) {
  return { key, type: INC_COUNT };
}

export function decItemsCount(key) {
  return { key, type: DEC_COUNT };
}

const initialItemsCountState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function itemsCount(state = initialItemsCountState, action) {
  switch (action.type) {
    case REQUEST_GET_ITEMS_COUNT:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_ITEMS_COUNT:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_ITEMS_COUNT:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case INC_COUNT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: ++state.data[action.key]
        }
      };
    case DEC_COUNT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: --state.data[action.key]
        }
      };
    default:
      return state;
  }
}
