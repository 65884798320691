const getLoadingKey = ([, method, resource]) =>
  `${method}_${resource}`.toLowerCase();

export const loading = (state = { now: false, pending: [] }, action) => {
  const fetchStart = action.type.match(
    /REQUEST_(GET|POST|CREATE|PUT|EDIT|DELETE|LOGIN)_?(.*)/
  );

  const fetchEnd = action.type.match(
    /(?:SUCCESS|FAIL|PASS)_(GET|POST|CREATE|PUT|EDIT|DELETE|LOGIN)_?(.*)/
  );

  if (action.type === 'SET_LOADING') {
    return {
      now: !!state.pending.length || action.value,
      ...state
    };
  } else if (fetchStart) {
    return {
      now: true,
      pending: [...state.pending, getLoadingKey(fetchStart)]
    };
  } else if (fetchEnd) {
    const pending = state.pending.filter(x => x !== getLoadingKey(fetchEnd));

    return { now: !!pending.length, pending };
  } else {
    return state;
  }
};
