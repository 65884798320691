import React, { useEffect, useRef, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import { v3 as uuidv3 } from 'uuid';
import { uuidNs, statuses } from '../../config';

import * as yup from 'yup';

import PageTabs from '../PageTabs/PageTabs';
import IMSTopBar from '../IMSTopBar/IMSTopBar';
import Table from '../Table/Table';
import UserForm from './AddEditUser';
import DeleteModal from '../DeleteModal/DeleteModal';
import EditPane from '../EditPane/EditPane';
import TNButton from '../../Site/TNButton/TNButton';

import { getUsers } from '../../../store/lib/users/users';
import { editUser, editUserReset } from '../../../store/lib/users/editUser';
import { getUsersCount } from '../../../store/lib/users/usersCount';
import { checkUsername } from '../../../store/lib/user/usernameAvailable';
import { checkEmail } from '../../../store/lib/user/emailAvailable';
import { createStatementReset } from '../../../store/lib/statements/createStatement';
import {
  createUser,
  createUserReset
} from '../../../store/lib/users/createUser';
import {
  deleteUser,
  deleteUserReset
} from '../../../store/lib/users/deleteUser';
import { changeSort, textSearchSubmit } from '../../../store/lib/etc/searchFns';

import useErrorToast from '../../hooks/useErrorToast';
import StatementModal from '../StatementModal/StatementModal';
import PaymentModal from '../PaymentModal/PaymentModal';
import { createPaymentReset } from '../../../store/lib/payments/createPayment';

const mapStateToProps = data => ({
  pagination: data.users.page,
  fetching: data.users.isFetching,

  users: data.users.data,
  createdUser: data.createdUser,
  editedUser: data.editedUser,
  deletedUser: data.deletedUser,
  usersCount: data.usersCount.data,
  createdPayment: data.createdPayment,

  getUserError: data.users.error,
  createUserError: data.createdUser.error,
  editUserError: data.editedUser.error,
  deleteUserError: data.deletedUser.error,
  usersCountError: data.usersCount.error,

  createdStatement: data.createdStatement
});

const columns = [
  { display: 'ID', dataKey: 'id' },
  {
    display: 'Company',
    dataKey: 'companyName',
    sortKey: 'companyName'
  },
  { display: 'Created', dataKey: 'createdAt' },
  { display: 'Last Changed', dataKey: 'updatedAt' },
  { display: 'Name', dataKey: 'name' }
];

const getSchema = initial =>
  yup.object().shape({
    name: yup.string().required('Please enter a name for the user'),
    username: yup
      .string()
      .required('Please enter a username.')
      .test(
        'is-username-available',
        'Username is not available.',
        async value =>
          initial?.username === value ||
          (await checkUsername(value)).data.available
      ),
    email: yup
      .string()
      .email('Please enter a valid email.')
      .test(
        'is-email-available',
        'Email is not available',
        async value =>
          initial?.email === value || (await checkEmail(value)).data.available
      ),
    memberships: yup.number().required('Select an access level.'),
    billingEmail: yup
      .array()
      .transform(function (value, originalValue) {
        console.log({ value }, { originalValue });
        return this.isType(value)
          ? value
          : originalValue.split(',').map(s => s.trim());
      })
      .of(
        yup
          .string()
          .email(({ value }) => `${value} is not a valid email address`)
      )
  });

function getUuidV3(selectedRow) {
  return uuidv3(selectedRow.id.toString(), uuidNs.userImg);
}

function getSigningUrl(id) {
  return `/api/s3-uploads/users${id ? `/${id}` : ''}`;
}

export default connect(mapStateToProps)(function Users({
  toggleMobileNav,
  dispatch,
  createdUser,
  editedUser,
  deletedUser,
  users,
  usersCount,

  pagination,
  location,
  history,

  getUserError,
  createUserError,
  editUserError,
  deleteUserError,
  usersCountError,

  createdPayment,
  createdStatement
}) {
  useErrorToast(getUserError);
  useErrorToast(createUserError);
  useErrorToast(editUserError);
  useErrorToast(deleteUserError);
  useErrorToast(usersCountError);
  useErrorToast(createdPayment.error);
  useErrorToast(createdStatement.error);

  useEffect(() => {
    if (createdPayment.submitted) {
      toast.success('Payment submitted successfully!');
      dispatch(createPaymentReset());
    }
  }, [createdPayment.submitted]);

  //stuff for handling the main getting of data and errors
  useEffect(() => {
    dispatch(getUsers({ search: location.search }));
  }, [location]);

  useEffect(() => {
    dispatch(getUsersCount());
  }, []);

  const scrollSection = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  function handleScroll() {
    const scrollTop = scrollSection?.current?.scrollTop;
    if (!isScrolled && scrollTop > 100) {
      setIsScrolled(true);
    } else if (isScrolled && scrollTop < 50) {
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    scrollSection?.current?.addEventListener('scroll', handleScroll);
    return () =>
      scrollSection?.current?.removeEventListener('scroll', handleScroll);
  });

  function onScroll() {
    dispatch(getUsers({ next: pagination?.next?.url }));
  }

  //stuff for controlling add and edit panel
  const [selectedRow, setSelectedRow] = useState(null);
  const [addNew, setAddNew] = useState(null);
  const [userImg, setUserImg] = useState(selectedRow?.photoUrl || '');
  const editPaneOpen = addNew || selectedRow;
  const {
    name,
    username,
    email,
    billingEmail,
    phone,
    memberships,
    website,
    companyName
  } = selectedRow ? selectedRow : {};

  function resetAndCloseEditPane() {
    setAddNew(null);
    setSelectedRow(null);
  }

  function onRowClick(selected) {
    if (selectedRow?.id === selected?.id) {
      setSelectedRow(null);
    } else if (addNew) {
      setAddNew(null);
      setTimeout(() => {
        setSelectedRow(selected);
        setUserImg(selected.photoUrl);
      }, 500);
    } else {
      setSelectedRow(selected);
      setUserImg(selected.photoUrl);
    }
  }

  function handleAddNewClick() {
    if (selectedRow) {
      setSelectedRow(null);
      setTimeout(() => {
        setAddNew(true);
      }, 500);
    } else {
      setAddNew(true);
    }
  }

  function handleSaveImg(imgFiles) {
    const photoUrl = imgFiles?.[0]?.meta?.fileUrl;
    setUserImg(`${photoUrl}?ts=${Date.now()}`);
    selectedRow?.id && dispatch(editUser(selectedRow.id, { photoUrl }, true));
  }

  //stuff for add and edit forms
  function handleFormSubmit(values) {
    const formatValues = { ...values };

    if (userImg) {
      formatValues.photoUrl = userImg;
    }

    if (selectedRow) {
      return dispatch(editUser(selectedRow.id, formatValues));
    } else {
      return dispatch(createUser(formatValues));
    }
  }

  useEffect(() => {
    // success messages
    createdUser.submitted && toast.success('User created successfully');
    editedUser.submitted && toast.success('User edited successfully');

    if (createdStatement.submitted) {
      toast.success('Statement sent successfully!');
      dispatch(createStatementReset());
    }

    resetAndCloseEditPane();
  }, [createdUser.submitted, editedUser.submitted, createdStatement.submitted]);

  useEffect(() => {
    //reset createUser object after pane closes on submit
    if (!addNew) {
      dispatch(createUserReset());
    }
    if (editedUser.submitted && !selectedRow) {
      dispatch(editUserReset());
    }
  }, [addNew, selectedRow]);

  const [paymentModal, setPaymentModal] = useState(null);
  const [statementModal, setStatementModal] = useState(null);

  //stuff for delete
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  function requestDelete(selected) {
    setDeleteConfirm(selected.id);
  }

  useEffect(() => {
    if (deletedUser?.submitted || deleteUserError) {
      setDeleteConfirm(null);
      dispatch(deleteUserReset());
      resetAndCloseEditPane();
      deletedUser.submitted && toast.success('User deleted successfully');
    }
  }, [deletedUser.submitted, deleteUserError]);

  //stuff for search, sort, filters, queries
  const _oldQuery = location.search.replace('%3F', '').replace('?', '');

  function sortFn(newQuery) {
    changeSort(newQuery, _oldQuery, history, location);
  }

  function searchFn(text) {
    textSearchSubmit(text, _oldQuery, history, location);
  }

  return (
    <div className="users ims-page">
      <ReactCSSTransitionGroup
        transitionName="page-tabs"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {!editPaneOpen && (
          <PageTabs
            title="Users"
            addItem="User"
            tableKey="users"
            search={location && location.search}
            condense={editPaneOpen}
            setAddNew={handleAddNewClick}
            pathKey="memberships"
            history={history}
            tabs={[
              {
                name: 'All Users',
                extra: usersCount?.all || 0
              },
              {
                name: 'Admins',
                extra: usersCount?.admin || 0,
                filter: '2'
              },
              {
                name: 'Clients',
                extra: usersCount?.client || 0,
                filter: '16'
              },
              {
                name: 'Employees',
                extra: usersCount?.employee || 0,
                filter: '8'
              }
            ]}
          />
        )}
      </ReactCSSTransitionGroup>

      <div className="ims-right-contain">
        <IMSTopBar
          tableKey="users"
          toggleMobileNav={toggleMobileNav}
          columns={columns}
          sortFn={sortFn}
          searchFn={searchFn}
          search={location?.search}
          setAddNew={handleAddNewClick}
        />
        <div className="ims-content" ref={scrollSection} id="scrollSection">
          <InfiniteScroll
            hasChildren={true}
            dataLength={users?.length}
            next={onScroll}
            hasMore={pagination?.next?.url}
            scrollableTarget="scrollSection"
            endMessage={<p className="end-table">End of results</p>}
          >
            <Table
              data={users}
              columns={columns}
              sortFn={sortFn}
              search={location?.search}
              editClick={onRowClick}
              deleteClick={requestDelete}
            />
          </InfiniteScroll>
          {isScrolled && (
            <TNButton
              border
              highlight
              onClick={() => {
                scrollSection?.current && scrollSection.current.scrollTo(0, 0);
              }}
              extraClass="back-to-top"
            >
              Back To Top
            </TNButton>
          )}
        </div>
      </div>

      <ReactCSSTransitionGroup
        transitionName="edit-pane"
        className="edit-pane-wrap"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {editPaneOpen && (
          <Formik
            validationSchema={getSchema(selectedRow)}
            initialValues={{
              name: name || '',
              username: username || '',
              email: email || '',
              billingEmail: billingEmail || '',
              phone: phone || '',
              website: website || '',
              companyName: companyName || '',
              memberships: memberships || 8
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values).finally(() => {
                setSubmitting(false);
              });
            }}
          >
            {({ isSubmitting, values }) => (
              <EditPane
                isAdd={addNew}
                addItem="User"
                closePane={resetAndCloseEditPane}
                headerKeys={{
                  title: 'name',
                  subtitle: 'email',
                  subtitleLink: `mailto:${selectedRow?.email}`,
                  //the following two are not keys but relate to header info for images
                  img: userImg, //img is saved to state on load
                  signingUrl: getSigningUrl(selectedRow?.id),
                  imgMultiple: false,
                  imgUuid: selectedRow?.id && getUuidV3(selectedRow),
                  imgModalSubmit: handleSaveImg //so can easily add a temp image after upload
                }}
                column1Keys={[
                  { display: 'Email', key: 'email' },
                  { display: 'B. Email', key: 'billingEmail' },
                  { display: 'Tel', key: 'phone' },
                  { display: 'Last Statement', key: 'lastStatementDatePretty' }
                ]}
                column2Keys={[
                  { display: 'Username', key: 'username' },
                  { display: 'Company', key: 'companyName' },
                  { display: 'Website', key: 'website' }
                ]}
                actionButtons={
                  selectedRow
                    ? [
                        {
                          display: 'Record a Payment',
                          action: () => setPaymentModal(selectedRow)
                        },
                        {
                          display: 'Send Statement',
                          action: () => setStatementModal(true)
                        }
                      ]
                    : null
                }
                selectedRow={selectedRow}
                formValues={values}
                extraContent={
                  selectedRow ? (
                    <ul className="extra-actions">
                      <li
                        onClick={() => {
                          requestDelete(selectedRow);
                        }}
                      >
                        Delete
                      </li>
                      {selectedRow.status & statuses.active ||
                      !selectedRow.email ? (
                        ''
                      ) : (
                        <li
                          onClick={() => {
                            dispatch(
                              editUser(selectedRow.id, {
                                status: statuses.active
                              })
                            );
                          }}
                        >
                          Activate
                        </li>
                      )}
                    </ul>
                  ) : null
                }
              >
                <UserForm
                  isSubmitting={isSubmitting}
                  values={values}
                  onSubmit={handleFormSubmit}
                />
              </EditPane>
            )}
          </Formik>
        )}
      </ReactCSSTransitionGroup>
      {deleteConfirm && (
        <DeleteModal
          toDelete={() => dispatch(deleteUser(deleteConfirm))}
          toClose={() => setDeleteConfirm(null)}
        />
      )}
      {paymentModal && (
        <PaymentModal
          toClose={() => setPaymentModal(null)}
          user={selectedRow}
        />
      )}
      {statementModal && (
        <StatementModal
          toClose={() => setStatementModal(null)}
          user={selectedRow}
        />
      )}
    </div>
  );
});
