import React from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { isAfter } from 'date-fns';

import Modal from '../../Site/PageWithModal/Modal/Modal';
import TNButton from '../../Site/TNButton/TNButton';
import { Form, Field, RadioGroup } from '../../core/Forms';

import { createStatement } from '../../../store/lib/statements/createStatement';

import './StatementModal.scss';

const schema = yup.object().shape({
  range: yup.string().required('A range is required'),
  rangeStart: yup
    .date()
    .test('r-start', 'Start date is required for Date Span', function(value) {
      return this.parent.range !== 'span' || value;
    }),
  rangeEnd: yup
    .date()
    .test('r-end-after', 'End date must be after start date', function(value) {
      return (
        !value || isAfter(new Date(value), new Date(this.parent.rangeStart))
      );
    })
});

function StatementModal({ toClose, user }) {
  const dispatch = useDispatch();

  return (
    <Modal title="Send Statement" toClose={toClose} className="statement-modal">
      <div className="content-contain">
        <Formik
          validationSchema={schema}
          initialValues={{
            range: '',
            rangeEnd: '',
            rangeStart: ''
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (!values.rangeEnd) delete values.rangeEnd;
            if (!values.rangeStart) delete values.rangeStart;

            dispatch(createStatement({ userId: user.id, data: values }))
              .then(toClose)
              .finally(() => setSubmitting(false));
          }}
        >
          {({ values, errors, touched }) => {
            return (
              <Form legend="Statement Options">
                <RadioGroup
                  id="range"
                  label="Select a range for the statement"
                  className="span-all"
                  value={values.range}
                  error={errors.range}
                  touched={touched.range}
                  options={[
                    ['open', 'Open Invoices'],
                    ['year', 'YTD'],
                    ['span', 'Date Span']
                  ]}
                />
                <Field
                  type="date"
                  name="rangeStart"
                  label="Start Date"
                  disabled={values.range !== 'span'}
                  helperText="Inclusive, available for Date Span only."
                />
                <Field
                  type="date"
                  name="rangeEnd"
                  label="End Date"
                  disabled={values.range !== 'span'}
                  helperText="Inclusive. defaults to today."
                />
                <TNButton border onClick={toClose}>
                  Cancel
                </TNButton>
                <TNButton border highlight type="submit">
                  Submit
                </TNButton>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
}

export default StatementModal;
