import React from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Modal from '../../PageWithModal/Modal/Modal';
import TNButton from '../../TNButton/TNButton';

export default function SignUpModal(props) {
  return (
    <Modal title="Sign Up" toClose={props.toClose}>
      {
        <div className="sign-up-text">
          <p>
            The IMS Dashboard is a free tool we offer our clients to help manage
            inventory and track jobs.
          </p>
          <p>
            If you'd like to set up a job with us, please{' '}
            <a href="mailto:topnotchdeliveryservice@gmail.com">
              shoot us an email
            </a>
            .
          </p>
          <p>
            If you are an existing client, just reach out & let us know you'd
            like an account.
          </p>
          <Link to="/contact" className="contact">
            <TNButton border highlight extraClass="contact-btn">
              Contact Us
            </TNButton>
          </Link>
        </div>
      }
    </Modal>
  );
}

SignUpModal.propTypes = { toClose: PropTypes.func };
