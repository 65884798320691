import ky from 'ky-universal';

const REQUEST_GET_ROOMS = 'REQUEST_GET_ROOMS';
const SUCCESS_GET_ROOMS = 'SUCCESS_GET_ROOMS';
const FAIL_GET_ROOMS = 'FAIL_GET_ROOMS';

function requestGetRooms() {
  return { type: REQUEST_GET_ROOMS };
}

function successGetRooms({ data, page }, next) {
  return {
    data,
    page,
    wasPaging: !!next,
    receivedAt: Date.now(),
    type: SUCCESS_GET_ROOMS
  };
}

function failGetRooms(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_ROOMS
  };
}

function tryGetRooms(next, search) {
  return dispatch => {
    dispatch(requestGetRooms());

    return ky
      .get(next || `/api/rooms${search}`)
      .json()
      .then(
        res => dispatch(successGetRooms(res, next)),
        async error => dispatch(failGetRooms(await error?.response?.json()))
      );
  };
}

function shouldGetRooms({ rooms }) {
  if (rooms.isFetching) {
    return false;
  } else if (!rooms.data.length) {
    return true;
  } else {
    return rooms.lastUpdated < Date.now() - 50;
  }
}

export function getRooms({ next, search = null } = {}) {
  return (dispatch, getState) => {
    if (shouldGetRooms(getState())) {
      return dispatch(tryGetRooms(next, search));
    }
  };
}

const initialRoomsState = {
  data: [],
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function rooms(state = initialRoomsState, action) {
  switch (action.type) {
    case REQUEST_GET_ROOMS:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_ROOMS:
      return {
        ...state,
        data: action.wasPaging ? [...state.data, ...action.data] : action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_ROOMS:
      return {
        ...state,
        data: [],
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
