import ky from 'ky-universal';

const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER';
const SUCCESS_CREATE_USER = 'SUCCESS_CREATE_USER';
const FAIL_CREATE_USER = 'FAIL_CREATE_USER';
const RESET_CREATE_USER = 'RESET_CREATE_USER';

export const createUserReset = () => ({ type: RESET_CREATE_USER });

function requestCreateUser() {
  return { type: REQUEST_CREATE_USER };
}

function successCreateUser({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_CREATE_USER
  };
}

function failCreateUser(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_CREATE_USER
  };
}

export function createUser(data) {
  return dispatch => {
    dispatch(requestCreateUser());
    return ky
      .post('/api/users', { json: data })
      .json()
      .then(
        res => dispatch(successCreateUser(res)),
        async error => dispatch(failCreateUser(await error?.response?.json()))
      );
  };
}

const initialUsersState = {
  data: {},
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function createdUser(state = initialUsersState, action) {
  switch (action.type) {
    case REQUEST_CREATE_USER:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_CREATE_USER:
      return {
        ...state,
        data: action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: true
      };
    case FAIL_CREATE_USER:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_CREATE_USER:
      return initialUsersState;
    default:
      return state;
  }
}
