import React from 'react';
import classNames from 'classnames';
import queryString from 'qs';

import './TableHead.scss';

const caret = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="angle-down"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
  >
    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
  </svg>
);

function TableHead({ columns, headerClass, sortFn, search }) {
  const query = queryString.parse(search?.replace('?', '')) || '';
  const _order = query.order || '';
  const order = _order.startsWith('-') ? _order.slice(1) : _order;

  return (
    <thead className={headerClass}>
      <tr>
        {columns.map((column, idx) => {
          const isActive =
            column.dataKey === order || column.dataKey === _order;
          const labelClasses = classNames({
            active: isActive,
            reverse: isActive && _order !== order
          });

          return (
            <th
              key={column.display}
              onClick={
                !column.noCaret
                  ? () => sortFn(column.sortKey || column.dataKey)
                  : null
              }
              className={labelClasses}
            >
              {idx % 2 !== 0 && !column.noCaret && caret}
              {column.display}
              {idx % 2 === 0 && !column.noCaret && caret}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

export default TableHead;
