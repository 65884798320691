import ky from 'ky-universal';
import { toast } from 'react-toastify';

const REQUEST_LOGIN = 'REQUEST_LOGIN';
const PASS_LOGIN = 'PASS_LOGIN';
const FAIL_LOGIN = 'FAIL_LOGIN';

const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
const PASS_LOGOUT = 'PASS_LOGOUT';
const FAIL_LOGOUT = 'FAIL_LOGOUT';

function requestLogin() {
  return { type: REQUEST_LOGIN };
}

function passLogin(res) {
  return {
    type: PASS_LOGIN,
    user: res.data,
    receivedAt: Date.now()
  };
}

function failLogin(error) {
  return {
    error,
    type: FAIL_LOGIN,
    receivedAt: Date.now()
  };
}

function tryLogin(data) {
  return dispatch => {
    dispatch(requestLogin());

    return ky
      .post(`/api/users/login`, { json: data })
      .json()
      .then(
        res => dispatch(passLogin(res)),
        async error => dispatch(failLogin(await error.response.json()))
      );
  };
}

function shouldLogin({ user }) {
  if (user.isFetching) {
    return false;
  } else if (!user.data) {
    return true;
  } else {
    return user.lastUpdated < Date.now() - 50;
  }
}

export function loginIfNeeded(data) {
  return (dispatch, getState) => {
    if (shouldLogin(getState())) {
      return dispatch(tryLogin(data));
    }
  };
}

export function requestLogout() {
  return { type: REQUEST_LOGOUT };
}

function passLogout() {
  return { type: PASS_LOGOUT };
}

function failLogout(error) {
  return {
    error,
    type: FAIL_LOGOUT,
    receivedAt: Date.now()
  };
}

function tryLogout() {
  return dispatch => {
    dispatch(requestLogout());

    return ky
      .get(`/api/users/logout`)
      .json()
      .then(
        res => dispatch(passLogout(res)),
        async error => dispatch(failLogout(await error.response.json()))
      );
  };
}

function shouldLogout({ user }) {
  return !user.isFetching;
}

export function logoutIfNeeded() {
  return (dispatch, getState) => {
    if (shouldLogout(getState())) {
      return dispatch(tryLogout());
    }
  };
}

const initialUserState = {
  data: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function user(state = initialUserState, action) {
  if (action.error?.message === 'no session') {
    toast.error('Session expired, please log back in.', { toastId: 'expired' });
    return { ...state, data: null };
  }

  switch (action.type) {
    case REQUEST_LOGIN:
    case REQUEST_LOGOUT:
      return { ...state, isFetching: true, error: null };
    case PASS_LOGIN:
      return {
        ...state,
        data: action.user,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_LOGIN:
    case FAIL_LOGOUT:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
