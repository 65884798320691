import ky from 'ky-universal';

const REQUEST_GET_CARRIERS = 'REQUEST_GET_CARRIERS';
const SUCCESS_GET_CARRIERS = 'SUCCESS_GET_CARRIERS';
const FAIL_GET_CARRIERS = 'FAIL_GET_CARRIERS';

function requestGetCarriers() {
  return { type: REQUEST_GET_CARRIERS };
}

function successGetCarriers({ data, page }, next) {
  return {
    data,
    page,
    wasPaging: !!next,
    receivedAt: Date.now(),
    type: SUCCESS_GET_CARRIERS
  };
}

function failGetCarriers(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_CARRIERS
  };
}

function tryGetCarriers(next, search) {
  return dispatch => {
    dispatch(requestGetCarriers());

    return ky
      .get(next || `/api/carriers${search}`)
      .json()
      .then(
        res => dispatch(successGetCarriers(res, next)),
        async error => dispatch(failGetCarriers(await error?.response?.json()))
      );
  };
}

function shouldGetCarriers({ carriers }) {
  if (carriers.isFetching) {
    return false;
  } else if (!carriers.data.length) {
    return true;
  } else {
    return carriers.lastUpdated < Date.now() - 50;
  }
}

export function getCarriers({ next, search = null } = {}) {
  return (dispatch, getState) => {
    if (shouldGetCarriers(getState())) {
      return dispatch(tryGetCarriers(next, search));
    }
  };
}

const initialCarriersState = {
  data: [],
  page: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function carriers(state = initialCarriersState, action) {
  switch (action.type) {
    case REQUEST_GET_CARRIERS:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_CARRIERS:
      return {
        ...state,
        data: action.wasPaging ? [...state.data, ...action.data] : action.data,
        page: action.page,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_CARRIERS:
      return {
        ...state,
        data: [],
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
