import ky from 'ky-universal';

const REQUEST_POST_PASS_RESET = 'REQUEST_POST_PASS_RESET';
const PASS_POST_PASS_RESET = 'PASS_POST_PASS_RESET';
const FAIL_POST_PASS_RESET = 'FAIL_POST_PASS_RESET';

function requestPassReset() {
  return { type: REQUEST_POST_PASS_RESET };
}

function passPassReset(res) {
  return {
    type: PASS_POST_PASS_RESET,
    user: res.data,
    receivedAt: Date.now()
  };
}

function failPassReset(error) {
  return {
    error,
    type: FAIL_POST_PASS_RESET,
    receivedAt: Date.now()
  };
}

function tryPassReset(data, search) {
  return (dispatch) => {
    dispatch(requestPassReset());

    return ky
      .put(`/login/reset-password${search}`, { json: data })
      .json()
      .then(
        (res) => dispatch(passPassReset(res)),
        async (error) =>
          dispatch(failPassReset(await error?.response?.json?.()))
      );
  };
}

function shouldPassReset({ passReset }) {
  if (passReset.isFetching) {
    return false;
  } else if (!passReset.data) {
    return true;
  } else {
    return passReset.lastUpdated < Date.now() - 50;
  }
}

export function passResetIfNeeded(data, search) {
  return async (dispatch, getState) => {
    if (shouldPassReset(getState())) {
      return dispatch(tryPassReset(data, search));
    }
  };
}

const initialUserState = {
  data: null,
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function passReset(state = initialUserState, action) {
  switch (action.type) {
    case REQUEST_POST_PASS_RESET:
      return { ...state, isFetching: true, error: null };
    case PASS_POST_PASS_RESET:
      return {
        ...state,
        data: action.user,
        isFetching: false,
        error: null,
        submitted: true,
        lastUpdated: action.receivedAt
      };
    case FAIL_POST_PASS_RESET:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    default:
      return state;
  }
}
