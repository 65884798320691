import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import IMSNav from '../IMSNav/IMSNav';
import Dash from '../Dash/Dash';
import Invoices from '../Invoices/Invoices';
import Jobs from '../Jobs/Jobs';
import Items from '../Items/Items';
import Users from '../Users/Users';
import AppSettings from '../AppSettings/AppSettings';

import FourOhFour from '../../errors/FourOhFour';
import FiveHundy from '../../errors/FiveHundy';

import './IMSWrap.scss';

function IMS() {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isMobile, setIsMobile] = useState(null);

  function toggleMobileNav() {
    setShowMobileNav(!showMobileNav);
  }

  function handleResize() {
    setIsMobile(window.innerWidth < 1025);
  }

  useEffect(() => {
    setIsMobile(window.innerWidth < 1025);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="ims">
      <IMSNav
        toggleMobileNav={toggleMobileNav}
        showMobileNav={showMobileNav}
        isMobile={isMobile}
      />
      <div className="ims-body">
        <Switch>
          <Route
            exact
            path="/ims"
            render={props => (
              <Dash {...props} toggleMobileNav={toggleMobileNav} />
            )}
          />
          <Route
            exact
            path="/ims/dash"
            render={props => (
              <Dash {...props} toggleMobileNav={toggleMobileNav} />
            )}
          />
          <Route
            path="/ims/invoices"
            render={props => (
              <Invoices {...props} toggleMobileNav={toggleMobileNav} />
            )}
          />
          <Route
            path="/ims/jobs"
            render={props => (
              <Jobs {...props} toggleMobileNav={toggleMobileNav} />
            )}
          />
          <Route
            path="/ims/items"
            render={props => (
              <Items {...props} toggleMobileNav={toggleMobileNav} />
            )}
          />
          <Route
            path="/ims/users"
            render={props => (
              <Users {...props} toggleMobileNav={toggleMobileNav} />
            )}
          />
          <Route
            path="/ims/settings"
            render={props => (
              <AppSettings {...props} toggleMobileNav={toggleMobileNav} />
            )}
          />
          <Route path="/ims/500" component={FiveHundy} />
          <Route component={FourOhFour} />
        </Switch>
      </div>
    </div>
  );
}

export default IMS;
