import React from 'react';
import TNButton from '../Site/TNButton/TNButton';

import './FourOhFour.scss';

export default function FiveHundy({ history }) {
  const back = e => {
    e?.stopPropagation();
    history?.goBack();
  };

  return (
    <div className="errorPage">
      <h1>500</h1>
      <h3>The server was unable to complete your request.</h3>
      <p>
        There is possibly a mistake in our code, or the internet is having
        problems.
      </p>
      <p>You can try refreshing the page.</p>
      <p>
        If the problem persists, we always very much appreciate
        <a href="mailto:hello@adxsoftware.com">a bug report.</a>
      </p>

      <TNButton onClick={back}>Return Home</TNButton>
    </div>
  );
}
