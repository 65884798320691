import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FieldArray as FormikFieldArray } from 'formik';

import '../styles/repeater.scss';

FieldArray.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.object,
  legend: PropTypes.string.isRequired,
  children: PropTypes.func,
  defaultValue: PropTypes.object.isRequired,
  addButtonText: PropTypes.string
};

FieldArray.defaultProps = {
  values: {},
  children: () => {},
  addButtonText: 'Add New'
};

export default function FieldArray({
  name,
  values,
  legend,
  children,
  className,
  defaultValue,
  addButtonText
}) {
  return (
    <FormikFieldArray name={name}>
      {arrayHelpers => (
        <div className={classNames('span-all', className)}>
          {values[name]?.length > 0 ? (
            values[name].map((value, idx) => (
              <fieldset className="fieldset fieldset-inner repeater" key={idx}>
                <legend className="legend">{legend}</legend>
                <div className="form-grid">{children(value, idx)}</div>
                <div className="repeater-controls">
                  <button
                    type="button"
                    className="repeater-control repeater-control-add"
                    onClick={() => arrayHelpers.insert(idx + 1, { ...defaultValue })}
                  >
                    +
                  </button>
                  <button
                    type="button"
                    className="repeater-control repeater-control-subtract"
                    onClick={() => arrayHelpers.remove(idx)}
                  >
                    -
                  </button>
                </div>
              </fieldset>
            ))
          ) : (
            <button type="button" onClick={() => arrayHelpers.push({ ...defaultValue })}>
              {addButtonText}
            </button>
          )}
        </div>
      )}
    </FormikFieldArray>
  );
}
