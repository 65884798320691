import React, { useState, useEffect, useRef } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'qs';

import { connect } from 'react-redux';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import * as yup from 'yup';

import PageTabs from '../PageTabs/PageTabs';
import IMSTopBar from '../IMSTopBar/IMSTopBar';
import EditPane from '../EditPane/EditPane';
import Table from '../Table/Table';
import JobForm from '../Jobs/AddEditJob';
import DeleteModal from '../DeleteModal/DeleteModal';
import TNButton from '../../Site/TNButton/TNButton';

import { getJob, getJobReset } from '../../../store/lib/jobs/job';
import { getJobs } from '../../../store/lib/jobs/jobs';
import { createJob, createJobReset } from '../../../store/lib/jobs/createJob';
import { editJob, editJobReset } from '../../../store/lib/jobs/editJob';
import { deleteJob, deleteJobReset } from '../../../store/lib/jobs/deleteJob';
import { getJobsCount } from '../../../store/lib/jobs/jobsCount';

import { getUsers } from '../../../store/lib/users/users';
import { getAddresses } from '../../../store/lib/addresses/addresses';

import { changeSort, textSearchSubmit } from '../../../store/lib/etc/searchFns';

import useErrorToast from '../../hooks/useErrorToast';

import briefcase from './briefcase.svg';
import {
  getAddress,
  getAddressReset
} from '../../../store/lib/addresses/address';

// noinspection DuplicatedCode
const mapStateToProps = data => ({
  job: data.job.data,
  jobsCount: data.jobsCount.data,

  jobs: data.jobs.data,
  pagination: data.jobs.page,
  fetching: data.jobs.isFetching,

  users: data.users.data,
  singleAddress: data.address.data,
  addresses: data.addresses.data,

  createdJob: data.createdJob,
  editedJob: data.editedJob,
  deletedJob: data.deletedJob,

  jobError: data.job.error,
  jobsError: data.jobs.error,
  jobsCountError: data.jobsCount.error,
  createJobError: data.createdJob.error,
  editJobError: data.editedJob.error,
  deleteJobError: data.deletedJob.error,

  usersError: data.users.error,
  addressError: data.addresses.error
});

const columns = [
  { display: 'ID', dataKey: 'id' },
  { display: 'Job', dataKey: 'name' },
  { display: 'Client', dataKey: 'user.name', sortKey: 'clientName' },
  { display: 'Created', dataKey: 'createdAt' },
  { display: 'Last Changed', dataKey: 'updatedAt' },
  { display: 'Status', dataKey: 'statusText', sortKey: 'status' }
];

const schema = yup.object().shape({
  name: yup.string().required('Please enter a name for the job.'),
  userId: yup.number().required('Please associate a user.'),

  street: yup.string(),
  locale: yup.string(),
  state: yup.string(),
  postalCode: yup.number()
});

export default connect(mapStateToProps)(function Jobs({
  toggleMobileNav,
  dispatch,
  pagination,
  location,
  history,

  job,
  jobs,
  jobsCount,
  createdJob,
  editedJob,
  deletedJob,

  users,
  singleAddress,
  addresses,

  jobError,
  jobsError,
  jobsCountError,
  createJobError,
  editJobError,
  deleteJobError,

  usersError,
  addressError
}) {
  useErrorToast(jobError);
  useErrorToast(jobsError);
  useErrorToast(jobsCountError);
  useErrorToast(createJobError);
  useErrorToast(editJobError);
  useErrorToast(deleteJobError);
  useErrorToast(usersError);
  useErrorToast(addressError);

  //stuff for handling the main getting of data and errors

  useEffect(() => {
    dispatch(getJobs({ search: location.search }));
  }, [location?.search]);

  //TODO seems to not get jobs correctly anymore

  useEffect(() => {
    dispatch(getJobsCount());
  }, []);

  const scrollSection = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  function handleScroll() {
    // noinspection JSUnresolvedVariable
    const scrollTop = scrollSection?.current?.scrollTop;
    if (!isScrolled && scrollTop > 100) {
      setIsScrolled(true);
    } else if (isScrolled && scrollTop < 50) {
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    // noinspection JSUnresolvedFunction
    scrollSection?.current?.addEventListener('scroll', handleScroll);
    // noinspection JSUnresolvedFunction
    return () =>
      scrollSection?.current?.removeEventListener('scroll', handleScroll);
  });

  function onScroll() {
    dispatch(getJobs({ next: pagination?.next?.url }));
  }

  //stuff for controlling add and edit panel
  const [selectedRow, setSelectedRow] = useState(null);

  const [addNew, setAddNew] = useState(null);
  const editPaneOpen = addNew || selectedRow;
  const { name, addressId, address, user, userId } = selectedRow
    ? selectedRow
    : {};

  function resetAndCloseEditPane() {
    setAddNew(null);
    setSelectedRow(null);
    dispatch(getJobReset());
  }

  function onRowClick(selected) {
    if (selectedRow?.id === selected?.id) {
      setSelectedRow(null);
    } else if (addNew) {
      setAddNew(null);
      setTimeout(() => {
        dispatch(getJob(selected.id));
      }, 500);
    } else if (selectedRow) {
      setSelectedRow(null);
      setTimeout(() => {
        dispatch(getJob(selected.id));
      }, 500);
    } else {
      dispatch(getJob(selected.id));
    }
  }

  useEffect(() => {
    if (job?.id && job.id !== selectedRow?.id) {
      setSelectedRow(job);
    }
  }, [job]);

  function handleAddNewClick() {
    if (selectedRow) {
      setSelectedRow(null);
      setTimeout(() => {
        setAddNew(true);
      }, 500);
    } else {
      setAddNew(true);
    }
  }

  //stuff for add and edit forms

  function handleFormSubmit(values) {
    const formatValues = { ...values };

    delete formatValues.street;
    delete formatValues.locale;
    delete formatValues.state;
    delete formatValues.postalCode;
    delete formatValues.user;

    if (!values.addressId) {
      delete formatValues.addressId;
      formatValues.address = {
        street: values.street,
        locale: {
          name: values.locale,
          state: values.state,
          postalCode: values.postalCode
        }
      };
    }

    if (selectedRow) {
      return dispatch(editJob(selectedRow.id, formatValues));
    } else {
      return dispatch(createJob(formatValues));
    }
  }

  function handleStatusChange(newStatus) {
    dispatch(editJob(selectedRow.id, { status: newStatus }));
  }

  useEffect(() => {
    //success message
    createdJob.submitted && toast.success('Job created successfully');
    editedJob.submitted && toast.success('Job edited successfully');
    resetAndCloseEditPane();
  }, [createdJob.submitted, editedJob.submitted]);

  useEffect(() => {
    //reset createJob object after pane closes on submit
    if (!addNew) {
      dispatch(createJobReset());
    }
    if (editedJob.submitted && !selectedRow) {
      dispatch(editJobReset());
    }
  }, [addNew, selectedRow]);

  //stuff for delete
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  function requestDelete(selected) {
    setDeleteConfirm(selected.id);
  }

  useEffect(() => {
    if (deletedJob?.submitted || deleteJobError) {
      setDeleteConfirm(null);
      dispatch(deleteJobReset());
      resetAndCloseEditPane();
      deletedJob.submitted && toast.success('Job deleted successfully');
    }
  }, [deletedJob?.submitted, deleteJobError]);

  //stuff for search, sort, filters, queries

  const _oldQuery = location.search.replace('%3F', '').replace('?', '');

  function sortFn(newQuery) {
    changeSort(newQuery, _oldQuery, history, location);
  }

  function searchFn(text) {
    textSearchSubmit(text, _oldQuery, history, location);
  }

  function autocompleteSearch(modelName, ac_key, ac_val) {
    const params = { search: '?' + queryString.stringify({ ac_key, ac_val }) };

    if (modelName === 'users') {
      dispatch(getUsers(params));
    } else if (modelName === 'addresses') {
      dispatch(getAddresses(params));
    }
  }

  return (
    <div className="jobs ims-page">
      <ReactCSSTransitionGroup
        transitionName="page-tabs"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {!editPaneOpen && (
          <PageTabs
            title="Jobs"
            addItem="Job"
            tableKey="jobs"
            search={location?.search}
            condense={editPaneOpen}
            setAddNew={handleAddNewClick}
            history={history}
            pathKey="status"
            tabs={[
              {
                name: 'All Jobs',
                extra: jobsCount?.all || 0
              },
              {
                name: 'Active Jobs',
                extra: jobsCount?.active || 0,
                filter: '8'
              },
              {
                name: 'Inactive Jobs',
                extra: jobsCount?.inactive || 0,
                filter: '2'
              },
              {
                name: 'Overdue',
                extra: jobsCount?.late || 0,
                filter: '16'
              }
            ]}
          />
        )}
      </ReactCSSTransitionGroup>

      <div className="ims-right-contain">
        <IMSTopBar
          tableKey="jobs"
          toggleMobileNav={toggleMobileNav}
          columns={columns}
          sortFn={sortFn}
          searchFn={searchFn}
          search={location?.search}
          setAddNew={handleAddNewClick}
        />
        <div className="ims-content" ref={scrollSection} id="scrollSection">
          <InfiniteScroll
            hasChildren={true}
            dataLength={jobs?.length}
            next={onScroll}
            hasMore={pagination?.next?.url}
            scrollableTarget="scrollSection"
            endMessage={<p className="end-table">End of results</p>}
          >
            <Table
              data={jobs}
              columns={columns}
              sortFn={sortFn}
              search={location?.search}
              editClick={onRowClick}
              deleteClick={requestDelete}
            />
          </InfiniteScroll>
          {isScrolled && (
            <TNButton
              border
              highlight
              onClick={() => {
                // noinspection JSUnresolvedFunction
                scrollSection?.current && scrollSection.current.scrollTo(0, 0);
              }}
              extraClass="back-to-top"
            >
              Back To Top
            </TNButton>
          )}
        </div>
      </div>

      <ReactCSSTransitionGroup
        transitionName="edit-pane"
        className="edit-pane-wrap"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {editPaneOpen && (
          <Formik
            validationSchema={schema}
            initialValues={{
              name: name || '',
              userId: userId || '',
              user: user?.name || '',

              addressId: addressId || '',

              street: address?.street || '',
              locale: address?.locale?.name || '',
              state: address?.locale?.state || '',
              postalCode: address?.locale?.postalCode || ''
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values).finally(() => {
                setSubmitting(false);
              });
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => {
              return (
                <EditPane
                  noImgUploads
                  isAdd={addNew}
                  addItem="Job"
                  closePane={resetAndCloseEditPane}
                  headerKeys={{
                    title: 'name',
                    subtitle: 'user.name',
                    img: briefcase
                  }}
                  column1Keys={[
                    { display: 'Created', key: 'createdAt' },
                    { display: 'Address', key: 'street' }
                  ]}
                  column2Keys={[
                    { display: 'Updated', key: 'updatedAt' },
                    { display: 'Status', key: 'statusText' }
                  ]}
                  selectedRow={selectedRow}
                  formValues={values}
                  extraContent={
                    selectedRow ? (
                      <ul className="extra-actions">
                        <li
                          onClick={() => {
                            handleStatusChange(
                              selectedRow.status === 8 ? 2 : 8
                            );
                          }}
                        >
                          Mark as{' '}
                          {selectedRow.status === 8 ? 'Inactive' : 'Active'}
                        </li>
                        <li
                          onClick={() => {
                            handleStatusChange(16);
                          }}
                        >
                          Mark as Overdue
                        </li>
                        <li
                          onClick={() => {
                            requestDelete(selectedRow);
                          }}
                        >
                          Delete
                        </li>
                      </ul>
                    ) : null
                  }
                >
                  <JobForm
                    isSubmitting={isSubmitting}
                    users={users}
                    address={singleAddress}
                    addresses={addresses}
                    resetAddress={() => dispatch(getAddressReset())}
                    getAddress={id => dispatch(getAddress(id))}
                    setFieldValue={setFieldValue}
                    values={values}
                    autocompleteSearch={autocompleteSearch}
                  />
                </EditPane>
              );
            }}
          </Formik>
        )}
      </ReactCSSTransitionGroup>
      {deleteConfirm && (
        <DeleteModal
          toDelete={() => {
            dispatch(deleteJob(deleteConfirm));
          }}
          toClose={() => setDeleteConfirm(null)}
        />
      )}
    </div>
  );
});

//TODO do not reset stuff on page load if does not have content (unnecessary dispatches)
