import React, { useState, useEffect, useRef } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'qs';
import { format } from 'date-fns';

import { connect } from 'react-redux';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import * as yup from 'yup';

import PageTabs from '../PageTabs/PageTabs';
import IMSTopBar from '../IMSTopBar/IMSTopBar';
import EditPane from '../EditPane/EditPane';
import Table from '../Table/Table';
import InvoiceForm from '../Invoices/AddEditInvoice';
import DeleteModal from '../DeleteModal/DeleteModal';
import TNButton from '../../Site/TNButton/TNButton';

import statuses from './config/statuses';
import useErrorToast from '../../hooks/useErrorToast';

import { getInvoices } from '../../../store/lib/invoices/invoices';
import { getInvoicesCount } from '../../../store/lib/invoices/invoicesCount';
import { getJobs } from '../../../store/lib/jobs/jobs';
import { getUsers } from '../../../store/lib/users/users';
import { getBillingItems } from '../../../store/lib/billingItems/billingItems';
import {
  getInvoice,
  getInvoiceReset
} from '../../../store/lib/invoices/invoice';
import {
  createInvoice,
  createInvoiceReset
} from '../../../store/lib/invoices/createInvoice';
import {
  editInvoice,
  editInvoiceReset
} from '../../../store/lib/invoices/editInvoice';
import {
  deleteInvoice,
  deleteInvoiceReset
} from '../../../store/lib/invoices/deleteInvoice';
import {
  getBillingItem,
  getBillingItemReset
} from '../../../store/lib/billingItems/billingItem';
import { changeSort, textSearchSubmit } from '../../../store/lib/etc/searchFns';

import money from './money.svg';

const today = format(Date.now(), 'yyyy-MM-dd');

const defaultBillingItem = {
  name: '',
  quantity: 1,
  rate: '',
  description: ''
};

const mapStateToProps = data => ({
  invoice: data.invoice.data,
  invoicesCount: data.invoicesCount.data,

  invoices: data.invoices.data,
  pagination: data.invoices.page,
  fetching: data.invoices.isFetching,

  jobs: data.jobs.data,
  users: data.users.data,
  singleBillingItem: data.billingItem.data,
  billingItems: data.billingItems.data,

  createdInvoice: data.createdInvoice,
  editedInvoice: data.editedInvoice,
  deletedInvoice: data.deletedInvoice,

  invoiceError: data.invoice.error,
  invoicesError: data.invoices.error,
  invoicesCountError: data.invoicesCount.error,
  createInvoiceError: data.createdInvoice.error,
  editInvoiceError: data.editedInvoice.error,
  deleteInvoiceError: data.deletedInvoice.error,

  jobsError: data.jobs.error,
  usersError: data.users.error,
  billingItemsError: data.billingItems.error
});

const columns = [
  { display: 'Number', dataKey: 'number' },
  { display: 'Client', dataKey: 'user.name', sortKey: 'clientName' },
  { display: 'Created', dataKey: 'createdAt' },
  { display: 'Job', dataKey: 'job.name', sortKey: 'jobName' },
  { display: 'Amount', dataKey: 'total', noCaret: true },
  { display: 'Status', dataKey: 'statusText', sortKey: 'status' }
];

const schema = yup.object().shape({
  number: yup.number().required('Number required'),
  date: yup.date().required('Invoice date required'),
  dueIn: yup.number().required('Due In Number Required')
});

export default connect(mapStateToProps)(function Invoices({
  toggleMobileNav,
  dispatch,
  pagination,
  location,
  history,

  invoice,
  invoices,
  invoicesCount,
  createdInvoice,
  editedInvoice,
  deletedInvoice,

  jobs,
  users,
  singleBillingItem,
  billingItems,

  invoiceError,
  invoicesError,
  invoicesCountError,
  createInvoiceError,
  editInvoiceError,
  deleteInvoiceError,

  jobsError,
  usersError,
  billingItemsError
}) {
  useErrorToast(invoiceError);
  useErrorToast(invoicesError);
  useErrorToast(invoicesCountError);
  useErrorToast(createInvoiceError);
  useErrorToast(editInvoiceError);
  useErrorToast(deleteInvoiceError);
  useErrorToast(usersError);
  useErrorToast(jobsError);
  useErrorToast(billingItemsError);

  //stuff for handling the main getting of data and errors

  useEffect(() => {
    dispatch(getInvoices({ search: location.search }));
  }, [location?.search]);

  useEffect(() => {
    dispatch(getInvoicesCount());
  }, []);

  const scrollSection = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  function handleScroll() {
    const scrollTop = scrollSection?.current?.scrollTop;
    if (!isScrolled && scrollTop > 100) {
      setIsScrolled(true);
    } else if (isScrolled && scrollTop < 50) {
      setIsScrolled(false);
    }
  }

  useEffect(() => {
    scrollSection?.current?.addEventListener('scroll', handleScroll);
    return () =>
      scrollSection?.current?.removeEventListener('scroll', handleScroll);
  });

  function onScroll() {
    dispatch(getInvoices({ next: pagination?.next.url }));
  }

  //stuff for controlling add and edit panel
  const [selectedRow, setSelectedRow] = useState(null);
  const [addNew, setAddNew] = useState(null);
  const editPaneOpen = addNew || selectedRow;
  const {
    number,
    date,
    dueIn,
    sentDate,
    paidDate,
    notes,
    user,
    userId,
    job
  } = selectedRow ? selectedRow : {};

  const status = selectedRow?.status;

  const isSent = status & statuses.sent;
  const isPaid = status & statuses.paid;

  function resetAndCloseEditPane() {
    setAddNew(null);
    setSelectedRow(null);
    dispatch(getInvoiceReset());
  }

  function onRowClick(selected) {
    if (selectedRow?.id === selected?.id) {
      setSelectedRow(null);
    } else if (addNew) {
      setAddNew(null);
      setTimeout(() => {
        dispatch(getInvoice(selected.id));
      }, 500);
    } else if (selectedRow) {
      setSelectedRow(null);
      setTimeout(() => {
        dispatch(getInvoice(selected.id));
      }, 500);
    } else {
      dispatch(getInvoice(selected.id));
    }
  }

  useEffect(() => {
    if (invoice?.id && invoice.id !== selectedRow?.id) {
      setSelectedRow(invoice);
    }
  }, [invoice]);

  function handleAddNewClick() {
    if (selectedRow) {
      setSelectedRow(null);
      setTimeout(() => {
        setAddNew(true);
      }, 500);
    } else {
      setAddNew(true);
    }
  }

  //stuff for add and edit forms

  function handleFormSubmit(values, send) {
    const formatValues = { ...values };

    delete formatValues.user;
    delete formatValues.job;

    const newBillingTypes = [];

    formatValues.billingItems = formatValues.billingItems?.map(item => {
      item.rate && (item.rate = parseFloat(item.rate));
      item.template.default_rate &&
        (item.template.default_rate = parseFloat(item.template.default_rate));

      if (!item.template.id) {
        const name = item.template.name;
        const nameIdx = newBillingTypes.findIndex(n => n === name);

        if (nameIdx > -1) {
          item.template = { '#ref': `item:${nameIdx}` };
        } else {
          item.template['#id'] = `item:${newBillingTypes.length}`;

          newBillingTypes.push(name);

          item.template.default_description = item.description;
          item.template.default_rate = item.rate;
          item.template.default_quantity = item.quantity;
        }
      }

      if (item.rate === item.template.default_rate) {
        delete item.rate;
      }
      if (item.quantity === item.template.default_quantity) {
        delete item.quantity;
      }
      if (item.description === item.template.default_description) {
        delete item.description;
      }

      if (item.template.id) {
        delete item.template.name;
        delete item.template.default_rate;
        delete item.template.default_quantity;
        delete item.template.default_description;
      }

      delete item.name;
      delete item.id;

      return item;
    });

    if (send) {
      formatValues.status = statuses.sent;
      formatValues.sentDate = new Date();
    }

    if (selectedRow) {
      return dispatch(editInvoice(selectedRow.id, formatValues, send && 1));
    } else {
      return dispatch(createInvoice(formatValues));
    }
  }

  function handleStatusChange(newStatus) {
    dispatch(editInvoice(selectedRow.id, { status: newStatus }));
  }

  useEffect(() => {
    //success message
    createdInvoice.submitted && toast.success('Invoice created successfully');
    editedInvoice.submitted && toast.success('Invoice edited successfully');
    resetAndCloseEditPane();
  }, [createdInvoice.submitted, editedInvoice.submitted]);

  useEffect(() => {
    //reset createInvoice object after pane closes on submit
    if (!addNew) {
      dispatch(createInvoiceReset());
    }
    if (editedInvoice.submitted && !selectedRow) {
      dispatch(editInvoiceReset());
    }
  }, [addNew, selectedRow]);

  //stuff for delete
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  function requestDelete(selected) {
    setDeleteConfirm(selected.id);
  }

  useEffect(() => {
    if (deletedInvoice?.submitted || deleteInvoiceError) {
      setDeleteConfirm(null);
      dispatch(deleteInvoiceReset());
      resetAndCloseEditPane();
      deletedInvoice.submitted && toast.success('Invoice deleted successfully');
    }
  }, [deletedInvoice?.submitted, deleteInvoiceError]);

  //stuff for search, sort, filters, queries

  const _oldQuery = location.search.replace('%3F', '').replace('?', '');

  function sortFn(newQuery) {
    changeSort(newQuery, _oldQuery, history, location);
  }

  function searchFn(text) {
    textSearchSubmit(text, _oldQuery, history, location);
  }

  function autocompleteSearch(modelName, ac_key, ac_val) {
    const params = { search: '?' + queryString.stringify({ ac_key, ac_val }) };

    if (modelName === 'billingItems') {
      dispatch(getBillingItems(params));
    } else if (modelName === 'users') {
      dispatch(getUsers(params));
    } else if (modelName === 'jobs') {
      dispatch(getJobs(params));
    }
  }

  return (
    <div className="invoices ims-page">
      <ReactCSSTransitionGroup
        transitionName="page-tabs"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {!editPaneOpen && (
          <PageTabs
            title="Invoices"
            addItem="Invoice"
            tableKey="invoices"
            search={location?.search}
            history={history}
            condense={editPaneOpen}
            setAddNew={handleAddNewClick}
            pathKey="status"
            tabs={[
              {
                name: 'All Invoices',
                extra: invoicesCount?.all || 0
              },
              {
                name: 'Sent Invoices',
                extra: invoicesCount?.sent || 0,
                filter: 32
              },
              {
                name: 'Overdue Invoices',
                extra: invoicesCount?.late || 0,
                filter: 16
              },
              {
                name: 'Paid Invoices',
                extra: invoicesCount?.paid || 0,
                filter: 64
              }
            ]}
          />
        )}
      </ReactCSSTransitionGroup>

      <div className="ims-right-contain">
        <IMSTopBar
          toggleMobileNav={toggleMobileNav}
          tableKey="invoices"
          columns={columns}
          sortFn={sortFn}
          searchFn={searchFn}
          search={location?.search}
          setAddNew={handleAddNewClick}
        />
        <div className="ims-content" ref={scrollSection} id="scrollSection">
          <InfiniteScroll
            hasChildren={true}
            dataLength={invoices?.length}
            next={onScroll}
            hasMore={pagination?.next.url}
            scrollableTarget="scrollSection"
            endMessage={<p className="end-table">End of results</p>}
          >
            <Table
              data={invoices}
              columns={columns}
              sortFn={sortFn}
              search={location?.search}
              editClick={onRowClick}
              deleteClick={requestDelete}
            />
          </InfiniteScroll>
          {isScrolled && (
            <TNButton
              border
              highlight
              onClick={() => {
                scrollSection?.current && scrollSection.current.scrollTo(0, 0);
              }}
              extraClass="back-to-top"
            >
              Back To Top
            </TNButton>
          )}
        </div>
      </div>

      <ReactCSSTransitionGroup
        transitionName="edit-pane"
        className="edit-pane-wrap"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {editPaneOpen && (
          <Formik
            validationSchema={schema}
            initialValues={{
              number: number || '',
              date: date || today,
              dueIn: dueIn || 0,
              sentDate: sentDate || '',
              paidDate: paidDate || '',
              notes: notes || '',

              billingItems: selectedRow?.billingItems || [defaultBillingItem],

              userId: userId || '',
              user: user?.name || '',
              job: job?.name || ''
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleFormSubmit(values).finally(() => {
                setSubmitting(false);
              });
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => {
              return (
                <EditPane
                  noImgUploads
                  isAdd={addNew}
                  addItem="Invoice"
                  closePane={resetAndCloseEditPane}
                  headerKeys={{
                    title: 'number',
                    subtitle: 'user.name',
                    img: user?.photoUrl || money
                  }}
                  column1Keys={[
                    { display: 'Invoice #', key: 'number' },
                    { display: 'Days Late', key: 'daysLate' },
                    { display: 'Due On', key: 'dateDue' },
                    { display: 'Invoice Sent', key: 'sentDate' },
                    { display: 'Total Paid', key: 'totalPaid' }
                  ]}
                  column2Keys={[
                    { display: 'User ID', key: 'user.id' },
                    { display: 'Status', key: 'statusText' },
                    { display: 'Phone', key: 'user.phone' },
                    { display: 'Email', key: 'user.email' },
                    { display: 'Balance', key: 'balance' }
                  ]}
                  actionButtons={
                    selectedRow
                      ? [
                          {
                            display: (() => {
                              if (isPaid) return 'Paid';
                              if (isSent) return 'Sent';
                              return 'Send';
                            })(),
                            action() {
                              !isSent ? handleFormSubmit(values, true) : null;
                            },
                            disabled:
                              selectedRow.status &
                              (statuses.paid | statuses.sent)
                          }
                        ]
                      : null
                  }
                  selectedRow={selectedRow}
                  formValues={values}
                  extraContent={
                    selectedRow ? (
                      <ul className="extra-actions">
                        <li
                          onClick={() => {
                            handleStatusChange(selectedRow.status & 8 ? 2 : 8);
                          }}
                        >
                          Mark as{' '}
                          {selectedRow.status & 8 ? 'Inactive' : 'Active'}
                        </li>
                        <li
                          onClick={() => {
                            requestDelete(selectedRow);
                          }}
                        >
                          Delete
                        </li>
                        <li>
                          <a
                            href={`/ims/invoices/${selectedRow.id}.pdf`}
                            target="_blank"
                          >
                            View Invoice
                          </a>
                        </li>
                      </ul>
                    ) : null
                  }
                >
                  <InvoiceForm
                    isSubmitting={isSubmitting}
                    users={users}
                    jobs={jobs}
                    billingItem={singleBillingItem}
                    billingItems={billingItems}
                    resetBillingItem={() => dispatch(getBillingItemReset())}
                    getBillingItem={(id, idx) =>
                      dispatch(getBillingItem(id, idx))
                    }
                    setFieldValue={setFieldValue}
                    values={values}
                    autocompleteSearch={autocompleteSearch}
                    defaultBillingItem={defaultBillingItem}
                  />
                </EditPane>
              );
            }}
          </Formik>
        )}
      </ReactCSSTransitionGroup>
      {deleteConfirm && (
        <DeleteModal
          toDelete={() => {
            dispatch(deleteInvoice(deleteConfirm));
          }}
          toClose={() => setDeleteConfirm(null)}
        />
      )}
    </div>
  );
});

// TODO do not reset stuff on page load if does not have content (unnecessary dispatches)

// TODO edit of billingItems seems to be entirely broken. Changes to invoice save, changes to
// billing items do not.
