import ky from 'ky-universal';

const REQUEST_CREATE_INVOICE = 'REQUEST_CREATE_INVOICE';
const SUCCESS_CREATE_INVOICE = 'SUCCESS_CREATE_INVOICE';
const FAIL_CREATE_INVOICE = 'FAIL_CREATE_INVOICE';
const RESET_CREATE_INVOICE = 'RESET_CREATE_INVOICE';

export const createInvoiceReset = () => ({ type: RESET_CREATE_INVOICE });

function requestCreateInvoice() {
  return { type: REQUEST_CREATE_INVOICE };
}

function successCreateInvoice({ data }) {
  return {
    data,
    receivedAt: Date.now(),
    type: SUCCESS_CREATE_INVOICE
  };
}

function failCreateInvoice(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_CREATE_INVOICE
  };
}

export function createInvoice(data) {
  return dispatch => {
    dispatch(requestCreateInvoice());
    return ky
      .post('/api/invoices', { json: data })
      .json()
      .then(
        res => dispatch(successCreateInvoice(res)),
        async error =>
          dispatch(failCreateInvoice(await error?.response?.json()))
      );
  };
}

const initialInvoicesState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null,
  submitted: false
};

export function createdInvoice(state = initialInvoicesState, action) {
  switch (action.type) {
    case REQUEST_CREATE_INVOICE:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_CREATE_INVOICE:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt,
        submitted: true
      };
    case FAIL_CREATE_INVOICE:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_CREATE_INVOICE:
      return initialInvoicesState;
    default:
      return state;
  }
}
