import ky from 'ky-universal';

const REQUEST_GET_BILLING_ITEM = 'REQUEST_GET_BILLING_ITEM';
const SUCCESS_GET_BILLING_ITEM = 'SUCCESS_GET_BILLING_ITEM';
const FAIL_GET_BILLING_ITEM = 'FAIL_GET_BILLING_ITEM';
const RESET_GET_BILLING_ITEM = 'RESET_GET_BILLING_ITEM';

export const getBillingItemReset = () => ({ type: RESET_GET_BILLING_ITEM });

function requestGetBillingItem() {
  return { type: REQUEST_GET_BILLING_ITEM };
}

function successGetBillingItem({ data }, idx) {
  return {
    data: { ...data, __selectedIdx: idx },
    receivedAt: Date.now(),
    type: SUCCESS_GET_BILLING_ITEM
  };
}

function failGetBillingItem(error) {
  return {
    error,
    receivedAt: Date.now(),
    type: FAIL_GET_BILLING_ITEM
  };
}

export function getBillingItem(id, idx) {
  return dispatch => {
    dispatch(requestGetBillingItem());
    return ky
      .get(`/api/billing-items/${id}`)
      .json()
      .then(
        res => {
          return dispatch(successGetBillingItem(res, idx));
        },
        async error => {
          return dispatch(failGetBillingItem(await error?.response?.json()));
        }
      );
  };
}

const initialBillingItemState = {
  data: {},
  lastUpdated: null,
  isFetching: false,
  error: null
};

export function billingItem(state = initialBillingItemState, action) {
  switch (action.type) {
    case REQUEST_GET_BILLING_ITEM:
      return { ...state, isFetching: true, error: null };
    case SUCCESS_GET_BILLING_ITEM:
      return {
        ...state,
        data: action.data,
        isFetching: false,
        error: null,
        lastUpdated: action.receivedAt
      };
    case FAIL_GET_BILLING_ITEM:
      return {
        ...state,
        data: null,
        isFetching: false,
        error: action.error,
        lastUpdated: null
      };
    case RESET_GET_BILLING_ITEM:
      return initialBillingItemState;
    default:
      return state;
  }
}
